@tailwind base;
@tailwind components;
@tailwind utilities;

/* Otherwise, use `::-webkit-scrollbar-*` pseudo-elements */
@supports selector(::-webkit-scrollbar) {
    /* width */
    .invisible-scrollbar::-webkit-scrollbar {
        height: 0px;
        /* Customize the scrollbar width */
    }

    /* width */
    .visible-scrollbar::-webkit-scrollbar {
        height: 4px;
        /* Customize the scrollbar width */
    }
    /* Track */
    .visible-scrollbar::-webkit-scrollbar-track {
        background: #BFBFBF;
        border-radius: 5px;
        margin: 0 20px;
        /* Customize the scrollbar track */
    }
    /* Handle */
    .visible-scrollbar::-webkit-scrollbar-thumb {
        background: #7B7B7B;
        border-radius: 5px;
        /* Customize the scrollbar thumb */
    }

    /* width */
    .invisible-scrollbar::-webkit-scrollbar {
        height: 0;
    }
    /* Track */
    .invisible-scrollbar::-webkit-scrollbar-track {
        background: transparent;
    }
    /* Handle */
    .invisible-scrollbar::-webkit-scrollbar-thumb {
        background: transparent;
    }

    @media (min-width: 1280px) {
        /* Track */
        .visible-scrollbar::-webkit-scrollbar-track {
            margin: 0;
            /* Customize the scrollbar track */
        }
    }
}


body {
    font-family: "Open Sans", serif;
}

[type='text']:focus, input:where(:not([type])):focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus{
    box-shadow: none !important;
    outline: none !important;
}