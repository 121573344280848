@media (min-width: 1024px) {
    .side-menu-item {
        display: flex;
        justify-content: space-between;
        width: 240px;
        padding-right: 25px;
    }

    .side-menu-block:after {
        border-bottom: 1px solid #b6b6b6;
        border-right: 1px solid #b6b6b6;
        content: "";
        display: block;
        height: 6px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: rotate(-45deg);
        transform-origin: 66% 66%;
        transition: all .15s ease-in-out;
        width: 6px;
    }

    .side-menu-item.active .side-menu-block:after {
        border-bottom: 1px solid #ec4a9b;
        border-right: 1px solid #ec4a9b;

    }

    span.side-menu-link {
        cursor: not-allowed;
    }

    a.side-menu-link {
        cursor: pointer;
        position: relative;
    }
    a.side-menu-link.new:after {
        content: "new";
        color: #e0414a;
        font-size: 10px;
        position: relative;
        left: 5px;
        top: -5px;
        display: inline-block;
        text-decoration: none;
    }

    .side-menu-block {
        display: flex;
        align-items: center;
        height: 70px;
        position: relative;
        width: 100%;
        gap: 15px;
    }
    .side-menu-block .image-block {
        height: 40px;
        width: 40px;
        flex-shrink: 1;
        flex-basis: 40px;
    }

    .side-menu-block a {
        text-decoration: unset;
    }

    .side-menu-block img {
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }

    .side-menu-title {
        font-size: 15px;
        line-height: 20px;
        color: #333;
        max-width: calc(100% - 70px);
        border-bottom: none;
    }

    .side-menu-link {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #333;
        display: inline-block;
        margin: 5px 0;
        text-decoration: none;
        border-bottom: none;
    }

    a.side-menu-link:hover {
        color: #ec4a9b;
        text-decoration: underline;
    }

    .side-menu-content {
        display: none;
        background: #FFFFFF;
        position: absolute;
        height: calc(100% + 1px);
        width: 100%;
        top: -1px;
        left: 240px;
        padding: 10px 30px 10px 20px;
        border-top: 1px solid #EEEEEE;
        z-index: 5;
    }

    .side-menu-item:hover,
    .catalog-menu-wrap .side-menu-item.active {
        background: #FAFAFA;
    }

    .side-menu-content .sub-menu {
        width: 80%;
        display: flex;
        gap: 20px;
        margin: 0 20px;
        overflow: hidden;
    }

    .side-menu-item:hover .side-menu-title,
    .catalog-menu-wrap .side-menu-item.active .side-menu-title {
        color: #ec4a9b;
    }

    .side-menu-item:hover .catalog-menu-image,
    .catalog-menu-wrap .side-menu-item.active .catalog-menu-image {
        display: none;
    }

    .catalog-menu-image-alternative {
        display: none;
    }

    .side-menu-item:hover .side-menu-content,
    .catalog-menu-wrap .side-menu-item.active .side-menu-content {
        display: flex;
    }

    .side-menu-item:hover .catalog-menu-image-alternative,
    .catalog-menu-wrap .side-menu-item.active .catalog-menu-image-alternative {
        display: block;
    }

    .popular-category {
        background: linear-gradient(0deg, rgba(0, 128, 204, 0.05), rgba(0, 128, 204, 0.05)), #FFFFFF;
        border-radius: 1px;
        width: 17%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        overflow: hidden;
    }

    div.popular-title {
        margin: 0;
        padding: 15px 0 0 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
    }

    a.popular-title {
        font-size: 18px;
        font-weight: normal;
        line-height: 22px;
        color: #3480C6;
        margin-top: 16px;
    }

    .popular-links {
        padding: 0;
    }

    .popular-links li {
        display: flex;
        align-items: start;
        gap: 5px;
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 400;
        line-height: 14px;
    }

    .popular-links li a {
        color: #777777;
    }

    .popular-links li span {
        font-size: 10px;
        height: 10px;
        line-height: 10px;
    }

    .popular-category img {
        margin: 10px 0 25px;
    }
}