.catalog-menu-wrap {
    z-index: 11;
    overflow: auto !important;
    overflow-x: hidden !important;
    max-height: 750px;
}

@media (min-width: 1901px) {

    .catalog-wrap {
        margin: 0 32px;
    }
    .catalog-menu-wrap {
        top: 135px;
    }
}

@media (min-width: 1024px) {
    .catalog-button {
        color: #FFFFFF;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        /*padding-right: 30px;*/
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .catalog-wrap {
        background: #ec4a9b;
        border-radius: 1px;
        width: 100%;
        max-width: 162px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 32px;
        flex-shrink: 0;
    }

    .catalog-icon-holder {
        display: flex;
        display: -webkit-flex;
        width: 100%;
        padding: 0 30px;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 48px;
    }

    .catalog-wrap.WrapButton {
        background: #5D99D1;
    }

    .catalog-menu-wrap {
        display: none;
        position: absolute;
        padding: 15px;
        top: 54px;
        left: 0;
        background: #FFFFFF;
        max-width: 100%;
        box-shadow: 0 0 40px 0 rgba(47, 74, 90, .29);
    }

    #header.fixed .catalog-menu-wrap {
        top: 46px;
        border-top: 1px solid #EEEEEE;
    }

    .catalog-menu-wrap--active {
        display: block;
        overflow: hidden;
    }

    .catalog-dropdown {
        background: #FFFFFF;
        width: fit-content;
    }

    .catalog-menu-wrap--active {
        width: calc(100% - 30px);
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (max-width: 1200px) {
    .catalog-wrap {
        margin: 0 20px !important;
    }
}

/** backdrop **/
.catalog-menu-backdrop {
    background: rgb(229 189 209 / 30%);
    position: fixed;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    cursor: pointer;
}

.catalog-menu-backdrop .circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.catalog-menu-backdrop .circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgb(236 74 155 / 20%);
    animation: backdrop-animate 25s linear infinite;
    bottom: -150px;

}

.catalog-menu-backdrop .circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.catalog-menu-backdrop .circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.catalog-menu-backdrop .circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.catalog-menu-backdrop .circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.catalog-menu-backdrop .circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.catalog-menu-backdrop .circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.catalog-menu-backdrop .circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.catalog-menu-backdrop .circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.catalog-menu-backdrop .circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.catalog-menu-backdrop .circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}


@keyframes backdrop-animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

/** backdrop **/
