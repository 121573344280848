#search {
    position: absolute;
    right: 0;
    top: -27px;
    width: 180px;
    transition: .3s ease-in-out;
}
#search.opened {
    width: 1250px;
}
.btn.btn-default svg {
    width: 15px;
    height: 23px;
    fill: #333;
}
.bx-searchtitle {
    margin: 10px 0 0;
}
.bx-searchtitle .bx-block-title {
    font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #343434;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 9px;
    font-weight: normal;
}
.bx-searchtitle .bx-input-group {
    position: relative;
    display: table;
    border-collapse: separate;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.bx-searchtitle .bx-input-group {}
.bx-searchtitle .bx-input-group .bx-form-control {
    display: table-cell;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px 0 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    border-bottom: 1px solid #d5dadc;
    border-left: none;
    outline: none;
    box-shadow: none !important;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.bx-ios .bx-searchtitle .bx-input-group .bx-form-control {
    /* Fix of input shadow for IOS browsers. */
    /*background-image: -webkit-linear-gradient(#f8fafc 0%, #f8fafc 100%);
    background-image: linear-gradient(#f8fafc 0%, #f8fafc 100%);*/
}
.bx-searchtitle .bx-input-group-btn {
    position: relative;
    font-size: 0;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
}
.bx-searchtitle .bx-input-group-btn button {
    position: relative;
    border: none;
    background: unset;
    display: inline-block;
    margin-bottom: 0;
    cursor: pointer;
    padding: 6px 6px 6px 12px;
    border-radius: 0;
    color: #95a1a8;
    font-size: 16px;
    line-height: 1.42857143;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

/*div.title-search-result {
    border:1px solid #c6c6c678;
    display:none;
    overflow:hidden;
    z-index:20;
    min-width: 370px;
    margin-top:-3px;
    background-color: #FFF;
    box-shadow: 0px -2px 20px 6px rgb(0 0 0 / 10%);
}*/
div.title-search-fader {
    display:none;
    background-image: url('images/fader.png');
    background-position: right top;
    background-repeat: repeat-y;
    position:absolute;
}
p.title-search-preview {
    font-size:12px;
}
div.title-search-result td.title-search-price span {
    font-size: 26px;
}

/*========================*/
.bx_searche{}
.bx_searche .bx_img_element{
    background: #fafafa;
    float: left;
    width: 67px;
    padding: 5px 10px;
    position: absolute;
    top: 0;
    bottom: 0;
}
.bx_searche .bx_item_block{
    min-height: 65px;
    position: relative
}
.search-result-products-container .bx_image{
    width: 80px;
    height: 80px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #e8e8e8;
}
.bx_item_element{
    display: flex;
    flex-direction: row;
}
.bx_searche .bx_item_element{
    padding-left: 10px;
    margin-left: 70px;
}
.bx_searche .bx_item_element hr{
    margin: 0;
    border: none;
    border-bottom: 1px solid #e5e5e5;
}
.bx_searche .bx_item_element a{
    margin-top: 10px;
    display: inline-block;
    color: #3BC915;
    font-size: 13px;
    text-decoration: none;
}
.bx_searche .bx_item_element a b{color: #000;font-weight: normal;}
.bx_searche .bx_item_element a:hover,
.bx_searche .bx_item_element a:hover b{text-decoration: underline;}
.bx_price{
    margin-top: 10px;
    margin-left: 20px;
}
.bx_searche .bx_price{
    font-weight:bold;
    font-size:13px;
}
.search-result-products-item__prise-old span.old{
    font-weight:normal;
    text-decoration: line-through;
    color: #333
}
.search-result-products-item__prise-old + .search-result-products-item__prise {
    color: #ec4a9b;
}
.bx_item_block.all_result{min-height:0}
.bx_item_block.others_result{
    min-height: 0;
    padding: 0 0 12px;
    line-height: 12px;
}
.bx_item_block.all_result .all_result_title{
    display: block;
    font-size: 12px;
    color: #a7a7a7;
    padding-top: 15px;
    padding-bottom: 10px;
}
.bx_item_block.all_result .all_result_title a{color: #a7a7a7}
.bx_item_block.all_result .all_result_title a:hover{color: #3BC915}
.bx_item_block.all_result a{
    margin-top: 0;
}
.search-result-pull-down{
    display: flex;
}
.title-search-result{
    /*position: absolute !important;*/
    /*top: 29px !important;*/
    /*left: -1250px !important;*/
    /*width: 1250px !important;*/
}
.bx-input-group .search-top__button {
    display: none;
}
.bx-input-group .search-top__button.shown {
    display: block;
}
.search-wrapper {
    margin: 0 auto ;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    position: relative;
}
.search-inner {
    margin: auto;
    border: 1px solid #e1e6e9;
}
.search-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.search-top__input {
    height: 46px;
    width: 1000px;
    padding: 0 15px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    color: #000000;
    font-size: 14px;
    font-family: "Museo", serif;
}
.search-top__input::-webkit-input-placeholder {
    color: #333;
    font-family: "Museo", serif;
}
.search-top__input::-moz-placeholder {
    color: #333;
    font-family: "Museo", serif;
}
.search-top__input::-ms-input-placeholder {
    color: #333;
    font-family: "Museo", serif;
}
.search-top__input::placeholder {
    color: #333;
    font-family: "Museo", serif;
}
.search-top__button {
    position: absolute;
    z-index: 2;
    right: 1px;
    cursor: pointer;
    background: transparent;
    border: none;
    margin: 6px 10px 0 10px;
}
.search-top__button svg {
    fill: #333;
    width: 17px;
    height: 17px;
}
.search-top__close {
    position: absolute;
    right: 6px;
    top: -3px;
    color: #333;
    opacity: 0.6;
    margin-right: 5px;
    margin-left: 5px;
}

/*.search-top__close::before {
	content: "";
	width: 2px;
	height: 22px;
	background: #000;
	background-color: #9ba6ad;
	position: absolute;
	left: -webkit-calc(50% - 1px);
	left: calc(50% - 1px);
	top: -webkit-calc(50% - 8px);
	top: -webkit-calc(50% - 11px);
	top: calc(50% - 11px);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	border-radius: 2px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
.search-top__close::after {
	content: "";
	width: 2px;
	height: 22px;
	background: #000;
	background-color: #9ba6ad;
	position: absolute;
	left: -webkit-calc(50% - 1px);
	left: calc(50% - 1px);
	top: -webkit-calc(50% - 8px);
	top: -webkit-calc(50% - 11px);
	top: calc(50% - 11px);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	border-radius: 2px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}*/

.search-result-pull-down {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #ffffff;
    padding: 10px 10px 10px 20px;
    margin: 0 0 0 0;
}
.search-result-categories-container {
    width: 210px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 10px;
    height: 300px;
    overflow-y: auto;
    border-right: 1px solid #e1e6e9;
}
.search-result-categories-container::-webkit-scrollbar {
    width: 3px;
    background-color: #ffffff;
}
.search-result-categories-container::-webkit-scrollbar-thumb {
    background: #e1e6e9;
    border-radius: 10px;
}
.search-result-categories-title_help {
    display: none;
    font-size: 15px;
    font-family: "Museo", serif;

    margin-bottom: 10px;
    margin-left: 10px;
}
.search-result-categories-title, .search-result-products-title {
    font-size: 15px;
    font-family: "Museo", serif;

    margin-bottom: 10px;
    color: #333;
}
.search-result-products-title {
    margin-top: 10px;
}
.search-result-categories-list {
    margin-top: 5px;
    padding-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
    list-style-type: none;
    padding-inline-start: 0;
}
.search-result-categories-list__item {
    color: #333;
    text-decoration: none;
    margin: 0 0;
    font-family: "Museo", serif;
}
.search-result-categories-list__link {
    text-decoration: none;
    color: #333;
    line-height: 18px;
    font-size: 15px;
}
.search-result-products-container {
    width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: center;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.search-result-products-container::-webkit-scrollbar {
    width: 3px;
    background-color: #ffffff;
}
.search-result-products-container::-webkit-scrollbar-thumb {
    background: #e1e6e9;
    border-radius: 10px;
}
.search-result-products-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: center;
}
.search-result-products-item__photo {
    margin: 10px;
    min-width: 100px;
    text-decoration: none;
}
.search-result-product-item-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
}
.search-result-products-item__description {
    margin: 10px 10px 10px 10px;
    min-width: 450px;
    max-width: 450px;
    text-decoration: none;
    color: #333;
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    font-family: "Museo", serif;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
}
.search-result-products-item__description:hover {
    color: #ec4a9b;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    font-size: 14px;
    font-family: "Museo", serif;
}
.search-result-products-item-pc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.search-result-products-prise-container {
    min-width: 105px;
    font-family: "Museo", serif;
    margin: 10px 10px 10px 10px;
}
.search-result-products-item__prise-old {
    font-size: 12px;
    font-weight: 400;
    margin: 5px;
}
.search-result-products-item__prise {
    font-size: 14px;
    /*font-weight: 900;*/
    margin: 5px 5px;
    font-family: "Museo", serif;
    color: #333;
}
.menu-popup__buy {
    margin: 0 10px 0 50px;
}
.search-result-products-item__cart {
    margin: 10px 10px 10px 10px;
    text-decoration: none;
    cursor: pointer;
    position: relative;
}
.search-result-products-item__cart::before {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background: #f2f2f2;
    border: 1px solid #e2e2e2;
    visibility: hidden;
    opacity: 1;
    overflow: hidden;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.search-result-products-item__cart svg {
    width: 16px;
    height: 22px;
    fill: #333;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.search-result-products-item__cart:hover::before {
    visibility: visible;
    opacity: 1;
    overflow: visible;
}
.search-result-products-item__cart:hover svg {
    fill: #333;
}
.search-result-products-item__cart:active::before {
    visibility: visible;
    opacity: 1;
    overflow: visible;
}
.search-result-products-item__cart:active svg {
    fill: #333;
}
.search-bottom {
    border-top: 1px solid #e1e6e9;
    height: 40px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.search-bottom__text {
    margin: 2px 10px;
    color: #333;
    font-size: 15px;
    font-family: "Museo", serif;
}
.search-bottom__link {
    margin: 2px 10px;
    text-decoration: none;
    font-weight: 900;
    color: #333;
    font-size: 14px;
    font-family: "Museo", serif;
}
.search-bottom__link:hover {
    color: #ec4a9b;
    transition: .3s ease-in-out;
    font-size: 14px;
    font-family: "Museo", serif;
}

/************************************* MEDIA ****************************/
@media only screen and (min-width: 1024px) {
    .title-search-result {
        padding: 10px 10px 10px 0;
    }
    .search-result-pull-down {
        padding: 10px 10px 10px 0;
    }
    .search-result-categories-container {
        padding: 10px 0 10px 0;
        height: 270px;
    }
    .search-result-categories-title {
        padding-left: 20px;
    }
    .search-result-categories-list {
        width: 100%;
    }
    .search-result-categories-list__item {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        cursor: pointer;
    }
    .search-result-categories-list__item:hover {
        background: #333;
        color: #fff;
        transition: .3s ease-in-out;
        -webkit-transition: .3s ease-in-out;
    }
    .search-result-categories-list__link {
        display: block;
        width: 100%;
        text-decoration: none;
        color: #333;
        line-height: 18px;
        font-size: 15px;
        padding-left: 20px;
        text-shadow: 0.1px 0.1px 0.8px darkgray;
    }
    .search-result-categories-list__item:hover a {
        color: #fff;
        transition: .3s ease-in-out;
        -webkit-transition: .3s ease-in-out;
    }
    /*.search-result-products-container {*/
    /*	height: 270px;*/
    /*}*/
}

@media only screen and (max-width: 1600px) {
    #search.opened {
        width: 1210px;
    }
    .title-search-result_old{
        width: 1210px !important;
        left: -1210px !important;
    }

}

@media only screen and (max-width: 1470px) {
    #search.opened {
        width: 1200px;
    }
    .title-search-result_old{
        width: 1200px !important;
        left: -1200px !important;
    }
}

@media only screen and (max-width: 1450px) {
    #search.opened {
        width: 1180px;
    }
    .title-search-result_old{
        width: 1180px !important;
        left: -1180px !important;
    }
}

@media only screen and (max-width: 1430px) {
    #search.opened {
        width: 1160px;
    }
    .title-search-result_old{
        width: 1160px !important;
        left: -1160px !important;
    }
}

@media only screen and (max-width: 1410px) {
    #search.opened {
        width: 1140px;
    }
    .title-search-result_old{
        width: 1140px !important;
        left: -1140px !important;
    }
}

@media only screen and (max-width: 1390px) {
    #search.opened {
        width: 1120px;
    }
    .title-search-result_old{
        width: 1120px !important;
        left: -1120px !important;
    }
    .search-result-products-item__description{
        min-width: 400px;
        max-width: 400px;
    }
    .menu-popup__buy {
        margin: 0 10px 0 30px;
    }
    .search-bottom{
        padding-top: 10px;
    }
}

@media only screen and (max-width: 1370px) {
    #search.opened {
        width: 1100px;
    }
    .title-search-result_old{
        width: 1100px !important;
        left: -1100px !important;
    }
}

@media only screen and (max-width: 1350px) {
    #search.opened {
        width: 1080px;
    }
    .title-search-result_old{
        width: 1080px !important;
        left: -1080px !important;
    }
}

@media only screen and (max-width: 1330px) {
    #search.opened {
        width: 1060px;
    }
    .title-search-result_old{
        width: 1060px !important;
        left: -1060px !important;
    }
    .search-result-pull-down {
        padding: 10px 10px 10px 0px;
    }
    .search-result-products-item__description{
        min-width: 350px;
        max-width: 350px;
    }
    .search-result-products-prise-container{
        margin: 3px;
    }
    .menu-popup__buy {
        margin: 0 10px 0 10px;
    }
    .search-result-products-item__photo{
        min-width: 0;
    }
}

@media only screen and (max-width: 1310px) {
    #search.opened {
        width: 1040px;
    }
    .title-search-result_old{
        width: 1040px !important;
        left: -1040px !important;
    }
}

@media only screen and (max-width: 1290px) {
    #search.opened {
        width: 1020px;
    }
    .title-search-result_old{
        width: 1020px !important;
        left: -1020px !important;
    }
}

@media only screen and (max-width: 1270px) {
    #search.opened {
        width: 1000px;
    }
    .title-search-result_old{
        width: 1000px !important;
        left: -1000px !important;
    }
}

@media only screen and (max-width: 1250px) {
    #search.opened {
        width: 980px;
    }
    .title-search-result_old{
        width: 980px !important;
        left: -980px !important;
    }
}

@media only screen and (max-width: 1230px) {
    #search.opened {
        width: 960px;
    }
    .title-search-result_old{
        width: 960px !important;
        left: -960px !important;
    }
}

@media only screen and (max-width: 1210px) {
    #search.opened {
        width: 940px;
    }
    .title-search-result_old{
        width: 940px !important;
        left: -940px !important;
    }
}

@media only screen and (max-width: 1200px) {
    #search{
        width: 115px;
    }
}

@media only screen and (max-width: 1190px) {
    #search.opened {
        width: 920px;
    }
    #search{
        width: 105px;
    }
    .title-search-result_old{
        width: 920px !important;
        left: -920px !important;
    }
    .search-result-pull-down {
        padding: 5px;
        padding-left: 0;
    }
    .search-result-categories-container {
        width: 180px;
    }
    .search-result-products-container .bx_image {
        width: 80px;
        height: 80px;
    }
    .search-result-products-item__description{
        min-width: 300px;
        max-width: 300px;
    }
    .search-result-products-prise-container{
        margin-left: 15px;
        margin-right: 15px;
    }
    .menu-popup__buy {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 1180px) {
    #search{
        width: 105px;
    }
}

@media only screen and (max-width: 1170px) {
    #search.opened {
        width: 900px;
    }
    .title-search-result_old{
        width: 900px !important;
        left: -900px !important;
    }
}

@media only screen and (max-width: 1150px) {
    #search.opened {
        width: 880px;
    }
    .title-search-result_old{
        width: 880px !important;
        left: -880px !important;
    }
}

@media only screen and (max-width: 1130px) {
    #search.opened {
        width: 860px;
    }
    .title-search-result_old{
        width: 860px !important;
        left: -860px !important;
    }
}

@media only screen and (max-width: 1110px) {
    #search.opened {
        width: 840px;
    }
    .title-search-result_old{
        width: 840px !important;
        left: -840px !important;
    }
}

@media only screen and (max-width: 1090px) {
    #search.opened {
        width: 830px;
    }
    .title-search-result_old{
        width: 830px !important;
        left: -830px !important;
    }
}

@media only screen and (max-width: 1080px) {
    .search-result-products-container .bx_image {
        width: 90px;
        height: 90px;
        margin-bottom: 5px;
    }
    .search-wrapper {
        align-items: center;
    }
    .search-top {
        width: 800px;
    }
    .search-top__input {
        width: 800px;
        padding: 0 10px;
    }
    .search-top__button {
        margin: 6px 20px 0 10px;
    }
    .search-result-pull-down {
        padding-left: 0;
        padding-right: 0;
        margin: 0 0;
        max-width: 800px;
    }
    .search-result-categories-container {
        width: 20%;
        padding: 5px 0 5px 0;
    }
    .search-result-categories-title {
        padding-left: 10px;
    }
    .search-result-categories-container::-webkit-scrollbar {
        width: 2px;
    }
    .search-result-categories-list__item {
        margin: 0 0;
        padding-left: 10px;
    }
    .search-result-categories-list__link {
        padding-left: 0;
    }
    .search-result-products-container {
        width: 720px;
        padding-left: 10px;
    }
    .search-result-products-item__photo {
        margin: 3px;
        min-width: 50px;
    }
    .search-result-products-item__description {
        margin-right: 5px;
        min-width: 320px;
        max-width: 320px;
    }
    .search-result-products-item__prise {
        min-width: 80px;
        margin: 5px;
        margin-left: 0;
    }
    .search-result-products-item__cart {
        margin: 10px 2px 10px 2px;
    }
    #search.opened {
        width: 820px;
    }
    .title-search-result_old {
        width: 820px !important;
        left: -820px !important;
    }
}

@media only screen and (max-width: 1070px) {
    #search.opened {
        width: 800px;
    }
    .title-search-result_old{
        width: 800px !important;
        left: -800px !important;
    }
}

@media only screen and (max-width: 1050px) {
    #search.opened {
        width: 780px;
    }
    .title-search-result_old{
        width: 780px !important;
        left: -780px !important;
    }
    .menu-popup__buy {
        margin-left: 2px;
    }
    .search-result-categories-title {
        margin-left: 1px;
    }
}

@media only screen and (max-width: 1030px) {
    .title-search-result_old{
        width: 760px !important;
        left: -760px !important;
    }
}

@media screen and (max-width: 1023px) {
    .title-search-result{
        padding: 10px 10px 10px 10px;
    }
    .search-wrapper {
        width: 100%;
        align-items: center;
    }
    #search.opened {

    }
    #search, #search.opened{
        width: 98%;
        top: -13px;
        left: 4px;
    }
    .title-search-result_old{
        width: auto !important;
        min-width: 273px !important;
        left: calc(2% - 5px) !important;
        right: calc(2% - 5px) !important;
        top: 48px !important;
    }
    .search #h-search-input{
        width: 95% !important;
    }
    .search-result-pull-down {
        max-width: 100%;
    }
    .search-result-categories-container {
        width: 25%;
        height: 270px;
    }
    .search-result-categories-list__item {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .search-result-products-container {

        height: 270px;
        padding: 0 10px 0 20px;
    }
    .search-result-products-container .bx_image {
        width: 80px;
        height: 80px;
    }
    .search-result-products-prise-container {
        margin-left: 30px;
        margin-right: 30px;
    }
    .search-result-products-item {
        width: 100%;
    }
}

@media screen and (max-width: 920px) {
    .search-result-categories-container {
        width: 21%;
    }
}

@media screen and (max-width: 865px) {
    .search-result-products-container {
        padding-left: 10px;
    }
    .search-result-products-item__description {
        margin: 5px;
    }
    .search-result-products-item__prise {
        margin: 5px;
    }
    .search-result-products-prise-container {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 810px) {
    #search {
        left: 10px;
    }
    .search-wrapper {
        margin: unset;
    }
    .search-result-pull-down {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .search-result-categories-title {
        display: block;
        margin-left: 9px;
        margin-bottom: 0;
    }
    .search-result-categories-container {
        width: 100%;
        padding: 5px 5px 0 5px;
        height: 90px;
        overflow-y: visible;
        border-bottom: 1px solid #e1e6e9;
        scrollbar-width: none;
    }
    .search-result-categories-container::-webkit-scrollbar {
        width: 0;
    }
    .search-result-categories-container::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 0;
    }
    .search-result-categories-title_help {
        display: block;
        margin-bottom: 5px;
    }

    .search-result-categories-list {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
        height: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none;
    }
    .search-result-categories-list::-webkit-scrollbar {
        width: 0;
    }
    .search-result-categories-list::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 0;
    }

    .search-result-categories-list__item {
        margin: 2px 10px;
        min-width: 90px;
        margin-right: 35px;
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100%;
    }
    .search-result-products-container {
        width: 100%;
    }

    .search-result-products-item__photo {
        margin-left: 10px;
        margin-right: 20px;
    }
    .search-result-product-item-text {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: row;
        margin-left: 20px;
    }
    .search-result-products-item__description {
        min-width: 300px;
    }
    .search-result-products-item-pc {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .search-result-products-item__prise-old {
        margin-left: 10px;
    }
    .search-result-products-item__prise {
        margin-left: 10px;
        margin-right: 10px;
    }
    .search-result-products-item__cart {
        margin-left: 50px;
    }
    .header{
        /*margin-bottom: 45px;*/
    }
    #search, #search.opened {
        width: 97vw;
    }
    .title-search-result {
    }
    .search-result-products-container {
        margin-top: 10px;
    }
    .search-result-products-prise-container {
        margin-top: 0;
        min-width: 115px;
    }
    .search-result-products-item__description {
        min-width: 330px;
    }
    .search-result-categories-container {
        border-right: unset;
    }
}

@media screen and (max-width: 740px) {
    .search-result-product-item-text {
        margin-left: 0;
    }
    .search-result-products-container {
        margin-left: 0;
    }
    .search-result-products-prise-container {
        margin-left: 3px;
        margin-right: 3px;
        min-width: 125px;
    }
}

@media screen and (max-width: 675px) {
    .search-result-products-item__description {
        min-width: 250px;
        max-width: 250px;
    }
    #search{
        width: auto;
        right: 15px;
        left: 3px;
    }
}

@media screen and (max-width: 600px) {
    .search-result-products-container {
        height: 200px;
    }
    .search-result-products-container .bx_image {
        width: 55px;
        height: 55px;
    }
    .search-result-products-item__description {
        min-width: 240px;
        max-width: 240px;
    }
}

@media screen and (max-width: 570px) {
    .search-result-products-container {
        height: 180px;
    }
    .search-result-products-container .bx_image {
        width: 50px;
        height: 50px;
    }
    .search-result-products-item__description {
        min-width: 200px;
        max-width: 200px;
    }
    .menu-popup__buy {
        width: 30px;
        height: 30px;
    }
    .search-result-products-item__description {
        min-width: 200px;
        max-width: 200px;
    }
}

@media screen and (max-width: 510px) {
    .search-top__input {
        height: 40px;
    }
    .search-top__button {
        margin: 6px 20px 0 5px;
    }
    .search-result-categories-container {
        padding: 2px 5px 2px 5px;
        height: 90px;
    }
    .search-result-categories-list__item {
        min-width: 130px;
        margin-left: 0;
        margin-right: 0;
    }
    .search-result-categories-title {
        margin-left: 0;
    }
    .search-result-products-container {
        width: 100%;
        padding-left: 0;
        height: 260px;
    }
    .search-result-products-item {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start;
    }
    .search-result-products-item__photo {
        margin-right: 5px;
    }
    .search-result-product-item-text {
        margin-left: 25px;
        max-width: 250px;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        align-items: flex-start;
        -webkit-box-align: start;
        -ms-flex-align: start;
    }
    .search-result-products-item__description {
        max-width: 300px;
        margin-left: 0;
        margin-right: 0;
    }
    .search-result-products-item__prise-old {
        margin-left: 0;
    }
    .search-result-products-item__prise {
        margin-left: 0;
        margin-right: 30px;
    }
    .search-result-products-item__cart {
        margin: 10px 2px 10px 90px;
    }
    .title-search-result {
        width: 97vw !important;
    }
    .search-result-products-container .bx_image {
        width: 75px;
        height: 75px;
    }
}

@media screen and (max-width: 500px) {
    .title-search-result {
        width: auto !important;
        min-width: 273px;
    }
}

@media screen and (max-width: 460px) {
    .search-result-product-item-text {
        margin-left: 10px;
    }
    .search-result-products-item__description {
        min-width: 240px;
        max-width: 240px;
    }
}
@media screen and (max-width: 370px) {
    .search-result-product-item-text {
        margin-left: 25px;
    }
    .search-result-products-item__description {
        min-width: 200px;
        max-width: 200px;
    }
}