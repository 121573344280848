.search-bg.active-bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0, 0, 0, .4);
    -webkit-transition: .3s;
    transition: .3s;
    cursor: pointer;
}

@media (min-width: 1024px) {
    .search-bg.active-bg {
        z-index: 8;
        cursor: pointer;
        visibility: visible;
    }
}

.search {
    width: 350px;
}

.query-history-item {
    margin: 0 0 15px;
}

.search.opened .search-holder {
    z-index: 10101;
    /*position: absolute;*/
    /*top: 26px;*/
    /*right: 0;*/
    /*left: 300px;*/
}

@media (max-width: 1900px) {
    .search.opened .search-holder {
        left: 280px;
    }
}

@media (max-width: 1374px) {
    .search.opened .search-holder {
        left: 260px;
    }
}


.search.opened .title-search-result {
    opacity: 2;
    visibility: visible;
}


@media (min-width: 1024px) {
    .search.opened .title-search-result {
        opacity: 0;
        visibility: visible;
        border: 1px solid hsla(0,0%,78%,.471);
        box-shadow: 0 -2px 20px 6px rgb(0 0 0/10%);
    }

    /*.modal-body--product-holder .product-item-image-holder img {
        width: auto;
        height: 100%;
        display: block;
    }*/

    .search.opened .title-search-result.show {
        opacity: 2;
    }

    .catalog-list .product-item:hover .in-basket #hover-text {
        display: inline-block!important;
        min-width: max-content;
    }

    .title-search-result {
        padding: 10px 10px 0 0 !important;
    }
}

@media (min-width: 1024px) and (max-width: 1100px) {
    .user-panel .link span {
        font-size: 13px;
        line-height: 15px;
    }
    .user-name {
        max-width: 120px!important;
    }
    .header-top .usd {
        font-size: 12px;
    }
    .languages {
        margin-right: 40px!important;
    }
    .header-top-menu li {
        line-height: 18px!important;
    }
    .bonus-sum.big-sum {
        max-width: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
}

.search.opened .close-search {
    right: 50px;
    /*top: 10px;*/
}

.search.opened .search-input.has-val .close-search {
    visibility: visible;
    z-index: 20;
    opacity: .6;
}

.search.opened .search-input.has-val .js-clear-input {
    top: 11px;
}

.search .search-result-main-info {
    padding: 15px 0 18px 35px;
}

.search .search-result-category-holder {
    width: 220px;
    /*border-right: solid 1px #eeeeee;*/
    max-height: 341px;
    overflow-y: hidden;
}

.search .search-result-category-holder.list-item {
    width: 95%;
}

.search .total_result_categori-item .count {
    color: #828282;
    margin-left: 5px;
}

.search .addition-items-holder {
    display: flex;
    flex-direction: column;
    flex-basis: 445px;
}

.search .main-items-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.search .item_block_holder {
    padding-left: 30px;
    height: 426px;
    overflow-y: hidden;
}

.search .item_block_holder .bx_image {
    width: 100%;
    height: 80px;
    background-repeat: no-repeat;
    background-position: 50%;
    display: inline-block;
    background-size: contain;
}

.search .item_block_holder .bx_item_block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 10px;
    /*margin: 15px 0 15px;*/
}

.search .item_block_holder .bx_img_element {
    width: 80px;
    margin-right: 21px;
}

.search .item_block_holder .bx_img_element a:hover {
    border-color: transparent;
}

.search .item_block_holder .bx_item_element {
    width: -webkit-calc(100% - 95px);
    width: calc(100% - 95px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.search .item_block_holder .code {
    display: inline-block;
    width: 100%;
    line-height: 1.8;
    font-size: 12px;
    color: #999999;
}

.search .item_block_holder .product_name {
    color: #000000;
    font-size: 13px;
}

.search .item_block_holder .product_name span {
    color: #ff9900;
}

.search .item_block_holder .info_product_holder {
    flex: 1 70px;
    padding-right: 10px;
    align-self: flex-start;
    margin-top: 5px;
}

.search .item_block_holder .info_price_holder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 15px;
    width: 15%;
    min-width: 110px;
    align-self: flex-start;
}

.search .item_block_holder .info_price_holder .bx_price_ua {
    width: 100%;
    color: #333333;
    font-family: 'Montserrat', sans-serif; font-weight: normal;
    margin: 5px 12px 0 20px;
    font-size: 14px;
    white-space: nowrap;
}

.search .item_block_holder .info_price_holder .bx_old_price_ua {
    font-size: 11px;
    position: relative;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: -4px;
    line-height: 13px;
    color: #555555;
}

.search .item_block_holder .info_price_holder .bx_old_price_ua:after {
    content: '';
    height: 1px;
    background: #e24a4a;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -1px;
}

.search .item_block_holder .info_price_holder .bx_price_usd {
    width: 100%;
    color: #999999;
    display: none;
}

.search .item_block_holder .buy_holder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 10%;
}

.search .item_block_holder .buy_holder .buy_btn {
    width: 50px;
    height: 40px;
    padding: 0;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #ffffff;
    border: none;
    -webkit-transition: background .3s;
    transition: background .3s;
}

.search .item_block_holder .buy_holder .buy_btn.in-basket {
    background: #0080cc;
}

.search .item_block_holder .buy_holder .buy_btn:hover {
    background: #0080cc;
    opacity: 0.8;
}

.search .item_block_holder .buy_holder .buy_btn:hover [data-show="basket"] g {
    stroke: #fff;
    opacity: 1;
}

.search .item_block_holder .buy_holder .buy_btn [data-show="basket"] g {
    stroke: #fff;
    opacity: 1;
}

.search .item_block_holder .buy_holder .buy_btn:hover .icon-basket_blue {
    width: 50px;
    height: 40px;
    background-position: -5px -740px;
}

.search .title-search-result {
    position: absolute;
    top: -webkit-calc(100% - -2px);
    top: calc(100% - -2px);
    opacity: 0;
    visibility: hidden;
    left: 0;
    right: 0;
    z-index: 10;
    background: #ffffff;
    padding-right: 18px;
    border: 1px solid hsla(0,0%,78%,.471);
    box-shadow: 0 -2px 20px 6px rgb(0 0 0/10%);
}

.search .search-result-main-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px;
}

.search .search-result-main-info .total_result_title {
    font-family: 'Montserrat', sans-serif; font-weight: normal;
    margin: 0 0 20px;
    font-size: 15px;
}

.search .search-result-main-info .total_result_categori-item {
    margin: 10px 5px 15px 0;
}

.search .search-result-total-info {
    padding: 20px 30px 0 30px;
    text-align: center;
}

.search .search-result-total-info .search-result-text {
    letter-spacing: 0.01em;
    font-size: 16px;
    color: #777777;
    display: inline-block;
    margin-right: 20px;
}

.search .search-result-total-info .search-result-link {
    font-size: 15px;
    letter-spacing: 0.01em;
}

.search .bx_searche.no_result .search-result-main-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    padding: 60px 30px 55px;
}

.search .bx_searche.no_result .search_no_res_main_info {
    display: inline-block;
    width: 100%;
    font-family: 'Montserrat', sans-serif; font-weight: normal;
    font-size: 20px;
}

.search .bx_searche.no_result .search_no_res_sub_info {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    margin: 20px 0;
}

.search hr.line {
    border: none;
    height: auto;
    background-color: #F3F3F3;
    width: 1px;
    margin: 0;
}

.search .total_result_categori-items a,
.search .search-result-total-info .search-result-link {
    color: #0080CC;
}

.search .total_result_categori-items,
.search .potential-query-list {
    font-family: 'Montserrat', sans-serif; font-weight: 400;
}


span.query-name {
    font-size: 13px;
    border-bottom: 1px solid transparent;
    webkit-transition: color .3s, border .3s;
    transition: color .3s, border .3s;
}
span.query-name:hover {
    cursor: pointer;
    border-color: #5D99D1;
}

.delete-query {
    width: 30px;
    height: 28px;
    position: absolute;
    cursor: pointer;
    z-index: 999;
}

.delete-query:before {
    content: '';
    width: 1px;
    height: 13px;
    background: #B6B6B6;
    position: absolute;
    left: -webkit-calc(50% - 1px);
    left: calc(50% - 1px);
    top: -webkit-calc(50% - 8px);
    top: -webkit-calc(50% - 11px);
    top: calc(50% - 11px);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    border-radius: 2px;
    -webkit-transition: .4s;
    transition: .4s;
}

.delete-query:after {
    content: '';
    width: 1px;
    height: 13px;
    background: #B6B6B6;
    position: absolute;
    left: -webkit-calc(50% - 1px);
    left: calc(50% - 1px);
    top: -webkit-calc(50% - 8px);
    top: -webkit-calc(50% - 11px);
    top: calc(50% - 11px);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    border-radius: 2px;
    -webkit-transition: .4s;
    transition: .4s;
}

.search #h-search-input{
    border: none !important;
    border-bottom: 1px solid #d5dadc !important;;
    box-shadow: none !important;
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
    height: 34px;
    line-height: 1.42857143;
    margin-bottom: 0;
    outline: none;
    padding: 6px 12px 0;
    position: relative;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
    z-index: 2;
}

.search-submit svg {
    fill: #333;
    height: 23px;
    width: 15px;
}

.search {
    margin: 10px 0 0;
    position: absolute;
    right: 0 !important;
    top: -27px !important;
    transition: .3s ease-in-out;
    width: 180px;
    background: #fff;
    z-index: 10;
}
.search .bx-block-title {
    font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #343434;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 9px;
    font-weight: normal;
}
.search .bx-input-group {
    position: relative;
    display: table;
    border-collapse: separate;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.search .bx-input-group {}
.search .bx-input-group .bx-form-control {
    display: table-cell;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px 0 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    border-bottom: 1px solid #d5dadc;
    border-left: none;
    outline: none;
    box-shadow: none !important;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.bx-ios .search .bx-input-group .bx-form-control {
    /* Fix of input shadow for IOS browsers. */
    /*background-image: -webkit-linear-gradient(#f8fafc 0%, #f8fafc 100%);
    background-image: linear-gradient(#f8fafc 0%, #f8fafc 100%);*/
}
.search .bx-input-group-btn {
    position: relative;
    font-size: 0;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
}
.search .bx-input-group-btn button {
    position: relative;
    border: none;
    background: unset;
    display: inline-block;
    margin-bottom: 0;
    cursor: pointer;
    padding: 6px 6px 6px 12px;
    border-radius: 0;
    color: #95a1a8;
    font-size: 16px;
    line-height: 1.42857143;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.search.opened {
    width: 1250px;
}

@media only screen and (max-width: 1600px) {
    .search.opened {
        width: 1210px !important;
    }

}

@media only screen and (max-width: 1470px) {
    .search.opened {
        width: 1200px !important;
    }
}

@media only screen and (max-width: 1450px) {
    .search.opened {
        width: 1180px !important;
    }
}

@media only screen and (max-width: 1430px) {
    .search.opened {
        width: 1160px !important;
    }
}

@media only screen and (max-width: 1410px) {
    .search.opened {
        width: 1140px !important;
    }
}

@media only screen and (max-width: 1390px) {
    .search.opened {
        width: 1120px !important;
    }
    .search-result-products-item__description{
        min-width: 400px;
        max-width: 400px;
    }
    .menu-popup__buy {
        margin: 0 10px 0 30px;
    }
    .search-bottom{
        padding-top: 10px;
    }
}

@media only screen and (max-width: 1370px) {
    .search.opened {
        width: 1100px !important;;
    }
}

@media only screen and (max-width: 1350px) {
    .search.opened {
        width: 1080px !important;;
    }
}

@media only screen and (max-width: 1330px) {
    .search.opened {
        width: 1060px !important;;
    }
    .search-result-pull-down {
        padding: 10px 10px 10px 0px;
    }
    .search-result-products-item__description{
        min-width: 350px;
        max-width: 350px;
    }
    .search-result-products-prise-container{
        margin: 3px;
    }
    .menu-popup__buy {
        margin: 0 10px 0 10px;
    }
    .search-result-products-item__photo{
        min-width: 0;
    }
}

@media only screen and (max-width: 1310px) {
    .search.opened {
        width: 1040px !important;;
    }
}

@media only screen and (max-width: 1290px) {
    .search.opened {
        width: 1020px !important;;
    }
}

@media only screen and (max-width: 1270px) {
    .search.opened {
        width: 1000px !important;;
    }
}

@media only screen and (max-width: 1250px) {
    .search.opened {
        width: 980px !important;;
    }
}

@media only screen and (max-width: 1230px) {
    .search.opened {
        width: 960px !important;;
    }
}

@media only screen and (max-width: 1210px) {
    .search.opened {
        width: 940px !important;;
    }
}

@media only screen and (max-width: 1200px) {
    .search{
        width: 115px;
    }
}

@media only screen and (max-width: 1190px) {
    .search.opened {
        width: 920px !important;;
    }
    .search{
        width: 105px;
    }
    .search-result-pull-down {
        padding: 5px;
        padding-left: 0;
    }
    .search-result-categories-container {
        width: 180px;
    }
    .search-result-products-container .bx_image {
        width: 80px;
        height: 80px;
    }
    .search-result-products-item__description{
        min-width: 300px;
        max-width: 300px;
    }
    .search-result-products-prise-container{
        margin-left: 15px;
        margin-right: 15px;
    }
    .menu-popup__buy {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 1180px) {
    .search{
        width: 105px;
    }
}

@media only screen and (max-width: 1170px) {
    .search.opened {
        width: 900px !important;;
    }
}

@media only screen and (max-width: 1150px) {
    .search.opened {
        width: 880px !important;
    }
}

@media only screen and (max-width: 1130px) {
    .search.opened {
        width: 860px !important;;
    }
}

@media only screen and (max-width: 1110px) {
    .search.opened {
        width: 840px !important;;
    }
}

@media only screen and (max-width: 1090px) {
    .search.opened {
        width: 830px !important;;
    }
}

@media only screen and (max-width: 1080px) {
    .search-result-products-container .bx_image {
        width: 90px;
        height: 90px;
        margin-bottom: 5px;
    }
    .search-wrapper {
        align-items: center;
    }
    .search-top {
        width: 800px;
    }
    .search-top__input {
        width: 800px;
        padding: 0 10px;
    }
    .search-top__button {
        margin: 6px 20px 0 10px;
    }
    .search-result-pull-down {
        padding-left: 0;
        padding-right: 0;
        margin: 0 0;
        max-width: 800px;
    }
    .search-result-categories-container {
        width: 20%;
        padding: 5px 0 5px 0;
    }
    .search-result-categories-title {
        padding-left: 10px;
    }
    .search-result-categories-container::-webkit-scrollbar {
        width: 2px;
    }
    .search-result-categories-list__item {
        margin: 0 0;
        padding-left: 10px;
    }
    .search-result-categories-list__link {
        padding-left: 0;
    }
    .search-result-products-container {
        width: 720px;
        padding-left: 10px;
    }
    .search-result-products-item__photo {
        margin: 3px;
        min-width: 50px;
    }
    .search-result-products-item__description {
        margin-right: 5px;
        min-width: 320px;
        max-width: 320px;
    }
    .search-result-products-item__prise {
        min-width: 80px;
        margin: 5px;
        margin-left: 0;
    }
    .search-result-products-item__cart {
        margin: 10px 2px 10px 2px;
    }
    .search.opened {
        width: 820px !important;
    }
}

@media only screen and (max-width: 1070px) {
    .search.opened {
        width: 800px !important;
    }
}

@media only screen and (max-width: 1050px) {
    .search.opened {
        width: 780px !important;
    }
    .menu-popup__buy {
        margin-left: 2px;
    }
    .search-result-categories-title {
        margin-left: 1px;
    }
}

@media only screen and (max-width: 1030px) {

}

@media screen and (max-width: 1023px) {
    .search-wrapper {
        /*display: inline-block;*/
        /*cursor: pointer;*/
        /*width: 100%;*/
        /*order: -1;*/
        width: 100%;
        align-items: center;
        margin-top: 14px;
    }
    .search.opened {
        padding: 10px 0px 0px;
        position: fixed;
        top: 0 !important;
        right: 0;
        left: 0;
        width: 100% !important;
        background: #fff;
        margin: 0;
        z-index: 10102;
    }
    .search, .search.opened{
        /*flex-direction: column;
        width: 98% !important;
        top: -13px;
        left: 4px;*/
    }
    .search{
        flex-direction: column;
        width: 98% !important;
        top: -13px;
        left: 4px;
    }
    .title-search-result{
        width: auto !important;
        min-width: 273px !important;
        /*left: calc(2% - 5px) !important;*/
        /*right: calc(2% - 5px) !important;*/
        top: 48px !important;
        border: none !important;
        box-shadow: none !important;
    }
    .search-result-pull-down {
        max-width: 100%;
    }
    .search-result-categories-container {
        width: 25%;
        height: 270px;
    }
    .search-result-categories-list__item {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .search-result-products-container {

        height: 270px;
        padding: 0 10px 0 20px;
    }
    .search-result-products-container .bx_image {
        width: 80px;
        height: 80px;
    }
    .search-result-products-prise-container {
        margin-left: 30px;
        margin-right: 30px;
    }
    .search-result-products-item {
        width: 100%;
    }
}

@media screen and (max-width: 570px) {
    .search .search-result-main-info {
        display: flex;
        font-size: 15px;
        flex-direction: column;
        padding: 5px 0 10px 8px;
    }

    .search .search-result-main-info .total_result_categori-item {
        margin: 10px 5px 10px 0;
    }

    .search .search-result-main-info .total_result_title {
        margin: 0 0 10px;
        font-size: 18px;
    }

    .search .item_block_holder {
        padding-left: 0px;
        margin-top: 20px;
    }

    .search .addition-items-holder{
        flex-basis: auto;
    }

    .search .item_block_holder .info_product_holder{
        margin-left: 15px;
    }

    .search .item_block_holder .bx_item_block{
        margin-bottom: 5px;
    }
}

@media (max-width: 767px) {
    .search.opened .title-search-result {
        position: fixed;
        max-height: unset;
        height: calc(100% - 49px);
        overflow-y: auto;
    }
}

body.side-open {
    height: 100%;
    position: relative;
    overflow: hidden;
}