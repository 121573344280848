@media (min-width: 1024px) {
    .menu-column {
        width: 20%;
        display: flex;
        flex-direction: column;
    }

    .menu-column-title {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        margin: 15px 0 0;
        border-bottom: none;
    }

    .menu-column-title:hover {
        color: #ec4a9b;
    }

    span.menu-column-title {
        cursor: not-allowed;
    }

    a.menu-column-title:hover {
        text-decoration: underline;
    }
}

.menu-column-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle-subsection {
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    padding: 0 8px;
    margin: 15px 0 0;
}