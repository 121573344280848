.potential-query-list {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
    padding-bottom: 20px;
    padding-top: 5px;
}

.potential-query-list div {
    background: #f2f2f2;
    padding: 3px 8px;
    width: max-content;
}

.potential-query-list div span {
    color: #000;
    font-size: 13px;
    line-height: 20px;
}

.potential-query-list div span:hover {
    cursor: pointer;
    border-bottom: 1px solid #5D99D1;
}

.potential-query-list div span b {
    color: #999;
    font-weight: 400;
}

@media (max-width: 1023px) {
    .potential-query-list {
        flex-flow: row wrap;
        padding-top: unset;
    }

    .potential-query-list div span:hover {
        cursor: pointer;
        border-bottom: 1px solid #5D99D1;
    }
}