
.g-recaptcha div:first-child {
    margin: 0 auto;
}

.captcha-error-form-send-text{
    margin: 0 auto;
    text-align: center;
    color: red;
}

.add-answer__form .g-recaptcha div:first-child{
    margin-left: 0;
}
.add-answer__form .captcha-error-form-send-text{
    text-align: left;
}

.catalog__faq {
    margin: 0 auto;
    margin-bottom: 80px;
    max-width: 864px;
}

@media only screen and (max-width: 1100px) {
    .catalog__faq {
        overflow-x: scroll;
    }
}

.catalog__faq-param {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #99a3a8;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 34px;
}

.catalog__faq-param .product-price {
    margin-left: 10px;
    font-weight: normal;
    color: #768187;
}

.catalog__faq-param .product-price sup {
    top: 0;
}

.catalog__faq-repeat {
    font-family: 'PT Serif', serif;
    min-width: 220px;
}

.catalog__faq-item {
    margin-bottom: 20px;
    position: relative;
    border: 1px solid #e1e6e9;
}

.catalog__faq-item > .catalog__faq-grid {
    cursor: pointer;
}

.catalog__faq-item .catalog__faq-grid {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.catalog__faq-item .catalog__faq-grid-cell {
    font-weight: normal;
    position: relative;
    color: #333;
    font-size: 16px;
}

.catalog__faq-item .catalog__faq-grid-cell::after {
    position: absolute;
    content: '';
    right: 0;
    top: 50%;
    height: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-right: 1px solid #cecece;
}

.catalog__faq-item-opener {
    position: absolute;
    top: 0;
    right: 0;
    height: 44px;
    width: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #e6ebec;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    pointer-events: none;
}

.catalog__faq-item-opener svg {
    width: 6px;
    height: 6px;
    fill: #666;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.catalog__faq-item-total {
    margin: 30px 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.catalog__faq-item-total .total-item {
    font-size: 13px;
    color: #89959b;
    margin-bottom: 10px;
}

.catalog__faq-item-total .total-item__heading {
    font-size: 14px;
    font-weight: normal;
    color: #768187;
}

.catalog__faq-item.active .catalog__faq-content {
    height: auto;
}

.catalog__faq-item.active > .catalog__faq-grid {
    background: #eff4f6;
}

.catalog__faq-item.active .catalog__faq-item-opener {
    background: #b1bcc1;
}

.catalog__faq-item.active .catalog__faq-item-opener svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    fill: #fff;
}

.catalog__faq-content {
    padding: 0 35px;
    height: 0;
    overflow: hidden;
}

.catalog__faq-content .catalog__faq-grid {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.5);
}

.catalog__faq-content .catalog__faq-grid-cell::after {
    display: none;
}

.catalog__faq-content .btn-simple {
    font-weight: 400;
    font-style: italic;
    padding: 10px 20px;
    margin-bottom: 15px;
}

.catalog__faq-content .comment-holder {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.catalog__faq-content .checkout-basket__item {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 35px 90px 25px 0;
    border-bottom: 1px solid #e1e6e9;
}

.catalog__faq-content .checkout-basket__image {
    max-width: none;
    margin-right: 30px;
}

.catalog__faq-content .checkout-basket__content {
    min-width: 195px;
    margin-right: 60px;
}

.catalog__faq-content .checkout-basket__content .checkout-basket__price-current {
    color: #89959b;
}

.catalog__faq-content .checkout-basket__price-box {
    margin-left: 0;
}

.catalog__faq-content .basket-popup__title {
    margin-bottom: 5px;
}

.catalog__faq-content .product-page__article {
    margin: 0 0 15px;
}

.catalog__faq-content .basket-popup__quantity {
    pointer-events: none;
}

.catalog__faq-content .basket-popup__quantity svg {
    opacity: .6;
}

.catalog__faq-content .product-page__article {
    padding: 0;
    border: none;
}

.catalog__faq-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.catalog__faq-grid-cell {
    padding: 14px;
    color: #8b979e;
    font-size: 13px;
    max-width: 85%;
}

.catalog__faq-grid-cell .product-title {
    text-align: left;
    margin-bottom: 5px;
    color: #333;
}

.catalog__faq-grid-cell .articul {
    margin-bottom: 10px;
}

.catalog__faq-grid-cell .product-item__price {
    text-align: left;
}

.catalog__faq-grid-cell:last-child::after {
    display: none;
}

.catalog__faq .items-list a{
    color: #333;
    text-decoration: none;
    transition: 0.3s ease-in-out;
}

.catalog__faq .items-list a:hover{
    color: #ec4a9b;
}

.add-photo {
    position: relative;
    cursor: pointer;
    margin-right: 0 !important;
}

.add-photo input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.add-reviews__form .form-media {
    position: relative;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
}

.add-reviews__form .form-media::before {
    content: none !important;
}

#photo-preview img {
    margin-top: 10px;
}

.reviews-block__text {
    position: relative;
    display: flex;
    flex-direction: column !important;
    gap: 20px;
}

.reviews-block__text img {
    max-width: 20%;
}

.reviews-fancybox .fancybox__content {
    flex: 0 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90% !important;
    height: 90% !important;
    margin: 0 var(--fancybox-slide-gap) 0 0;
    padding: 4px;
    overflow: auto;
    overscroll-behavior: contain;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
}