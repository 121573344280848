.query-history-list {
    width: 80%;
    padding-bottom: 5px;
}

@media (max-width: 768px) {
    .query-history-list {
        width: 100%;
        border-bottom: 1px solid #E8E8E8;
        margin-bottom: 20px;
    }
}