@charset "UTF-8";

html {
    line-height: 1.15;
    /* 1 */
    -ms-text-size-adjust: 100%;
    /* 2 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
    display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
    /* 1 */
    display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
    margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    /* 1 */
    height: 0;
    /* 1 */
    overflow: visible;
    /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
    background-color: transparent;
    /* 1 */
    -webkit-text-decoration-skip: objects;
    /* 2 */
}

a:active {
    color: #ec4a9b; /* Цвет активных ссылок */
}

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
    border-bottom: none;
    /* 1 */
    text-decoration: underline;
    /* 2 */
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
    font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
    font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
    font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
    background-color: #ff0;
    color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
    font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

button {
    font-family: 'Open Sans', serif;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
    display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
    border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
    overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
    margin: 0;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
    /* 1 */
    overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
    /* 1 */
    text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* 1 */
    color: inherit;
    /* 2 */
    display: table;
    /* 1 */
    max-width: 100%;
    /* 1 */
    padding: 0;
    /* 3 */
    white-space: normal;
    /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
    display: inline-block;
    /* 1 */
    vertical-align: baseline;
    /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
    overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    outline-offset: -2px;
    /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
    display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
    display: list-item;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
    display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
    display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
    display: none;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: none;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

.dropdown > input:not([type="checkbox"]):not([type="radio"]) {
    padding-left: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input::-ms-clear {
    display: none;
}

.product-page__main .product__image-inner img {
    width: 100%;
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    --aspect-ratio: attr(width) / attr(height);
}

.ic-reviews {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url("images/reviews.svg");
    display: inline-block;
}

.intentional_callback a{
    text-decoration: none;
    color: #333;
}

.intentional_callback{
    margin-top: 3px;
}

.select__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.breadcrumb li + li::after {
    background-image: url(./images/sprite.png);
    background-position: -13px 0px;
    width: 5px;
    height: 6px;
}

.add-cart__status span::before, .product-page__status span::before, .product-page__buttons-item .chek::before {
    background-image: url(./images/sprite.png);
    background-position: 0px 0px;
    width: 8px;
    height: 8px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .breadcrumb li + li::after {
        background-image: url(./images/sprite@2x.png);
        background-size: 18px 8px;
    }

    .add-cart__status span::before, .product-page__status span::before, .product-page__buttons-item .chek::before {
        background-image: url(./images/sprite@2x.png);
        background-size: 18px 8px;
    }
}

* {
    outline: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*::after, *::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

svg {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

input[type="submit"] {
    cursor: pointer;
}

input[type="false"] {
    cursor: pointer;
}

h1 {
    font-size: 34px;
    line-height: 36px;
    font-weight: normal;
}

h2 {
    font-size: 24px;
    line-height: 26px;
    font-weight: normal;
}

h3 {
    font-size: 22px;
    line-height: 24px;
    font-weight: normal;
}

h4 {
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
}

h5 {
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
}

h6 {
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
}

p.error {
    font-size: 10px;
    line-height: 12px;
    color: #ec4a9b;
    margin: 5px 0 0;
}

.wrapper {
    overflow: hidden;
    position: relative;
    min-height: 100vh;
}

.holder-block {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.fixed-filter {
    overflow: hidden;
    position: relative;
    height: 100%;
}

input {
    font-family: 'Open Sans', serif;
}

input:focus, input:active {
    border-color: #899398;
}

input.error {
    border-color: #ec4a9b;
}

input:disabled {
    background: #f6f9fa;
    border-color: #e1e6ea;
    color: rgba(139, 151, 158, 0.5);
    pointer-events: none;
    cursor: not-allowed;
}

textarea {
    font-family: 'Open Sans', serif;
}

textarea:focus, textarea:active {
    border-color: #899398;
}

textarea.error {
    border-color: #ec4a9b;
}

.main-textarea {
    resize: none;
    width: 100%;
    height: 100px;
    padding: 15px 22px;
    border: 1px solid #e1e6ea;
    color: #333;
    font-size: 13px;
}

.main-textarea::-webkit-input-placeholder {
    color: #bac2c7;
}

.main-textarea::-moz-placeholder {
    color: #bac2c7;
}

.main-textarea::-ms-input-placeholder {
    color: #bac2c7;
}

.main-textarea::placeholder {
    color: #bac2c7;
}

.main-title {
    font-size: 32px;
    color: #333;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
    .main-title {
        font-size: 20px;
        margin-bottom: 18px;
    }
}

.main-link, .main-link-more-items {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    text-decoration: none;
}

.main-link::before, .main-link-more-items::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #e1e6e9;
    pointer-events: none;
}

.main-link::after, .main-link-more-items::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #e1e6e9;
    pointer-events: none;
}

.main-link span, .main-link-more-items span {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 13px;
    text-align: center;
    color: #333;
    padding: 0 62px;
    text-transform: uppercase;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.main-link-more-items span {
    padding: 0 20px 0 60px;
}

.main-link-more-items span::before {
    background-image: url('./images/more_arrow.png');
    content: '';
    display: block;
    left: 10%;
    height: 29px;
    width: 30px;
    margin-top: -7px;
    pointer-events: none;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
}

.main-link span::after {
    border-bottom: 1px solid #333;
    border-right: 1px solid #333;
    content: '';
    display: block;
    height: 6px;
    width: 6px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 45px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

@media only screen and (max-width: 767px) {
    .main-link span {
        padding: 0 32px;
    }

    .main-link-more-items span {
        padding: 0 20px 0 50px;
    }

    .main-link span::after {
        right: 20px;
    }

    .main-link-more-items span::before {
        height: 20px;
        width: 22px;
        margin-top: -4px;
    }
}

@media only screen and (min-width: 1025px) {
    .main-link:hover, .main-link-more-items:hover {
        color: #ec4a9b;
    }

    .main-link:hover span, .main-link-more-items:hover span {
        color: #ec4a9b;
    }

    .main-link:hover span::after {
        border-color: #ec4a9b;
    }
}

.text p:first-of-type {
    margin-top: 0;
}

.text p:last-of-type {
    margin-bottom: 0;
}

.main-input {
    border: 1px solid #e1e6ea;
    border-radius: 0;
    height: 40px;
    width: 100%;
    color: #333;
    font-size: 12px;
    padding: 0 22px;
}

.main-input.error {
    border-color: #ec4a9b;
}

.main-input::-webkit-input-placeholder {
    color: #8b979e;
}

.main-input::-moz-placeholder {
    color: #333;
}

.main-input::-ms-input-placeholder {
    color: #333;
}

.main-input::placeholder {
    color: #333;
}

.submit-btn {
    text-decoration: none;
    border: none;
    max-width: 240px;
    width: 100%;
    height: 40px;
    background: #ec4a9b;
    font-size: 13px;

    color: #fff;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .submit-btn:hover {
        background: #333;
    }
}

.submit-btn--black {
    background: #333;
}

.submit-btn--black:hover {
    color: white;
}

@media only screen and (min-width: 1025px) {
    .submit-btn--black:hover {
        background: #ec4a9b;
    }
}

.submit-btn--white {
    background: #fff;
    border: 1px solid #c3ced5;
    color: #333;
}

@media only screen and (min-width: 1025px) {
    .submit-btn--white:hover {
        color: #fff;
    }
}

.submit-btn--gray {
    background: #8b979e;
}

@media only screen and (min-width: 1025px) {
    .submit-btn--gray:hover {
        background: #ec4a9b;
    }
}

button.submit-btn {
    display: block;
    text-align: center;
}

.white-btn {
    cursor: pointer;
    font-size: 14px;
    color: #333;
    text-decoration: none;
    background: #fff;
    border: 1px solid #d2d8db;
    max-width: 220px;
    width: 100%;
    height: 44px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .white-btn:hover {
        color: #fff;
        background: #333;
        border-color: #333;
    }
}

.black-btn {
    cursor: pointer;
    font-size: 14px;

    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    background: #333;
    max-width: 220px;
    width: 100%;
    height: 44px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .black-btn:hover {
        background: #ec4a9b;
    }
}

.head-block {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 35px;
}

@media only screen and (max-width: 767px) {
    .head-block {
        margin: 0 auto 16px;
    }
}

.head-title {
    font-size: 32px;

    color: #333;
    text-transform: uppercase;
    text-align: center;
}

.head-title--thin {
    font-family: 'Open Sans', serif;
    margin-bottom: 20px;
}

.head-block__subtitle {
    font-size: 20px;
    color: #333;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Open Sans', serif;
    padding: 15px 0px;
}

.payment-checkbox-wrap {
    max-width: 400px;
    width: 100%;
    max-height: 200px;
    margin: 0 auto 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 50px 0px 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.payment-output {
    height: 50px;
    width: 300px;
    margin: 0 auto 100px;
}

.error-list-block {
    max-width: 1192px;
    width: 100%;
    margin: 0 auto 80px;
    color: #ec4a9b;
    text-align: center;
}

.order-block {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    .head-title {
        font-size: 20px;
        padding: 0 15px;
    }
}

.breadcrumb {
    margin: 45px auto 18px;
    padding: 0;
    list-style: none;
    max-width: 1480px;
    letter-spacing: -4px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}

@media only screen and (max-width: 1199px) {
    .breadcrumb {
        padding: 0 15px;
    }
}

.breadcrumb li {
    display: inline-block;
    vertical-align: top;
    letter-spacing: 0;
    position: relative;
    font-weight: normal;
}

.breadcrumb li + li {
    margin-left: 40px;
}

.breadcrumb li + li::after {
    content: '';
    position: absolute;
    top: 6px;
    left: -20px;
}

.breadcrumb a {
    color: #000;
    text-decoration: none;
    font-weight: normal;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.breadcrumb a span {
    color: #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .breadcrumb a span:hover {
        color: #ec4a9b;
        border-bottom: 1px dotted #ec4a9b;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }
}

.breadcrumb span {
    color: #333;
}

@media only screen and (max-width: 767px) {
    .breadcrumb {
        overflow-x: auto;
        overflow-y: unset;
        display: flex;
        flex-wrap: nowrap;
        -webkit-overflow-scrolling: touch;
        padding: 2px 0;
        margin: 0 15px;
        scrollbar-width: none;
    }
    .breadcrumb::-webkit-scrollbar {
        display: none;
    }
    .breadcrumb li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        vertical-align: unset;
    }
}

.pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.pagination li {
    margin: 0 10px;
}

.pagination li a,
.pagination li span {
    cursor: pointer;
    width: 22px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 15px;
    color: #333;

}

.pagination li span.current {
    cursor: not-allowed;
    color: #ec4a9b;
}

@media only screen and (max-width: 500px) {
    .pagination li a,
    .pagination li span {
        display: none;
    }
}

/*.pagination li span {
    border-top: 2px solid #b0bfc8;
    border-bottom: 2px solid #b0bfc8;
    font-size: 18px;
    color: #333;
}*/

@media only screen and (max-width: 600px) {
    .pagination li span {
        display: flex;
    }
}

.pagination li.first {
    margin-right: 30px;
}

.pagination li.first a, .pagination li.first span {
    width: auto;
    height: 36px;
    font-size: 12px;
    color: #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.pagination li.first a svg, .pagination li.first span svg {
    width: 5px;
    height: 6px;
    fill: #333;
    margin: 2px 10px 0 0;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .pagination li.first a:hover, .pagination li.first span:hover {
        color: #333;
    }

    .pagination li.first a:hover svg, .pagination li.first span:hover svg {
        fill: #333;
    }
}

@media only screen and (max-width: 600px) {
    .pagination li.first a, .pagination li.first span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 0;
    }

    .pagination li.first a svg, .pagination li.first span svg {
        width: 10px;
        height: 10px;
    }
}

.pagination li.last {
    margin-left: 30px;
}

.pagination li.last a, .pagination li.last span {
    width: auto;
    height: 36px;
    font-size: 12px;
    color: #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.pagination li.last a svg, .pagination li.last span svg {
    width: 5px;
    height: 6px;
    fill: #333;
    margin: 2px 0 0 10px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .pagination li.last a:hover, .pagination li.last span:hover {
        color: #333;
    }

    .pagination li.last a:hover svg, .pagination li.last span:hover svg {
        fill: #333;
    }
}

@media only screen and (max-width: 600px) {
    .pagination li.last a, .pagination li.last span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 0;
    }

    .pagination li.last a svg, .pagination li.last span svg {
        width: 10px;
        height: 10px;
    }
}

.pagination li.dots {
    position: relative;
    width: 22px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;

    letter-spacing: -1px;
}

.pagination li.dots::before {
    content: '...';
    width: 16px;
    color: #333;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .pagination li.dots {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media only screen and (max-width: 600px) {
    .pagination li:nth-child(2) a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media only screen and (max-width: 600px) {
    .pagination li:nth-last-child(2) a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media only screen and (max-width: 600px) {
    .pagination li {
        margin: 0 2px;
    }
}

.product-quantity {
    max-width: 94px;
    width: 100%;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.product-quantity__button {
    background: #e6ebec;
    border: none;
    width: 22px;
    height: 100%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.product-quantity__input {
    width: 46px;
    height: 100%;
    font-size: 15px;

    color: #333;
    text-align: center;
    border: 1px solid #d7dee3;
}

.product-quantity__plus svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.checkbox-block {
    margin: 0 0 0 28px;
}

.checkbox-block input {
    display: none;
}

.checkbox-block input + label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 13px;
    color: #333;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.checkbox-block input + label::before {
    content: ' ';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -28px;
    width: 20px;
    height: 20px;
    display: block;
    background: #fff;
    border: 1px solid #c3ced5;
}

.checkbox-block input + label::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -28px;
    width: 20px;
    height: 20px;
    display: block;
    z-index: 1;
    /* webpackIgnore: true */ background-image: url("/images/check.svg");
    background-position: center;
    background-repeat: no-repeat;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
}

.checkbox-block input:checked + label::after {
    -webkit-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
    opacity: 1;
}

.checkbox-block input:disabled + label::before {
    background: #f6f9fa;
}

.main-subtitle {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.main-subtitle span {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 18px;
    text-align: center;

    color: #333;
    padding: 0 20px;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .main-subtitle span {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 100%;
        text-align: center;
    }
}

.main-subtitle::before, .main-subtitle::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #e1e6e9;
}

@media only screen and (max-width: 767px) {
    .main-subtitle::before, .main-subtitle::after {
        display: none;
    }
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.socials-login__box {
    text-align: center;
}

.socials-login__box .socials-login {
    margin: 0 auto;
}

.socials-login__box .socials-login__list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.password-icon {
    width: 20px;
    height: 20px;
    margin-top: -30px;
    float: right;
    z-index: 3;
    position: relative;
    margin-right: 25px;
    cursor: pointer;
}

.password-icon::before {
    content: '';
    display: block;
    background-image: url("./images/password-off.svg");
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.password-icon::after {
    content: '';
    display: block;
    background-image: url("./images/password-on.svg");
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    display: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.password-icon.active::before {
    display: none;
}

.password-icon.active::after {
    display: block;
}

input[name="password"] {
    padding-right: 55px;
}

.main-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}


/* Slider */
.slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
}


.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before, .slick-track:after {
    content: "";
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.product-colors__item img.lazyloaded, .product-colors__item img.lazyloading, .product-colors__item img.lazyload {
    max-height: 22px;
    height: 22px;
    width: 22px;
}

@media only screen and (max-width: 767px) {

    .product__image img.lazyloaded {
        max-height: -webkit-fill-available;
        margin: auto;
    }

    .product-colors__item img.lazyloaded, .product-colors__item img.lazyloading, .product-colors__item img.lazyload {
        max-height: 22px;
        height: 22px;
        width: 22px;
    }

    .product__image a {
        min-width: 90px;
        max-width: 85%;
    }

    .product__image .sale-counter {
        max-width: 65px;
    }
}

@media only screen and (max-width: 454px) {
    .product__image a {
        min-width: unset;
        max-width: unset;
    }
}

.slick-slide.slick-loading img {
    display: none;

}

.slick-slide.dragging img {
    pointer-events: none;

}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/* Slider */
.slick-loading .slick-list {
    /*background: #fff url("./images/ajax-loader.gif") center center no-repeat;*/
}

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    font-family: false;
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}

[dir="rtl"] .slick-prev {
    left: auto;
    right: -25px;
}

.slick-prev:before {
    content: "←";
}

[dir="rtl"] .slick-prev:before {
    content: "→";
}

.slick-next {
    right: -25px;
}

[dir="rtl"] .slick-next {
    left: -25px;
    right: auto;
}

.slick-next:before {
    content: "→";
}

[dir="rtl"] .slick-next:before {
    content: "←";
}

/* Dots */
/*.slick-dotted.slick-slider {
    margin-bottom: 30px;
}*/

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    padding: 5px;
    cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "•";
    width: 20px;
    height: 20px;
    font-family: false;
    font-size: 6px;
    line-height: 20px;
    text-align: center;
    color: black;
    opacity: 0.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    color: black;
    opacity: 0.75;
}

.slick-loading .slick-list {
    background: #fff;
}

/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS:
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited).
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar.
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars.
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars.
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/*
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
    -ms-touch-action: pinch-zoom;
    touch-action: pinch-zoom;
    /* direct pointer events to js */
}

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
    -ms-touch-action: auto;
    touch-action: auto;
}

.mCustomScrollBox, .mCustomScrollBox.mCS-light.mCSB_vertica.mCSB_inside {
    /* contains plugin's markup */
    position: relative;
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    outline: none;
    direction: ltr;
}

.product-colors__list > .mCustomScrollBox.mCS-light.mCSB_vertica.mCSB_inside {
    width: 320px;
}

.mCSB_container {
    /* contains the original content */
    overflow: hidden;
    width: auto;
    height: auto;
}

/*
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
    margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-right: 0;
}

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
    /* RTL direction/left-side scrollbar */
    margin-right: 0;
    margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-left: 0;
}

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
    /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
    position: absolute;
    width: 16px;
    height: auto;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
}

.mCSB_outside + .mCSB_scrollTools {
    right: -26px;
}

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
    /* RTL direction/left-side scrollbar */
    right: auto;
    left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
    left: -26px;
}

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
    /* contains the draggable element and dragger rail markup */
    position: absolute;
    top: 5px;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
    margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
    width: 10px;
    height: 100%;
    margin: 0 auto;
    border-radius: 0;
}

.mCSB_scrollTools .mCSB_dragger {
    /* the draggable element */
    cursor: pointer;
    width: 100%;
    height: 30px;
    /* minimum dragger height */
    z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    /* the dragger element */
    position: relative;
    width: 10px;
    height: 100%;
    margin: 0 auto;
    border-radius: 16px;
    text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    width: 12px;
    /* auto-expanded scrollbar */
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    width: 8px;
    /* auto-expanded scrollbar */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
    display: block;
    position: absolute;
    height: 20px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
    bottom: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
    margin-right: 0;
    margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container {
    min-height: 100%;
}

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
    margin-bottom: 0;
}

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    width: auto;
    height: 16px;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
}

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: -26px;
}

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
    margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 2px;
    margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 30px;
    /* minimum dragger width */
    height: 100%;
    left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 4px;
    margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    height: 12px;
    /* auto-expanded scrollbar */
    margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    height: 8px;
    /* auto-expanded scrollbar */
    margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
    left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
    right: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS
yx-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
    position: absolute;
    height: auto;
    width: auto;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-right: 30px;
    margin-bottom: 30px;
}

.mCSB_container_wrapper > .mCSB_container {
    padding-right: 30px;
    padding-bottom: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
    bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    right: 20px;
}

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
    bottom: 0;
}

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    right: 0;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    left: 20px;
}

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
    /* RTL direction/left-side scrollbar */
    margin-right: 0;
    margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
    padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
    padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-right: 0;
    /* non-visible scrollbar */
    margin-left: 0;
}

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
    margin-bottom: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
    -webkit-transition: opacity .5s ease-in-out, background-color .5s ease-in-out;
    transition: opacity .5s ease-in-out, background-color .5s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
    -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
}

/*
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
------------------------------------------------------------------------------------------------------------------------
*/
/*
	----------------------------------------
	6.1 THEMES
	----------------------------------------
	*/
/* default theme ("light") */
.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
    opacity: 0;
    filter: "alpha(opacity=0)";
    -ms-filter: "alpha(opacity=0)";
}

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
    opacity: 1;
    filter: "alpha(opacity=100)";
    -ms-filter: "alpha(opacity=100)";
}

.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: #e5eaed;
    filter: "alpha(opacity=40)";
    -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #fff;
    background-color: #a6aeb2;
    border-radius: 0;
    filter: "alpha(opacity=75)";
    -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: #a6aeb2;
    /*border-radius: 0;*/
    filter: "alpha(opacity=85)";
    -ms-filter: "alpha(opacity=85)";
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #fff;
    background-color: #a6aeb2;
    border-radius: 0;
    filter: "alpha(opacity=90)";
    -ms-filter: "alpha(opacity=90)";
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
    /* background-image: url(mCSB_buttons.png);*/
    /* css sprites */
    background-repeat: no-repeat;
    opacity: 0.4;
    filter: "alpha(opacity=40)";
    -ms-filter: "alpha(opacity=40)";
}

.mCSB_scrollTools .mCSB_buttonUp {
    background-position: 0 0;
    /*
		sprites locations
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/
}

.mCSB_scrollTools .mCSB_buttonDown {
    background-position: 0 -20px;
    /*
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/
}

.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: 0 -40px;
    /*
		sprites locations
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/
}

.mCSB_scrollTools .mCSB_buttonRight {
    background-position: 0 -56px;
    /*
		sprites locations
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
    opacity: 0.75;
    filter: "alpha(opacity=75)";
    -ms-filter: "alpha(opacity=75)";
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
    opacity: 0.9;
    filter: "alpha(opacity=90)";
    -ms-filter: "alpha(opacity=90)";
}

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
    width: 4px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 4px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 1px;
}

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 4px;
    margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px 0;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -20px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -40px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -56px;
}

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px 0;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -20px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -40px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
    width: 4px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 2px;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 4px;
    margin: 6px 0;
}

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 6px;
    margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.85);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.9);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -16px 0;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -16px -20px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -20px -40px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -20px -56px;
}

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -96px 0;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -96px -20px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -100px -40px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -100px -56px;
}

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
}

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 2px;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
}

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 2px;
    margin: 7px auto;
}

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -80px 0;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -80px -20px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -80px -40px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -80px -56px;
}

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.15);
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
    height: 14px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 14px;
    margin: 0 1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 14px;
}

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 14px;
    margin: 1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    width: 16px;
    /* auto-expanded scrollbar */
    height: 16px;
    margin: -1px 0;
}

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    width: 4px;
    /* auto-expanded scrollbar */
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    height: 16px;
    /* auto-expanded scrollbar */
    width: 16px;
    margin: 0 -1px;
}

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    height: 4px;
    /* auto-expanded scrollbar */
    margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
    background-position: 0 -72px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
    background-position: 0 -92px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: 0 -112px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
    background-position: 0 -128px;
}

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.9);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -80px -72px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -80px -92px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -80px -112px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -80px -128px;
}

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
    width: 4px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    background-color: transparent;
    background-position: center;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
    background-repeat: repeat-y;
    opacity: 0.3;
    filter: "alpha(opacity=30)";
    -ms-filter: "alpha(opacity=30)";
}

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    height: 4px;
    margin: 6px 0;
    background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -16px -72px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -16px -92px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -20px -112px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -20px -128px;
}

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -96px -72px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -96px -92px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -100px -112px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -100px -128px;
}

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-y;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-x;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
    height: 70px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 70px;
}

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
    opacity: 1;
    filter: "alpha(opacity=30)";
    -ms-filter: "alpha(opacity=30)";
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    border-radius: 16px;
}

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
    width: 8px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #555;
}

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 8px;
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 8px;
    margin: 4px 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 8px;
    margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px;
}

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
    opacity: 1;
    filter: "alpha(opacity=30)";
    -ms-filter: "alpha(opacity=30)";
}

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
    border-radius: 7px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    border-radius: 5px;
}

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
    right: 1px;
}

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
    -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
    bottom: 1px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    width: 12px;
    margin: 2px;
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #555;
}

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 12px;
    width: auto;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px;
}

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
    -webkit-box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #777;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
    background-color: #fff;
    background-color: rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
    right: 0;
    margin: 12px 0;
}

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: 0;
    margin: 0 12px;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
    left: 0;
    right: auto;
}

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
}

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
    height: 50px;
}

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 50px;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
    filter: "alpha(opacity=20)";
    -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.5);
    filter: "alpha(opacity=50)";
    -ms-filter: "alpha(opacity=50)";
}

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.2);
    filter: "alpha(opacity=20)";
    -ms-filter: "alpha(opacity=20)";
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
    filter: "alpha(opacity=50)";
    -ms-filter: "alpha(opacity=50)";
}

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
    width: 6px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.2);
}

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
}

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 6px;
    margin: 5px 0;
}

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    width: 12px;
}

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    height: 12px;
    margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px;
}

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.1);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px;
}

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
    width: 12px;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.2);
}

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
    margin: 3px 5px;
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 6px;
    margin: 5px 3px;
    position: absolute;
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 12px;
    margin: 2px 0;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px;
}

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px;
}

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.1);
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px;
}

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px;
}

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-color: rgba(255, 255, 255, 0.2);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
    border-color: #000;
    border-color: rgba(0, 0, 0, 0.2);
}

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.6);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.6);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.75);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.85);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.9);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.75);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.85);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.9);
}

/* ---------------------------------------- */
body.compensate-for-scrollbar {
    overflow: hidden;
}

.fancybox-active {
    height: auto;
}

.fancybox-is-hidden {
    left: -9999px;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    visibility: hidden;
}

.fancybox-container {
    -webkit-backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: none;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%;
    z-index: 99992;
}

.fancybox-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.fancybox-bg {
    background: #1e1e1e;
    opacity: 0;
    -webkit-transition-duration: inherit;
    transition-duration: inherit;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
    transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
    opacity: .9;
    -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
    direction: ltr;
    opacity: 0;
    position: absolute;
    -webkit-transition: opacity .25s ease, visibility 0s ease .25s;
    transition: opacity .25s ease, visibility 0s ease .25s;
    visibility: hidden;
    z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
    opacity: 1;
    -webkit-transition: opacity .25s ease 0s, visibility 0s ease 0s;
    transition: opacity .25s ease 0s, visibility 0s ease 0s;
    visibility: visible;
}

.fancybox-infobar {
    color: #ccc;
    font-size: 13px;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 44px;
    left: 0;
    line-height: 44px;
    min-width: 44px;
    mix-blend-mode: difference;
    padding: 0 10px;
    pointer-events: none;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fancybox-toolbar {
    right: 0;
    top: 0;
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden;
}

.fancybox-slide {
    -webkit-backface-visibility: hidden;
    /* Using without prefix would break IE11 */
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: absolute;
    text-align: center;
    top: 0;
    transition-property: opacity, -webkit-transform;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    white-space: normal;
    width: 100%;
    z-index: 99994;
}

.fancybox-slide::before {
    content: '';
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
    display: block;
}

.fancybox-slide--image {
    overflow: hidden;
    padding: 44px 0;
}

.fancybox-slide--image::before {
    display: none;
}

.fancybox-slide--html {
    padding: 6px;
}

.fancybox-content {
    background: #fff;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 44px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
    animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
    -webkit-backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    transition-property: opacity, -webkit-transform;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
    cursor: -webkit-grab;
    cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
    cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.fancybox-spaceball {
    z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
    height: 100%;
    overflow: visible;
    padding: 0;
    width: 100%;
}

.fancybox-slide--video .fancybox-content {
    background: #000;
}

.fancybox-slide--map .fancybox-content {
    background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
    background: #fff;
}

.fancybox-video,
.fancybox-iframe {
    background: transparent;
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
    left: 0;
    position: absolute;
    top: 0;
}

.fancybox-error {
    background: #fff;
    cursor: default;
    max-width: 400px;
    padding: 40px;
    width: 100%;
}

.fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

/* Buttons */
.fancybox-button {
    background: rgba(30, 30, 30, 0.6);
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    margin: 0;
    padding: 10px;
    position: relative;
    -webkit-transition: color .2s;
    transition: color .2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
    color: #ccc;
}

.fancybox-button:hover {
    color: #fff;
}

.fancybox-button:focus {
    outline: none;
}

.fancybox-button.fancybox-focus {
    outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
    outline: none;
}

/* Fix IE11 */
.fancybox-button div {
    height: 100%;
}

.fancybox-button svg {
    display: block;
    height: 100%;
    overflow: visible;
    position: relative;
    width: 100%;
}

.fancybox-button svg path {
    fill: currentColor;
    stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
    display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
    display: none;
}

.fancybox-progress {
    background: #ff5268;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0;
    transform-origin: 0;
    transition-property: -webkit-transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #ccc;
    cursor: pointer;
    opacity: .8;
    padding: 8px;
    position: absolute;
    right: -12px;
    top: -44px;
    z-index: 401;
}

.fancybox-close-small:hover {
    color: #fff;
    opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
    color: currentColor;
    padding: 10px;
    right: 0;
    top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
    overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
    display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
    background-clip: content-box;
    height: 100px;
    opacity: 0;
    position: absolute;
    top: calc(50% - 50px);
    width: 70px;
}

.fancybox-navigation .fancybox-button div {
    padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
    padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
    padding: 31px 6px 31px 26px;
    right: 0;
}

/* Caption */
.fancybox-caption {
    bottom: 0;
    color: #eee;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    line-height: 1.5;
    padding: 25px 44px 25px 44px;
    right: 0;
    text-align: center;
    z-index: 99996;
}

.fancybox-caption::before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAD6CAQAAADKSeXYAAAAYklEQVQoz42RwQ3AMAgDjfcfup8WoRykfBAK5mQHKSz5rbXJPis1hjiV3CIqgG0hLZPkVkA4p4x5oR1bVeDrdCLrW2Q0D5bcwY3TGMHbdw3mPRuOtaspYP1w//G1OIcW148H0DMCqI/3mMMAAAAASUVORK5CYII=);
    background-repeat: repeat-x;
    background-size: contain;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -44px;
    z-index: -1;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
    color: #ccc;
    text-decoration: none;
}

.fancybox-caption a:hover {
    color: #fff;
    text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
    -webkit-animation: fancybox-rotate 1s linear infinite;
    animation: fancybox-rotate 1s linear infinite;
    background: transparent;
    border: 4px solid #888;
    border-bottom-color: #fff;
    border-radius: 50%;
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    opacity: .7;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 50px;
    z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fancybox-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Transition effects */
.fancybox-animated {
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
    opacity: 0;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
    opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
    opacity: 0;
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
    -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
    transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
    -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
    transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
    .fancybox-caption {
        padding: 12px;
    }

    .fancybox-slide {
        padding-left: 6px;
        padding-right: 6px;
    }

    .fancybox-slide--image {
        padding: 6px 0;
    }

    .fancybox-close-small {
        right: -6px;
    }

    .fancybox-slide--image .fancybox-close-small {
        background: #4e4e4e;
        color: #f2f4f6;
        height: 36px;
        opacity: 1;
        padding: 6px;
        right: 0;
        top: 0;
        width: 36px;
    }
}

/* Share */
.fancybox-share {
    background: #f4f4f4;
    border-radius: 3px;
    max-width: 90%;
    padding: 30px;
    text-align: center;
}

.fancybox-share h1 {
    color: #222;
    font-size: 35px;
    font-weight: normal;
    margin: 0 0 20px 0;
}

.fancybox-share p {
    margin: 0;
    padding: 0;
}

.fancybox-share__button {
    border: 0;
    border-radius: 3px;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 40px;
    margin: 0 5px 10px 5px;
    min-width: 130px;
    padding: 0 15px;
    text-decoration: none;
    -webkit-transition: all .2s;
    transition: all .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
    color: #fff;
}

.fancybox-share__button:hover {
    text-decoration: none;
}

.fancybox-share__button--fb {
    background: #3b5998;
}

.fancybox-share__button--fb:hover {
    background: #344e86;
}

.fancybox-share__button--pt {
    background: #bd081d;
}

.fancybox-share__button--pt:hover {
    background: #aa0719;
}

.fancybox-share__button--tw {
    background: #1da1f2;
}

.fancybox-share__button--tw:hover {
    background: #0d95e8;
}

.fancybox-share__button svg {
    height: 25px;
    margin-right: 7px;
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 25px;
}

.fancybox-share__button svg path {
    fill: #fff;
}

.fancybox-share__input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    color: #5d5b5b;
    font-size: 14px;
    margin: 10px 0 0 0;
    outline: none;
    padding: 10px 15px;
    width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
    background: #ddd;
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 2px 2px 4px 2px;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    width: 212px;
    z-index: 99995;
}

.fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
    display: block;
}

.fancybox-show-thumbs .fancybox-inner {
    right: 212px;
}

.fancybox-thumbs__list {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
    overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
    width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    border-radius: 10px;
}

.fancybox-thumbs__list a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    float: left;
    height: 75px;
    margin: 2px;
    max-height: calc(100% - 8px);
    max-width: calc(50% - 4px);
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 100px;
}

.fancybox-thumbs__list a::before {
    border: 6px solid #ff5268;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
    opacity: .5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
    opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
    .fancybox-thumbs {
        width: 110px;
    }

    .fancybox-show-thumbs .fancybox-inner {
        right: 110px;
    }

    .fancybox-thumbs__list a {
        max-width: calc(100% - 10px);
    }
}

/*! nouislider - 11.0.3 - 2018-01-21 14:04:07 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-target {
    position: relative;
    direction: ltr;
}

.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
    overflow: hidden;
    z-index: 0;
}

.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

/* Offset direction
 */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
    left: auto;
    right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
    width: 0;
}

.noUi-horizontal .noUi-origin {
    height: 0;
}

.noUi-handle {
    position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
    height: 18px;
}

.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    left: -17px;
    top: -6px;
}

.noUi-vertical {
    width: 18px;
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -17px;
    left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
    background: #FAFAFA;
    border-radius: 4px;
    border: 1px solid #D3D3D3;
    -webkit-box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
    box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connects {
    border-radius: 3px;
}

.noUi-connect {
    background: #3FB8AF;
}

/* Handles and cursors;
 */
.noUi-draggable {
    cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}

.noUi-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    -webkit-box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}

.noUi-active {
    -webkit-box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #E8E7E6;
    left: 14px;
    top: 6px;
}

.noUi-handle:after {
    left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}

.noUi-vertical .noUi-handle:after {
    top: 17px;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
    background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
    cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-pips {
    position: absolute;
    color: #999;
}

/* Values;
 *
 */
.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
    position: absolute;
    background: #CCC;
}

.noUi-marker-sub {
    background: #AAA;
}

.noUi-marker-large {
    background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%, 0);
    padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}

.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}

.about-block {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1249px) {
    .about-block {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 1023px) {
    .about-block {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        margin: 0 auto 40px;
    }
}

@media only screen and (max-width: 480px) {
    .about-block {
        padding: 0 10px;
        margin: 0 auto 25px;
    }
}

.about-block__content {
    max-width: 500px;
    width: 100%;
    -ms-flex-preferred-size: 500px;
    flex-basis: 500px;
    margin-right: 35px;
}

@media only screen and (max-width: 1023px) {
    .about-block__content {
        max-width: 760px;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto;
    }
}

.about-block__video {
    max-width: 638px;
    width: 100%;
    -ms-flex-preferred-size: 638px;
    flex-basis: 638px;
}

.about-block__video iframe {
    border: none;
    max-width: 100%;
    width: 100%;
    height: 382px;
}

@media only screen and (max-width: 1023px) {
    .about-block__video {
        max-width: 760px;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto 25px;
    }
}

@media only screen and (max-width: 480px) {
    .about-block__video iframe {
        height: 214px;
    }
}

.about-block__title {
    font-size: 18px;

    text-transform: uppercase;
    color: #333;
    margin-bottom: 16px;
}

@media only screen and (max-width: 480px) {
    .about-block__title {
        font-size: 16px;
    }

    .payment-checkbox-wrap {
        max-width: 300px;
    }

    .head-title--thin {
        font-size: 14px;
        line-height: 16px;
    }

    .head-block__subtitle {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 18px;
    }

    .order-block {
        padding-top: 15px;
    }
}

.about-block__text {
    font-size: 14px;
    font-family: 'Open Sans', serif;
    color: #333;
    line-height: 1.7;
    max-height: 340px;
    overflow-y: auto;
}

@media only screen and (max-width: 480px) {
    .about-block__text {
        max-height: 100%;
        overflow: visible;
    }
}

.about-block__text ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.about-history {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 120px;
    padding-top: 60px;
    border-top: 1px solid #e1e6e9;
}

@media only screen and (max-width: 1249px) {
    .about-history {
        padding: 60px 20px 0;
    }
}

@media only screen and (max-width: 1023px) {
    .about-history {
        padding: 35px 20px 0;
        margin: 0 auto 70px;
    }
}

@media only screen and (max-width: 767px) {
    .about-history {
        padding: 25px 10px 0;
        margin: 0 auto 40px;
    }
}

.about-history__title {
    font-size: 18px;

    text-transform: uppercase;
    color: #333;
    text-align: center;
    margin-bottom: 15px;
}

@media only screen and (max-width: 480px) {
    .about-history__title {
        font-size: 16px;
    }
}

.about-history__text {
    max-width: 790px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-family: 'Open Sans', serif;
    font-size: 14px;
    color: #333;
    line-height: 1.7;
    margin-bottom: 30px;
}

.about-history__nav-slider {
    max-width: 1225px;
    width: 100%;
    margin: 0 auto 45px;
    list-style: none;
    padding: 0;
    border: 1px solid #e1e6e9;
}

.about-history__nav-slide {
    cursor: pointer;
    position: relative;
    font-size: 14px;
    color: #7b878f;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex !important;
    text-transform: uppercase;
}

.about-history__nav-slide::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 10px;
    width: 1px;
    background: #cecece;
}

.about-history__nav-slide.slick-current.slick-active {
    border-top: 2px solid #b0bfc8;
    border-bottom: 2px solid #b0bfc8;
    font-size: 18px;
    color: #545e65;

}

.about-history__main-slider {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
}

.about-history__main-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
    .about-history__main-slide {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.about-history__main-images {
    max-width: 585px;
    width: 100%;
    -ms-flex-preferred-size: 585px;
    flex-basis: 585px;
    margin-right: 35px;
    position: relative;
}

@media only screen and (max-width: 1023px) {
    .about-history__main-images {
        max-width: 645px;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto 30px;
    }
}

.about-history__main-content {
    max-width: 555px;
    width: 100%;
    -ms-flex-preferred-size: 555px;
    flex-basis: 555px;
}

@media only screen and (max-width: 1023px) {
    .about-history__main-content {
        max-width: 645px;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto;
    }
}

.about-history__main-title {
    font-size: 18px;

    text-transform: uppercase;
    color: #333;
    margin-bottom: 10px;
}

.about-history__main-text {
    max-width: 530px;
    width: 100%;
    max-height: 330px;
    overflow-y: auto;
    font-size: 14px;
    line-height: 1.7;
    font-family: 'Open Sans', serif;
    color: #333;
}

@media only screen and (max-width: 1023px) {
    .about-history__main-text {
        max-width: 100%;
    }
}

.about-history__navigation {
    max-width: 170px;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: -1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.about-history__counter {
    background: rgba(23, 27, 29, 0.7);
    max-width: 85px;
    width: 100%;
    height: 42px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.about-history__counter .slide-current {
    font-size: 26px;

    color: #fff;
}

.about-history__counter .slide-all {
    font-size: 16px;

    color: #cdc6c6;
}

.about-history__arrows {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.about-history__arrows .nav-arrow {
    cursor: pointer;
    width: 42px;
    height: 42px;
    border: 1px solid #c3ced5;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.about-history__arrows .nav-arrow svg {
    width: 6px;
    height: 16px;
    fill: #a2acb3;
}

.about-history__arrows .nav-arrow.slick-disabled {
    background: rgba(176, 191, 200, 0.7);
}

.about-history__arrows .nav-arrow.slick-disabled svg {
    fill: #fff;
}

.about-history__arrows .about-history__prev {
    border-right: none;
}

.account {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto 140px;
}

.account-sidebar {
    width: 280px;
    margin-right: 50px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.account-sidebar__inner {
    background: #fff;
    -webkit-box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
}

.account-sidebar-info {
    padding: 15px 30px;
    background: #f7f9f9;
    border: 1px solid #dfe4e7;
}

@media only screen and (max-width: 900px) {
    .account-sidebar-info {
        text-align: center;
    }
}

.account-sidebar-line {
    font-size: 13px;
    color: #8b979e;
    margin-bottom: 9px;
}

.account-sidebar-line span {
    font-weight: normal;
    color: #768187;
    margin-left: 7px;
}

.account-sidebar__heading {
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    padding: 15px;
    background: #89959a;
    display: none;
}

.account-sidebar__link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.account-sidebar__link svg {
    width: 20px;
    height: 20px;
    fill: #727b81;
    margin-right: 9px;
}

.account-sidebar__link span {
    font-size: 13px;
    color: #768187;
    border-bottom: 1px dotted #768187;
}

@media only screen and (max-width: 900px) {
    .account-sidebar {
        width: 100%;
        margin: 0 0 40px;
    }

    .account-sidebar .account-sidebar__heading {
        display: block;
    }

    .account-sidebar .account-sidebar-holder {
        display: none;
    }
}

.account-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 40px 30px 30px;
}

.account-info__thumb {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #f1f3f4;
    position: relative;
    margin-bottom: 25px;
}

.account-info__thumb img {
    border-radius: 50%;
}

.account-info__thumb svg {
    width: 15px;
    height: 15px;
    fill: #ec4a9b;
}

.account-info__thumb-handler {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    bottom: -15px;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.account-info__name {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #333;
}

.account-nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.account-nav a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px 24px;
    font-size: 14px;
    text-transform: uppercase;
    color: #333;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    border-top: 1px solid #e1e6e9;
    position: relative;
}

.account-nav a::after {
    content: '';
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 35px;
    border-top: 2px solid #9baab3;
}

.account-nav a svg {
    width: 19px;
    height: 19px;
    fill: #97a6b1;
    margin-right: 15px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.account-nav a.current {
    font-weight: normal;
    color: #333;
}

.account-nav a.current::after {
    width: 42px;
}

.account-nav a.current svg {
    fill: #546876;
}

@media only screen and (min-width: 1025px) {
    .account-nav a:hover {
        color: #000;
    }

    .account-nav a:hover svg {
        fill: #546876;
    }
}

@media only screen and (max-width: 900px) {
    .account-nav a {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-weight: normal;
    }

    .account-nav a::after {
        display: none;
    }

    .account-nav a span {
        min-width: 140px;
    }

    .account-nav a.current {
        background: #f7f9f9;
    }
}

.account-title {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #333;
}

.account-inner {
    width: 100%;
    min-width: 0;
}

.account-data .adress-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.account-data .adress-block > * {
    width: 48%;
}

.account-data .main-input {
    height: 44px;
    font-size: 14px;

    color: #333;
}

.account-data .main-input::-webkit-input-placeholder {
    font-weight: 400;
}

.account-data .main-input::-moz-placeholder {
    font-weight: 400;
}

.account-data .main-input::-ms-input-placeholder {
    font-weight: 400;
}

.account-data .main-input::placeholder {
    font-weight: 400;
}

.account-data .submit-btn {
    font-family: 'Open Sans', serif;
    width: 215px;
    height: 44px;
}

@media only screen and (max-width: 580px) {
    .account-data .submit-btn {
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .account {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
}

.form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.form-column {
    max-width: calc(50% - 20px);
    width: 100%;
    -ms-flex-preferred-size: calc(50% - 20px);
    flex-basis: calc(50% - 20px);
    margin: 0 10px;
    color: #333;
}

.form-column .form-block {
    margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
    .form-column {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.form-label {
    margin-bottom: 7px;
    font-size: 13px;
    color: #333;
}


.birth-date {
    margin-bottom: 40px;
}

.birth-date__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.birth-date__inner .select {
    width: 70px;
    margin: 0 10px 0 0;
    padding: 13px 20px;
    color: #333;
    font-size: 13px;
    font-weight: normal;
    border-color: #c3ced5;
}

.birth-date__inner .select::after {
    border: none;
    -webkit-transform: none;
    transform: none;
    background: url("./images/opener.svg");
    background-size: 7px 7px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background-position: center;
}

.birth-date__inner .select--open::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.birth-date__inner .select .select__list {
    border-radius: 0;
    -webkit-box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.12);
    box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.12);
    max-height: 300px;
    overflow-y: auto;
    margin-top: 100%;
}

.birth-date__inner .select .select__list .select__item {
    padding: 7px 15px;
}

.birth-date__inner .birth-date-year {
    width: 95px;
}

@media only screen and (max-width: 767px) {
    .pass-change .account-title {
        text-align: center;
    }
}

.account-title.center {
    text-align: center;
}

.account-subscribes__item {
    margin-bottom: 25px;
    max-width: 380px;
}

.account-subscribes__item + .account-subscribes__item {
    padding-top: 25px;
    border-top: 1px solid #e1e6e9;
}

.account-subscribes__label {
    font-size: 13px;
    color: #8b979e;
    margin-bottom: 20px;
}

.account-subscribes__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.account-subscribes__row .checkbox-block {
    margin-right: 25px;
}

.account-subscribes__row .checkbox-block input + label {
    font-weight: normal;
}

.account-subscribes__row .checkbox-block input:checked + label {
    color: #333;
}

.account-subscribes .submit-btn {
    margin-top: 60px;
    height: 46px;
    font-family: 'Open Sans', serif;
}

@media only screen and (max-width: 767px) {
    .account-subscribes .submit-btn {
        width: 100%;
        max-width: 100%;
    }
}

.account-documents {
    width: 860px;
    min-width: 0;
}

@media only screen and (max-width: 992px) {
    .account-documents {
        max-width: 730px;
    }
}

.account-documents__wrap {
    overflow-x: auto;
}

.account-documents__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.account-documents__column {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.account-documents__column:nth-child(2) {
    width: 280px;
    text-align: left;
}

@media only screen and (max-width: 992px) {
    .account-documents__column:nth-child(2) {
        max-width: 260px;
    }
}

@media only screen and (max-width: 767px) {
    .account-documents__column:nth-child(2) {
        width: 220px;
    }
}

.account-documents__column:nth-child(3) {
    width: 220px;
}

@media only screen and (max-width: 992px) {
    .account-documents__column:nth-child(3) {
        width: 200px;
    }
}

.account-documents__column:nth-child(4) {
    width: 120px;
}

@media only screen and (max-width: 992px) {
    .account-documents__column:nth-child(4) {
        width: 100px;
    }
}

.account-documents__column:nth-child(5) {
    width: 200px;
}

@media only screen and (max-width: 992px) {
    .account-documents__column:nth-child(5) {
        width: 140px;
    }
}

.account-documents__heading {
    text-align: center;
    font-size: 13px;
    color: #8b979e;
    padding-bottom: 14px;
    padding-left: 40px;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 26px;
}

.account-documents__heading .account-documents__column:nth-child(1) {
    width: 280px;
    text-align: left;
}

@media only screen and (max-width: 992px) {
    .account-documents__heading .account-documents__column:nth-child(1) {
        max-width: 260px;
    }
}

@media only screen and (max-width: 767px) {
    .account-documents__heading .account-documents__column:nth-child(1) {
        width: 220px;
    }
}

.account-documents__heading .account-documents__column:nth-child(2) {
    width: 220px;
    text-align: center;
}

@media only screen and (max-width: 992px) {
    .account-documents__heading .account-documents__column:nth-child(2) {
        width: 200px;
    }
}

.account-documents__heading .account-documents__column:nth-child(3) {
    width: 120px;
    text-align: center;
}

@media only screen and (max-width: 992px) {
    .account-documents__heading .account-documents__column:nth-child(3) {
        width: 100px;
    }
}

.account-documents__heading .account-documents__column:nth-child(4) {
    width: 200px;
    text-align: center;
}

@media only screen and (max-width: 992px) {
    .account-documents__heading .account-documents__column:nth-child(4) {
        width: 140px;
    }
}

.account-documents__item {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    color: #768187;
    padding: 0 0 26px 40px;
    border-bottom: 1px solid #e7e7e7;
    position: relative;
    margin-bottom: 26px;
}

.account-documents__thumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #333;
    text-decoration: none;
    text-align: left;
}

.account-documents__thumb img {
    margin-right: 20px;
}

.account-documents__remove {
    position: absolute;
    top: 40%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
}

.account-documents__remove svg {
    width: 9px;
    height: 9px;
    fill: #778288;
}

.account-documents__add {
    position: relative;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #768187;
    margin-bottom: 55px;
}

.account-documents__add input {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
}

.account-documents__add svg {
    width: 20px;
    height: 20px;
    fill: #768187;
    margin-right: 10px;
}

.account-documents__add-label {
    font-size: 13px;
    border-bottom: 1px dotted #768187;
}

.account__orders {
    margin-bottom: 80px;
}

@media only screen and (max-width: 1100px) {
    .account__orders {
        overflow-x: scroll;
    }
}

.account__orders-param {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #333;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 34px;
}

.account__orders-param .product-price {
    margin-left: 10px;
    font-weight: normal;
    color: #333;
}

.account__orders-param .product-price sup {
    top: 0;
}

.account__orders-repeat {
    font-family: 'Open Sans', serif;
    min-width: 220px;
}

.account__orders-item {
    margin-bottom: 20px;
    position: relative;
    border: 1px solid #e1e6e9;
}

.account__orders-item > .account__orders-grid {
    cursor: pointer;
}

.account__orders-item .account__orders-grid {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.account__orders-item .account__orders-grid-cell {
    font-size: 14px;
    font-weight: normal;
    color: #333;
    position: relative;
}

.account__orders-item .account__orders-grid-cell::after {
    position: absolute;
    content: '';
    right: 0;
    top: 50%;
    height: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-right: 1px solid #cecece;
}

.account__orders-item-opener {
    position: absolute;
    top: 0;
    right: 0;
    height: 44px;
    width: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #e6ebec;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    pointer-events: none;
}

.account__orders-item-opener svg {
    width: 6px;
    height: 6px;
    fill: #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.account__orders-item-total {
    margin: 30px 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.account__orders-item-total .total-item {
    font-size: 13px;
    color: #333;
    margin-bottom: 10px;
}

.account__orders-item-total .total-item__heading {
    font-size: 14px;
    font-weight: normal;
    color: #333;
}

.account__orders-item.active .account__orders-content {
    height: auto;
}

.account__orders-item.active > .account__orders-grid {
    background: #eff4f6;
}

.account__orders-item.active .account__orders-item-opener {
    background: #b1bcc1;
}

.account__orders-item.active .account__orders-item-opener svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    fill: #fff;
}

@media only screen and (max-width: 1100px) {
    .account__orders-item {
        width: 880px;
    }
}

.account__orders-content {
    padding: 0 35px;
    height: 0;
    overflow: hidden;
}

.account__orders-content .account__orders-grid {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.5);
}

.account__orders-content .account__orders-grid-cell::after {
    display: none;
}

.account__orders-content .btn-simple {
    font-weight: 400;
    font-style: italic;
    padding: 10px 20px;
    margin-bottom: 15px;
}

.account__orders-content .comment-holder {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.account__orders-content .checkout-basket__item {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 35px 90px 25px 0;
    border-bottom: 1px solid #e1e6e9;
}

.account__orders-content .checkout-basket__image {
    max-width: none;
    margin-right: 30px;
}

.account__orders-content .checkout-basket__content {
    min-width: 195px;
    margin-right: 60px;
}

.account__orders-content .checkout-basket__content .checkout-basket__price-current {
    color: #333;
}

.account__orders-content .checkout-basket__price-box {
    margin-left: 0;
}

.account__orders-content .basket-popup__title {
    margin-bottom: 5px;
}

.account__orders-content .product-page__article {
    margin: 0 0 15px;
}

.account__orders-content .basket-popup__quantity {
    pointer-events: none;
}

.account__orders-content .basket-popup__quantity svg {
    opacity: .6;
}

.account__orders-content .product-page__article {
    padding: 0;
    border: none;
}

.account__orders-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.account__orders-grid-cell {
    width: 150px;
    padding: 14px;
    text-align: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: #333;
    font-size: 13px;
}

.account__orders-grid-cell .product-title {
    text-align: left;
    margin-bottom: 5px;
    color: #333;
}

.account__orders-grid-cell .articul {
    margin-bottom: 10px;
}

.account__orders-grid-cell .product-item__price {
    text-align: left;
}

.account__orders-grid-cell:last-child::after {
    display: none;
}

.acardion-block {
    border: 1px solid #e1e6e9;
}

.acardion-block.active .acardion-block__title {
    background: #eff4f6;
}

.acardion-block.active .acardion-block__title span {
    background: #b1bcc1;
}

.acardion-block.active .acardion-block__title span svg {
    fill: #fff;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.acardion-block__title {
    cursor: pointer;
    width: 100%;
    height: 44px;
    font-family: 'Open Sans', serif;
    font-size: 14px;
    color: #768187;
    padding-left: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.acardion-block__title span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #e1e6e9;
    width: 50px;
    height: 100%;
    margin-left: auto;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.acardion-block__title span svg {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    fill: #868787;
    width: 8px;
    height: 7px;
    -webkit-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
}

.acardion-block__content {
    padding: 35px 50px 35px 35px;
    font-size: 14px;
    color: #768187;
}

.acardion-block__inner {
    height: 0;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.brends {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 135px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}

.brends.holder::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

@media only screen and (max-width: 1599px) {
    .brends {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .brends {
        padding: 0 10px;
    }
}

.brends__sidebar {
    max-width: 280px;
    width: 100%;
    -ms-flex-preferred-size: 280px;
    flex-basis: 280px;
    margin-right: 15px;
}

@media only screen and (max-width: 1023px) {
    .brends__sidebar {
        position: absolute;
        overflow-x: auto;
        left: 0;
        top: 0;
        background: #fff;
        min-height: 100vh;
        height: 100%;
        z-index: 2;
        margin: 0;
        max-width: 320px;
        padding: 15px 20px;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }

    .brends__sidebar.open {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.brends__main {
    max-width: 1180px;
    width: 100%;
    -ms-flex-preferred-size: 1180px;
    flex-basis: 1180px;
}

@media only screen and (max-width: 1023px) {
    .brends__main {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

@media only screen and (max-width: 767px) {
    .brends__main {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.brends__item-title {
    text-transform: uppercase;
    font-size: 22px;

    color: #333;
    margin-bottom: 22px;
}

.brends__item-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.brends__item-item {
    margin-bottom: 15px;
    font-size: 14px;
}

.brends__item-item a {
    color: #333;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .brends__item-item:hover a {
        color: #ec4a9b;
    }
}

.brends__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1023px) {
    .brends__list {
        max-width: 480px;
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .brends__list {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

.brends__item {
    width: 25%;
    margin-bottom: 35px;
}

@media only screen and (max-width: 1023px) {
    .brends__item {
        width: 33.33333%;
    }
}

@media only screen and (max-width: 767px) {
    .brends__item {
        max-width: 115px;
        width: 100%;
        -ms-flex-preferred-size: 115px;
        flex-basis: 115px;
        margin: 0 15px 35px;
        /* max-height: 300px;
         overflow-y: scroll;*/
    }
}

.brends-nav__title {
    font-size: 14px;

    text-transform: uppercase;
    color: #333;
    border-bottom: 2px solid #b0bfc8;
    padding-bottom: 12px;
    margin-bottom: 22px;
    padding-left: 6px;
}

@media only screen and (max-width: 1023px) {
    .brends-nav__title {
        border-top: 1px solid #b0bfc8;
        border-bottom: none;
        padding-top: 25px;
        padding-bottom: 0;
    }
}

.brends-nav__list {
    margin: 0;
    padding: 0 35px 0 6px;
    list-style: none;
}

.brends-nav__item {
    margin-bottom: 15px;
}

.brends-nav__item a {
    font-size: 14px;
    color: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.brends-nav__item a small {
    color: #333;
    font-size: 12px;
    margin: 2px 0 0 30px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.brends-nav__item.active a {
    color: #333;

}

.brends-nav__item.active a small {
    color: #333;

}

@media only screen and (min-width: 1025px) {
    .brends-nav__item:hover a {
        color: #ec4a9b;
    }

    .brends-nav__item:hover a small {
        color: #ec4a9b;
    }
}

.brends-nav__close {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .brends-nav__close {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: #e6ebec;
        margin-bottom: 20px;
        width: 100%;
        height: 40px;
        font-size: 14px;
        color: #333;
    }
}

.brends-filter {
    background: #f5f7f7;
    padding: 22px 19px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 42px;
}

@media only screen and (max-width: 1023px) {
    .brends-filter {
        max-width: 224px;
        width: 100%;
        -ms-flex-preferred-size: 224px;
        flex-basis: 224px;
        padding-bottom: 0;
        margin: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media only screen and (max-width: 767px) {
    .brends-filter {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.brends-filter__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.brends-filter__item {
    position: relative;
    margin: 0 6px;
    font-size: 14px;
    text-transform: uppercase;
}

.brends-filter__item::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background: #b0bfc8;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -10px;
    display: none;
}

.brends-filter__item::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background: #b0bfc8;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -10px;
    display: none;
}

.brends-filter__item a {
    color: #333;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.brends-filter__item.active a {

    color: #333;
}

.brends-filter__item.active::before, .brends-filter__item.active::after {
    display: block;
}

.brends-filter__item--all a {

    color: #333;
    text-decoration: none;
}

@media only screen and (max-width: 1023px) {
    .brends-filter__item--all {
        width: 100%;
    }
}

@media only screen and (min-width: 1025px) {
    .brends-filter__item:hover a {
        color: #ec4a9b;
    }
}

@media only screen and (max-width: 1449px) {
    .brends-filter__item {
        margin: 0 4px;
    }
}

@media only screen and (max-width: 1249px) {
    .brends-filter__item {
        font-size: 12px;
        margin: 0 3px;
    }
}

@media only screen and (max-width: 1023px) {
    .brends-filter__item {
        font-size: 14px;
        margin: 0 3px 20px;
    }
}

.brends-filter__inner {
    margin-right: 25px;
}

.brends-filter__inner .submit-btn {
    display: none;
    margin-bottom: 30px;
}

@media only screen and (max-width: 1023px) {
    .brends-filter__inner .submit-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media only screen and (max-width: 767px) {
    .brends-filter__inner .submit-btn {
        max-width: 420px;
    }
}

@media only screen and (max-width: 767px) {
    .brends-filter__inner {
        margin: 0 auto 25px;
    }
}

.categories-block {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
}

@media only screen and (max-width: 1599px) {
    .categories-block {
        padding: 0 15px;
    }
}

.categories-block__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -10px 80px;
}

.categories-block__list.large-items .categories-block__item {
    max-width: 48%;
    width: 100%;
    -ms-flex-preferred-size: 48%;
    flex-basis: 48%;
}

@media only screen and (max-width: 1249px) {
    .categories-block__list.large-items .categories-block__item {
        max-width: 48.5%;
        -ms-flex-preferred-size: 48.5%;
        flex-basis: 48.5%;
    }
}

@media only screen and (max-width: 767px) {
    .categories-block__list.large-items .categories-block__item {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.categories-block__list.confused .categories-block__item:nth-child(1), .categories-block__list.confused .categories-block__item:nth-child(2) {
    max-width: calc(50% - 20px);
    width: 100%;
    -ms-flex-preferred-size: calc(50% - 20px);
    flex-basis: calc(50% - 20px);
}

@media (max-width: 1024px) {
    .categories-block {
        padding: unset;
    }

    .categories-block__list,
    .categories-block__list.confused {
        flex: 1 1;
        display: flex;
        position: relative;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        margin: 30px -10px 30px 0;
        gap: 10px;
    }

    .categories-block__list.confused .categories-block__item,
    .categories-block__list .categories-block__item {
        min-width: 27%;
        margin: unset;
    }

    .categories-block__list.confused .categories-block__item:last-child,
    .categories-block__list .categories-block__item:last-child {
        margin-right: 10px;
    }

    .categories-block__list .categories-block__image {
        padding: unset;
        height: 100%;
    }

    .categories-block__list .categories-block__image img {
        width: 80%;
    }

    .categories-block__list .categories-block__box {
        position: relative;
        height: 50px;
        padding: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .categories-block__list .categories-block__title {
        font-size: 10px;
        max-width: 90%;
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    .categories-block__list,
    .categories-block__list.confused {
        margin: 30px 10px;
    }

    .categories-block__list.confused .categories-block__item,
    .categories-block__list .categories-block__item {
        min-width: 37%;
    }

    .categories-block__list .categories-block__item:last-child {
        margin-right: unset;
    }
}

@media only screen and (max-width: 767px) {
    .categories-block__list.confused .categories-block__item:nth-child(1):nth-child(1), .categories-block__list.confused .categories-block__item:nth-child(1):nth-child(2), .categories-block__list.confused .categories-block__item:nth-child(2):nth-child(1), .categories-block__list.confused .categories-block__item:nth-child(2):nth-child(2) {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.categories-block__item {
    position: relative;
    max-width: calc(33.33333% - 20px);
    width: 100%;
    -ms-flex-preferred-size: calc(33.33333% - 20px);
    flex-basis: calc(33.33333% - 20px);
    margin: 0 10px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-decoration: none;
}

@media only screen and (max-width: 1249px) {
    .categories-block__item {
        max-height: 205px;
        margin: 0 5px 10px;
        max-width: calc(33.33333% - 10px);
        -ms-flex-preferred-size: calc(33.33333% - 10px);
        flex-basis: calc(33.33333% - 10px);
    }
}

@media only screen and (max-width: 767px) {
    .categories-block__item {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .categories-block__item:nth-child(1), .categories-block__item:nth-child(2) {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

@media only screen and (min-width: 1025px) {
    .categories-block__item:hover .categories-block__image img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);

    }

    .categories-block__item:hover .categories-block__box {
        background: #ec4a9b;
    }
}

.categories-block__image {
    padding: 40px 60px 100px;
    width: 100%;
    height: 345px;
    background: #fff;
    -webkit-box-shadow: inset 0px 23px 136px 0px rgba(0, 0, 0, 0.13);
    box-shadow: inset 0px 23px 136px 0px rgba(0, 0, 0, 0.13);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
}


.categories-block__image img {
    /*  height: 100%; */
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;

}

@media only screen and (max-width: 1249px) {
    .categories-block__image {
        padding: 50px 50px 65px;
    }

    .categories-block__image img {
        height: unset;
    }
}

.categories-block__box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(63, 73, 78, 0.5);
    padding: 14px 28px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.categories-block__title {
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
}

.categories-block__subtitle {
    font-family: 'Open Sans', serif;
    font-size: 14px;
    color: #d6dadc;
}

@media only screen and (max-width: 1023px) {
    .categories-block__subtitle {
        font-size: 12px;
    }
}

.popular-products {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 100px;
}

.popular-products__list .main-catalog__btn {
    top: 25%;
}

.popular-products__list .slick-dots {
    bottom: -40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.popular-products__list .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.popular-products__list .slick-dots li:first-of-type {
    margin-left: 0;
}

.popular-products__list .slick-dots li:last-of-type {
    margin-right: 0;
}

.popular-products__list .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #ccc;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.popular-products__list .slick-dots li button::before {
    display: none;
}

.popular-products__list .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

/*
.popular-products__list .product__image img {
    max-width: 160px;
}*/

@media only screen and (max-width: 767px) {
    .popular-products__list .product__image img {

    }
}

.popular-products__title {
    font-size: 26px;

    color: #333;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto 30px;
}

@media only screen and (max-width: 767px) {
    .popular-products__title {
        font-size: 20px;
        margin: 0 auto 10px;
    }
}

.catalog {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /*justify-content: space-between;*/
    justify-content: flex-start;
    gap: 20px;
}

@media only screen and (max-width: 1599px) {
    .catalog {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) {
    .catalog {
        position: relative;
    }
}

@media only screen and (max-width: 767px) {
    .catalog {
        padding: 0;
    }
}

.catalog__sidebar {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    max-width: 285px;
    width: 100%;
    -ms-flex-preferred-size: 285px;
    flex-basis: 285px;
}

@media only screen and (max-width: 1023px) {
    .catalog__sidebar {
        max-width: 360px;
        width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        z-index: 10102;
        padding: 0 20px;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

    .catalog__sidebar.open {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@media only screen and (max-width: 480px) {
    .catalog__sidebar {
        max-width: 100%;
    }
}

.catalog__sidebar-close {
    max-width: 280px;
    width: 100%;
    height: 38px;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 14px;
    color: #333;
    background: #e6ebec;
    margin: 0 auto 20px;
}

@media only screen and (max-width: 1023px) {
    .catalog__sidebar-close {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.catalog__sidebar-open {
    display: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 90px;
    width: 100%;
    height: 36px;
    font-size: 12px;

    text-transform: uppercase;
    color: #fff;
    background: #333;
}

@media only screen and (max-width: 1023px) {
    .catalog__sidebar-open {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
}

.catalog__main {
    min-width: 1180px;
    width: 100%;
    -ms-flex-preferred-size: 1180px;
    flex-basis: 1180px;
}

@media only screen and (max-width: 767px) {
    .catalog__main {
        min-width: auto;
        min-height: 1050px;
    }
}

.catalog__main .pagination {
    margin: 0 auto 90px;
}

.catalog__products-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -9px 35px;
    border-bottom: 1px solid #e1e6e9;
}


@media only screen and (max-width: 767px) {
    .catalog__products-list {
        margin: 0 0 35px;
        padding: 0 5px;
    }
}

.catalog__products-list .product__item {
    max-width: calc(25% - 18px);
    -ms-flex-preferred-size: calc(25% - 18px);
    flex-basis: calc(25% - 18px);
    height: 513px;
}

.catalog__products-list .swiper-slide{
    min-width: 190px !important;
}

.product__item .ukrainian-brand {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
}

@media only screen and (max-width: 991px) {
    .catalog__products-list .product__item {
        max-width: 240px;
        -ms-flex-preferred-size: 240px;
        flex-basis: 240px;
        margin: 0 5px 25px;
        height: auto;
    }
}

@media only screen and (max-width: 510px) {
    .catalog__products-list .product__item {
        max-width: 46%;
        -ms-flex-preferred-size: 46%;
        flex-basis: 46%;
        height: auto;
    }
}

.catalog__recommend {
    max-width: 280px;
    width: 100%;
    -ms-flex-preferred-size: 280px;
    flex-basis: 280px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 20px;
}

@media only screen and (max-width: 1599px) {
    .catalog__recommend {
        display: none;
    }

    .catalog__main {
        min-width: auto;
        width: calc(100% - 285px - 30px);
    }
}

.catalog__nav {
    padding: 0 30px 25px 8px;
}

.catalog__nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.catalog__nav-list-item {
    font-size: 13px;
    margin-bottom: 10px;
}

.catalog__nav-list-item a {
    color: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-decoration: none;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .catalog__nav-list-item a:hover {
        color: #333;
    }
}

.catalog__nav-list-item.active a {
    color: #333;

}

.catalog__nav-list-opener {
    font-size: 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    margin-top: 15px;
    color: #333;
}

.catalog__nav-list-opener::after {
    content: '';
    width: 4px;
    height: 4px;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    margin-left: 7px;
}

.catalog__nav-list-opener.open::after {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

.catalog__nav-list .collapsed {
    display: none;
}

.catalog__nav-back {
    position: relative;
    display: block;
    color: #333;
    font-size: 14px;
    margin-bottom: 14px;
    padding-left: 15px;
    text-decoration: none;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.catalog__nav-back::after {
    border-bottom: 1px solid #333;
    border-right: 1px solid #333;
    content: "";
    height: 6px;
    width: 6px;
    margin-top: -4px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .catalog__nav-back:hover {
        color: #525252;
    }

    .catalog__nav-back:hover::after {
        border-color: #525252;
    }
}

.catalog--large {
    max-width: 1780px;
    min-height: 850px;
}

@media only screen and (max-width: 1799px) {
    .catalog--large {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .catalog--large {
        padding: 0;
    }
}

.catalog--large .catalog__products-list .product__item {
    max-width: calc(25% - 18px);
    -ms-flex-preferred-size: calc(25% - 18px);
    flex-basis: calc(25% - 18px);
}

@media only screen and (max-width: 1249px) {
    .catalog--large .catalog__products-list .product__item {
        max-width: calc(33.33333% - 18px);
        -ms-flex-preferred-size: calc(33.33333% - 18px);
        flex-basis: calc(33.33333% - 18px);
        margin: 0 9px 40px;
    }
}

@media only screen and (max-width: 767px) {
    .catalog--large .catalog__products-list .product__item {
        max-width: calc(50% - 10px);
        -ms-flex-preferred-size: calc(50% - 10px);
        flex-basis: calc(50% - 10px);
        margin: 0 5px 25px;
    }
}

.catalog__nav-title {

    font-size: 14px;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.catalog-filter__list {
    padding-bottom: 25px;
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 25px;
}

.catalog-filter__item {
    border-top: 1px solid #e1e6e9;
}

.catalog-filter__item.active .catalog-acardion__title::after {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
    opacity: 0;
}

.catalog-filter__item .catalog-filter__inner {
    height: fit-content;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
}
.catalog-filter__item .catalog-filter__content {
    overflow-y: auto;
    margin-bottom: 20px;
}
/*Filter Scrollbar*/
.catalog-filter__item .catalog-filter__content::-webkit-scrollbar {
    width: 4px;
    height: 90%;
}
.catalog-filter__item .catalog-filter__content::-webkit-scrollbar-track {
    background-color: #e5eaed;
    /*height: 90%;*/
}
.catalog-filter__item .catalog-filter__content::-webkit-scrollbar-thumb {
    background-color: #a6aeb2;
    border-radius: 0;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
}
/*Filter Scrollbar*/
.catalog-filter__item.active .catalog-filter__inner {
    max-height: 320px;
}

/*.catalog-filter__item.active .catalog-filter__inner {
    margin-top: -10px;
}*/

.catalog-filter__title {
    cursor: pointer;
    position: relative;
    font-size: 14px;

    color: #333;
    text-transform: uppercase;
    padding: 20px 45px 20px 0;
}

.catalog-filter__title::before {
    content: "";
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 2px;
    width: 12px;
    background: #333;
}

.catalog-filter__title::after {
    content: "";
    position: absolute;
    right: 17px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 12px;
    width: 2px;
    background: #333;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.catalog-filter__inner {
    height: 0;
    overflow: hidden;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.catalog-filter__content {
    max-height: 300px;
}

.catalog-filter__content .checkbox-block {
    position: relative;
    margin: 20px 0 20px 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.catalog-filter__content .checkbox-block:last-child {
    margin-bottom: 10px;
}

.catalog-filter__content .checkbox-block label {
    font-size: 14px;
    color: #333;
}

.catalog-filter__content .checkbox-block input[type="checkbox"] + label::before {
    border-radius: 2px;
}

.catalog-filter__content .checkbox-block input[type="checkbox"] + label::after {
    border-radius: 2px;
}

.catalog-filter__content .checkbox-block input[type="checkbox"]:checked + label {

}

.catalog-filter__content .checkbox-block small {
    font-size: 12px;
    color: #333;
    margin-left: auto;
    margin-right: 35px;
}

.catalog-filter__content .mCSB_scrollTools .mCSB_draggerRail,
.catalog-filter__content .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 4px;
}

.catalog-filter__content .mCSB_scrollTools {
    right: 5px;
}

.catalog-filter__content .mCSB_scrollTools .mCSB_draggerContainer {
    bottom: 10px;
}

.catalog-filter__clear {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    color: #333;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.catalog-filter__clear svg {
    fill: #333;
    width: 9px;
    height: 9px;
    margin-left: 8px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .catalog-filter__clear:hover {
        color: #ec4a9b;
    }

    .catalog-filter__clear:hover svg {
        fill: #ec4a9b;
    }
}

.top-filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 16px;
}

@media only screen and (max-width: 1449px) {
    .top-filters {
        padding: 0 115px;
    }
}

@media only screen and (max-width: 1400px) {
    .top-filters {
        padding: 0 85px;
    }
}

@media only screen and (max-width: 1300px) {
    .top-filters {
        padding: 0 35px;
    }
}

@media only screen and (max-width: 1270px) {
    .top-filters {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 1023px) {
    .top-filters {
        padding: 0;
    }
}

@media only screen and (max-width: 767px) {
    .top-filters {
        padding: 0 10px 15px;
        border-bottom: 1px solid #c3ced5;
    }
}

.top-filters__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.top-filters__item > span {
    font-size: 12px;
    color: #333;
    margin-right: 10px;
}

.top-filters__item .select {
    font-size: 12px;
    color: #333;
    border: 1px solid #c3ced5;
    padding: 10px 35px 10px 18px;
}

.top-filters__item .select--open {
    border-color: transparent;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
}

.top-filters__item .select.sorting {
    min-width: 192px;
}

.top-filters__item .select .select__list {
    /*transform: scale(0) translateY(0) !important;*/
    margin-top: 100%;
    border-radius: 0;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
}

.top-filters__item .select .select__item {
    padding: 10px 18px;
    border-bottom: 1px solid #c3ced5;
}

.top-filters__item .select .select__item:last-of-type {
    border-bottom: none;
}

.top-filters__item .select .select__item--active {
    background: #fff;
}

@media only screen and (max-width: 767px) {
    .top-filters__item--number {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .top-filters__item--sorting > span {
        display: none;
    }
}


.sale-counter {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    max-width: 110px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 13px 0px rgba(47, 74, 90, 0.12);
    box-shadow: 0px 3px 13px 0px rgba(47, 74, 90, 0.12);
}

.sale-counter__top {
    font-family: 'Open Sans', serif;
    font-size: 12px;
    color: #333;
    background: #e3e6e8;
    padding: 4px;
    text-align: center;
}

.sale-counter__box {
    background: #fff;
    padding: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.sale-counter__item {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    width: 48px;
    height: 26px;
    font-size: 20px;
    z-index: 2;
}

.sale-counter__item span {
    color: #fff;
    text-align: center;
    display: block;
    letter-spacing: 16px;
    margin-left: 6px;
    margin-top: 2px;
}

.sale-counter__item::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 22px;
    background: #333;
    top: 0;
    left: 0;
    border-radius: 4px;
    z-index: -1;
}

.sale-counter__item::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 22px;
    background: #333;
    top: 0;
    right: -2px;
    border-radius: 4px;
    z-index: -1;
}

.sale-counter__text {
    font-size: 13px;
    color: #333;
    margin-left: 8px;
}

@media only screen and (max-width: 767px) {
    .sale-counter {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        max-width: 60px;
        width: 60%;
        -webkit-box-shadow: 0px 3px 13px 0px rgba(47, 74, 90, 0.12);
        box-shadow: 0px 3px 13px 0px rgba(47, 74, 90, 0.12);
    }

    .sale-counter__top {
        font-family: 'Open Sans', serif;
        font-size: 7px;
        color: #99a1a7;
        background: #e3e6e8;
        padding: 2px;
        text-align: center;
    }

    .sale-counter__box {
        background: #fff;
        padding: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .sale-counter__item {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        position: relative;
        width: 24px;
        height: 13px;
        font-size: 11px;
        z-index: 2;
    }

    .sale-counter__item span {
        color: #fff;
        text-align: center;
        display: block;
        letter-spacing: 8px;
        margin-left: 3.5px;
        margin-top: 1px;
    }

    .sale-counter__item::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 11px;
        background: #333;
        top: 0;
        left: 0;
        border-radius: 2px;
        z-index: -1;
    }

    .sale-counter__item::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 11px;
        background: #333;
        top: 0;
        right: -2px;
        border-radius: 4px;
        z-index: -1;
    }

    .sale-counter__text {
        font-size: 9px;
        color: #333;
        margin-left: 8px;
    }
}

.colors-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-top: 35px;
}

.colors-list .product-colors__item {
    margin: 4px;
}

.recommend-block {
    margin-bottom: 95px;
}

.recommend-block__main-title {
    font-size: 14px;

    color: #333;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.recommend-block__list {
    border-top: 2px solid #b0bfc8;
}

.recommend-block__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 18px 0;
    border-bottom: 1px solid #e1e6e9;
}

.recommend-block__image {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    max-width: 108px;
    width: 100%;
    -ms-flex-preferred-size: 108px;
    flex-basis: 108px;
    margin-right: 10px;
}

.recommend-block__info {
    max-width: 150px;
    width: 100%;
    -ms-flex-preferred-size: 150px;
    flex-basis: 150px;
}

.recommend-block__title {
    font-size: 13px;

}

.recommend-block__title a {
    color: #333;
    text-decoration: none;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .recommend-block__title a:hover {
        color: #ec4a9b;
    }
}

.recommend-news__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    padding: 20px 0 20px 15px;
    border-bottom: 1px solid #e1e6e9;
}

.recommend-news__image {
    max-width: 113px;
    width: 100%;
    -ms-flex-preferred-size: 113px;
    flex-basis: 113px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 20px;
}

.recommend-news__title {
    max-width: 125px;
    width: 100%;
    font-size: 13px;
    color: #333;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .recommend-news__title:hover {
        color: #ec4a9b;
    }
}

.active-filters {
    border-top: 1px solid #e1e6e9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 20px;
}

@media only screen and (max-width: 1023px) {
    .active-filters {
        display: none;
    }
}

.active-filters__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -13px;
}

@media only screen and (max-width: 1249px) {
    .active-filters__list {
        margin: 0 -5px;
    }
}

.active-filters__item {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #333;
    margin: 0 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 1249px) {
    .active-filters__item {
        margin: 0 5px;
    }
}

.active-filters__item svg {
    fill: #333;
    width: 9px;
    height: 9px;
    margin-left: 8px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .active-filters__item:hover {
        color: #ec4a9b;
    }

    .active-filters__item:hover svg {
        fill: #ec4a9b;
    }
}

.active-filters__clear {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    margin-left: 50px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.active-filters__clear svg {
    fill: #333;
    width: 9px;
    height: 9px;
    margin-left: 8px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .active-filters__clear:hover {
        color: #ec4a9b;
    }

    .active-filters__clear:hover svg {
        fill: #ec4a9b;
    }
}

.filters-active {
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 20px;
}

.filters-active__wrap {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .filters-active__wrap {
        display: block;
    }
}

.filters-active .active-filters__item {
    margin: 0 5px 10px;
}

.filters-active .active-filters__clear {
    background: #f4f5f5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 28px;
    max-width: 280px;
    width: 100%;
    margin: 0 auto 15px;
}

.filters-active__title {
    font-size: 14px;

    color: #333;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.brend-content {
    max-width: 1180px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #f5f7f7;
    padding: 30px 35px;
    margin-bottom: 40px;
}

@media only screen and (max-width: 1023px) {
    .brend-content {
        padding: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .brend-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px;
        margin-bottom: 30px;
    }
}

.brend-content__image {
    background-color: #fff;
    max-width: 230px;
    width: 100%;
    -ms-flex-preferred-size: 230px;
    flex-basis: 230px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 124px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #e1e6e9;
    margin-right: 35px;
}

.brend-content__image img {
    max-width: 200px;

}

@media only screen and (max-width: 1023px) {
    .brend-content__image {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .brend-content__image {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        max-width: 370px;
        height: auto;
        min-height: 65px;
        margin: 0 auto 15px;
    }
}

.brend-content__content {
    max-width: 850px;
    width: 100%;
    -ms-flex-preferred-size: 850px;
    flex-basis: 850px;
}

@media only screen and (max-width: 767px) {
    .brend-content__content {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.brend-content__title {
    font-size: 14px;

    color: #333;
    margin-bottom: 12px;
}

.brend-content__text {
    font-size: 13px;
    color: #333;
    /*max-height: 85px;*/
    overflow: hidden;
}

/*.brend-content__text noindex pre {
    font-family: 'Open Sans', serif;
}*/

@media only screen and (max-width: 767px) {
    .brend-content__text {
        /*max-height: 152px;*/
    }
}

.brend-content__text .mCSB_scrollTools .mCSB_draggerRail,
.brend-content__text .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 2px;
}

@media only screen and (max-width: 767px) {
    .catalog__sidebar.open {
        max-height: calc(130vh - 120px);
        overflow: auto;
        padding-bottom: 100%;
    }
}

.catalog-filter__mobile-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 -10px;
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 15px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 15px 20px 15px;
    bottom: 0;
    margin: 0;
    position: fixed;
    z-index: 10102;
    background: #fff;
    left: 0;
    max-width: 360px;
    width: 100%;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
}

@media only screen and (max-width: 480px) {
    .catalog-filter__mobile-buttons {
        max-width: 100%;
    }
}

.catalog-filter__mobile-buttons.active {
    visibility: visible;
    opacity: 1;
    overflow: visible;
}

.catalog-filter__mobile-show {
    max-width: 130px;
    width: 100%;
    height: 35px;
    border: 1px solid #c3ced5;
    font-size: 10px;
    color: #333;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    margin: 0 10px;
}

.catalog-filter__mobile-clear {
    max-width: 130px;
    width: 100%;
    height: 35px;
    margin: 0 10px;
    background: #e8eff3;
    font-size: 10px;
    color: #333;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
}

.info-qunt {
    width: 112px;
    position: absolute;
    top: -14px;
    right: 2px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
    padding: 5px;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
}

.info-qunt.active {
    visibility: visible;
    opacity: 1;
    overflow: visible;
}

@media only screen and (max-width: 767px) {
    .info-qunt {
        display: none;
    }
}

.info-qunt::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background: #fff;
    top: 10px;
    left: -12px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
    -webkit-box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.04);
}

.info-qunt__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.info-qunt__title {
    font-size: 12px;
    color: #8b979e;
}

.info-qunt__value {
    font-size: 12px;
    color: #000;
    margin-left: 8px;
}

.info-qunt__button {
    width: 100%;
    height: 22px;
    font-size: 10px;
    color: #000;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #c3ced5;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .info-qunt__button:hover {
        background: #333;
        color: #fff;
    }
}

.price-slider__inner,
.catalog-filter__content {
    position: relative;
}

.price-slider__inner .info-qunt,
.catalog-filter__content .info-qunt {
    top: -10px;
    right: 3px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.colors-info-qunt {
    top: 11px !important;
    box-shadow: none !important;
}

.colors-info-qunt-brand {
    top: 3px !important;
}

.price-slider__inner .info-qunt::before,
.catalog-filter__content .info-qunt::before {
    display: none;
}

.colors__inner .info-qunt {
    top: 4px;
    right: 3px;
}

.price-slider__inner .info-qunt {
    top: 4px;
    right: 3px;
}

.certificates {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 135px;
}

@media only screen and (max-width: 1599px) {
    .certificates {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .certificates {
        padding: 0 10px;
    }
}

.certificates__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 0 -5px 40px;
    border-bottom: 1px solid #e1e6e9;
}

@media only screen and (max-width: 1599px) {
    .certificates__list {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.certificates__item {
    display: block;
    max-width: 284px;
    width: 100%;
    -ms-flex-preferred-size: 284px;
    flex-basis: 284px;
    text-decoration: none;
    margin: 0 5px 50px;
}

@media only screen and (max-width: 1599px) {
    .certificates__item {
        max-width: 19.1%;
        -ms-flex-preferred-size: 19.1%;
        flex-basis: 19.1%;
        margin: 0 5px 18px;
    }
}

@media only screen and (max-width: 1249px) {
    .certificates__item {
        max-width: 31%;
        -ms-flex-preferred-size: 31%;
        flex-basis: 31%;
    }
}

@media only screen and (max-width: 767px) {
    .certificates__item {
        max-width: 45%;
        -ms-flex-preferred-size: 45%;
        flex-basis: 45%;
        margin: 0 5px 15px;
    }
}

.certificates__image {
    position: relative;
    margin-bottom: 16px;
}

.certificates__image::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.certificates__image svg {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    fill: #fff;
    width: 18px;
    height: 18px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .certificates__image:hover::before {
        visibility: visible;
        opacity: 1;
        overflow: visible;
    }

    .certificates__image:hover svg {
        visibility: visible;
        opacity: 1;
        overflow: visible;
    }
}

.certificates__title {
    padding: 0 15px;
    font-size: 15px;

    color: #333;
    text-align: center;
}

.certificates__title small {
    font-size: 14px;
    color: #999;
    font-weight: 400;
}

@media only screen and (max-width: 767px) {
    .certificates__title {
        font-size: 12px;
        text-align: left;
        padding: 0;
    }
}

#soa-order > #soa-properties {
    grid-area: fields;
}

#soa-order > #bx-soa-user-block {
    grid-area: authArea;
}

#soa-order > #bx-soa-checkout-basket {
    grid-area: basket;
}

#soa-order > #bx-soa-order-btn {
    grid-area: authArea;
    margin-top: -19px;
}

#ordering-title__first {
    margin-top: 2px;
    color: #333;
}

.checkout-block {
    max-width: 1180px;
    width: 100%;
    margin: 100px auto 250px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
}

@media only screen and (max-width: 1249px) {
    .checkout-block {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) {
    .checkout-block {
        grid-template-areas:
            "authArea"
            "fields"
            "basket";
    }

    .checkout-block {
        display: grid;
        grid-template-columns: 1fr;
        /*grid-template-rows: 3fr 2fr;*/

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        align-items: center;
        margin-top: 0;
    }

    #bx-soa-order-btn {
        margin: 0 auto 30px;
    }
}

@media only screen and (max-width: 767px) {
    .checkout-block {
        margin: 30px auto 30px;
    }
}

.checkout-block__steps {
    max-width: 490px;
    width: 100%;
    -ms-flex-preferred-size: 490px;
    flex-basis: 490px;
    margin-right: 20px;
}

@media only screen and (max-width: 1023px) {
    .checkout-block__steps {
        max-width: 400px;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto 30px;
    }
}

.checkout-block__step {
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e1e6e9;
}

.checkout-block__step.disabled {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}

.checkout-block__step.disabled .checkout-block__step-title {
    opacity: 0.4;
}

.checkout-block__step.filled {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkout-block__step.filled .checkout-block__step-title {
    color: #adb7bd;
}

.checkout-block__step.filled .checkout-block__step-title span {
    background: #bac2c7;
    color: #fff;
}

@media only screen and (max-width: 480px) {
    .checkout-block__step.filled {
        display: block;
    }
}

.checkout-block__step-1 {
    max-width: 402px;
    width: 100%;
    -ms-flex-preferred-size: 402px;
    flex-basis: 402px;
}

.checkout-block__step-2 {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}

.checkout-block__step-title {
    font-size: 14px;
    color: #333;

    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.checkout-block__step-title span {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #c6d1d7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 16px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.checkout-block__step-content .form-block {
    margin-bottom: 25px;
}

.checkout-block__step-content .form-block .main-input {
    height: 44px;
    border-color: #c3ced5;
}

.checkout-block__step-content .form-block .main-input.error {
    border-color: #ec4a9b;
}

.next-step {
    cursor: pointer;
    max-width: 218px;
    width: 100%;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px;

    color: #fff;
    text-transform: uppercase;
    background: #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .next-step:hover {
        background: #ec4a9b;
    }
}

.checkout-tabs {
    list-style: none;
    margin: 0 0 25px;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px solid #e1e6e9;
    border-bottom: 1px solid #e1e6e9;
}

.checkout-tabs__item {
    width: 50%;
    font-size: 11px;

    text-transform: uppercase;
    text-align: center;
}

.checkout-tabs__item a {
    position: relative;
    padding: 12px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    color: #b4babe;
}

.checkout-tabs__item a::before {
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    width: 0;
    height: 2px;
    background: #b0bfc8;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.checkout-tabs__item a::after {
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    width: 0;
    height: 2px;
    background: #b0bfc8;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.checkout-tabs__item a.active {
    color: #545e65;
}

.checkout-tabs__item a.active::before, .checkout-tabs__item a.active::after {
    width: 100%;
}

.checkout-basket {
    background: #fff;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    max-width: 455px;
    width: 100%;
    -ms-flex-preferred-size: 455px;
    flex-basis: 455px;
}

@media only screen and (max-width: 1023px) {
    .checkout-basket {
        max-width: 400px;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto;
    }
}

.checkout-basket__list {
    max-height: 567px;
    overflow-y: auto;
}

.checkout-basket__list .mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.checkout-basket__list .mCSB_scrollTools {
    top: -5px;
    right: -6px;
}

.checkout-basket__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 15px 0 20px 30px;
    margin: 0 24px;
    border-bottom: 1px solid #e1e6e9;
}

.checkout-basket__item:last-of-type {
    border-bottom: none;
}

@media only screen and (max-width: 767px) {
    .checkout-basket__item {
        margin: 0 10px;
        padding: 15px 0 20px 20px;
    }
}

.checkout-basket__delete {
    cursor: pointer;
    position: absolute;
    top: 40px;
    left: 5px;
}

.checkout-basket__delete svg {
    width: 9px;
    height: 9px;
    fill: #89959b;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .checkout-basket__delete:hover svg {
        fill: #ec4a9b;
    }
}

.checkout-basket__image {
    max-width: 80px;
    width: 100%;
    -ms-flex-preferred-size: 80px;
    flex-basis: 80px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
    .checkout-basket__image {
        -ms-flex-negative: 1;
        flex-shrink: 1;
        margin-right: 10px;
    }
}

.checkout-basket__content {
    max-width: 195px;
    width: 100%;
    -ms-flex-preferred-size: 195px;
    flex-basis: 195px;
}

@media only screen and (max-width: 767px) {
    .checkout-basket__content .basket-popup__title {
        font-size: 11px;
    }
}

.checkout-basket__price-box {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: auto;
    text-align: right;
}

.checkout-basket__color {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
}

.checkout-basket__color-current {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkout-basket__color-current .product-colors__item {
    pointer-events: none;
}

.basket-popup__color .product-colors__item {
    pointer-events: none;
}

@media only screen and (max-width: 767px) {
    .checkout-basket__color-current .product-colors__item:last-of-type {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.checkout-basket__color-current-text {
    font-family: 'Open Sans', serif;
    color: #333;
    font-size: 14px;
    margin-right: 8px;
}

.checkout-basket__color-change {
    cursor: pointer;
    margin-left: 10px;
    font-size: 12px;
    color: #596368;
    border-bottom: 1px dotted #596368;
    padding-bottom: 2px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .checkout-basket__color-change:hover {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }
}

.checkout-basket__price-old {
    font-size: 11px;

    color: #333;
    text-decoration: line-through;
    margin-bottom: 8px;
}

.checkout-basket__price-new {
    font-size: 13px;

    color: #ec4a9b;
}

.checkout-basket__price-current {
    font-size: 16px;

    color: #333;
}

.checkout-basket__total {
    border-top: 1px solid #e1e6e9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 30px;
}

.checkout-basket__total ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
}

.checkout-basket__total ul li {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 16px;
    color: #333;
}

.checkout-basket__total ul li span {
    font-size: 16px;

    color: #333;
}

.checkout-basket__total ul li:last-of-type {
    margin-bottom: 0;
}

.checkout-basket__total-text {
    font-size: 14px;
    color: #333;
}

.checkout-basket__total-price {
    font-size: 18px;

    color: #333;
}

.checkout-basket__bottom {
    margin: 0 22px;
    border-top: 1px solid #e1e6e9;
    padding: 40px 0 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.checkout-basket__bottom--added {
    margin: 0;
}

#delivery-message {
    color: #000;

}

.checkout-basket__bottom--added .checkout-basket__total-block {
    padding-bottom: 0;
    border: none;
}

.checkout-basket__bottom--added .checkout-basket__total-block ul li {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.checkout-basket__bottom--added form {
    margin-bottom: 22px !important;
}

.checkout-basket__bottom--added form .submit-btn {
    font-size: 13px;
    font-family: 'Open Sans', serif;
    max-width: 400px !important;
    height: 50px;
}

.checkout-basket__bottom form {
    width: 100%;
    margin: 0 auto 28px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 1023px) {
    .checkout-block .checkout-basket__bottom form {
        padding: 0 30px;
        margin-bottom: 28px;
    }
}

.checkout-basket__bottom form .submit-btn {
    max-width: 220px;
}

.checkout-basket__bottom-link {
    cursor: pointer;
    font-size: 14px;

    color: #333;
    margin-bottom: 20px;
    border-bottom: 1px dotted #333;
    padding-bottom: 2px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .checkout-basket__bottom-link:hover {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }
}

.checkout-basket__bottom-link.added-promocode {
    pointer-events: none;
    border: none;
    color: #aeb8be;
    padding: 0;
}

.checkout-basket__bottom-price {
    text-align: center;
    margin: 0 auto 20px;
    font-size: 15px;
    color: #333;
}

.checkout-basket__bottom-price span {
    margin-left: 10px;
    font-size: 18px;

    color: #535f66;
}

.checkout-basket__total-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 20px 0 0;
    margin-bottom: 0;
}

.checkout-basket__total-title {
    font-size: 14px;

    color: #535f66;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.checkout-basket__total-block {
    width: 100%;
    padding: 0 30px 35px;
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
    .checkout-basket__total-block {
        padding: 0 15px 35px;
    }
}

.checkout-basket__total-block:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
}

.checkout-basket__total-block ul {
    margin: 0;
    padding: 0;
}

.checkout-basket__total-block ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkout-basket__total-block ul li .text {
    font-size: 13px;
    font-weight: 400;
    color: #8b979e;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.checkout-basket__total-block ul li .dots {
    -ms-flex-item-align: end;
    align-self: flex-end;
    border-bottom: 1px dotted #999;
    width: 100%;
    margin: 0 5px 4px;
}

.checkout-basket__total-block ul li .value {
    font-size: 16px;
    color: #535f66;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkout-basket__total-block ul li .value .checkout-sale {
    padding: 2px 4px;
    width: auto;
    height: auto;
    margin-right: 12px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.checkout-basket__total-block ul li .value .checkout-sale::before {
    display: none;
}

.checkout-basket__total-block ul li .value .checkout-sale::after {
    content: '';
    position: absolute;
    right: -16px;
    top: 0;
    border: 9px solid transparent;
    border-left: 9px solid #ec4a9b;
}

.checkout-basket__total-block ul li .total-value {

    font-size: 22px;
    color: #ec4a9b;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.checkout-basket__total-block ul li .total-value small {
    font-size: 14px;

    color: #91999d;
    text-decoration: line-through;
}

@media only screen and (max-width: 767px) {
    .checkout-basket__total-block ul li .total-value {
        font-size: 14px;
    }

    .checkout-basket__total-block ul li .total-value small {
        font-size: 12px;
    }
}

.radio-block__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e1e6e9;
}

@media only screen and (max-width: 767px) {
    .radio-block__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.radio-block__title {

    font-size: 14px;
    color: #333;
    width: 135px;
    margin-right: 10px;
}

@media only screen and (max-width: 767px) {
    .radio-block__title {
        margin: 0 0 20px;
    }
}

.radio-block__wrap {
    max-width: 345px;
    width: 100%;
    -ms-flex-preferred-size: 345px;
    flex-basis: 345px;
}

@media only screen and (max-width: 767px) {
    .radio-block__wrap {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.radio-block input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.radio-block input[type="radio"] + label {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 13px;
    color: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 25px;
    cursor: pointer;
}

.radio-block input[type="radio"] + label:before {
    content: '';
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: #fff;
    border-radius: 100%;
    border: 1px solid #768187;
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
    top: 0;
    margin-right: 15px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
}

.radio-block input[type="radio"]:checked + label:before {
    border-color: #768187;
    background-color: #768187;
    -webkit-box-shadow: inset 0 0 0 3px #fff;
    box-shadow: inset 0 0 0 3px #fff;
}

.radio-block input[type="radio"]:focus + label:before {
    outline: none;
}

.radio-block input[type="radio"]:disabled + label:before {
    -webkit-box-shadow: inset 0 0 0 2px #fff;
    box-shadow: inset 0 0 0 2px #fff;
    border-color: #efefef;
}

.radio-block input[type="radio"] + label:empty:before {
    margin-right: 0;
}

.checkout-country {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 30px;
}

.checkout-country__text {

    font-size: 14px;
    color: #333;

}

.checkout-country .select {
    border: none;
    font-family: 'Open Sans', serif;
    font-size: 13px;
    color: #768187;
    padding: 0px 20px;
}

.checkout-country .select::after {
    right: 5px;
    height: 5px;
    width: 5px;
    border-bottom: 1px solid #768187;
    border-right: 1px solid #768187;
}

.checkout-country .select .select__current {
    border-bottom: 1px dotted #768187;
    display: table;
}

.checkout-country .select .select__list {
    -webkit-box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.12);
    box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.12);
    background: #fff;
    min-width: 135px;
    max-height: 220px;
    overflow-y: auto;
    border-radius: 0;
    left: auto;
    right: 0;
    margin-top: 100%;
}

.checkout-country .select .select__item {
    font-size: 14px;
    color: #768187;
    text-align: right;
    padding: 7px 30px 7px 15px;
}

.checkout-country .select .select__item--active {
    background: #fff;
}

.add-comment {
    cursor: pointer;
    display: table;
    margin: 0 auto 20px;
    font-size: 13px;
    color: #7a8a94;
    border-bottom: 1px dotted #7a8a94;
}

.add-comment-inner .form-block--textarea {
    display: none;
}

.edit-step {
    margin-bottom: 20px;
    font-size: 12px;
    color: #8c989f;
    border-bottom: 1px dotted #8c989f;
    text-decoration: none;
    margin-left: 40px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .edit-step:hover {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }
}

@media only screen and (max-width: 480px) {
    .edit-step {
        display: table;
        margin: -20px 0 20px 52px;
    }
}

.delivery-block {
    margin-bottom: 10px;
    display: none;
}

.delivery-block.active {
    display: block;
}

.delivery-block .form-block {
    margin-bottom: 18px;
}

.delivery-block .form-block label {
    display: block;
    font-size: 12px;
    color: #9fa6ab;
    margin-bottom: 5px;
}

.delivery-block .form-block .main-input {
    height: 40px;
}

.delivery-block .form-block .select {
    font-size: 12px;
    border: 1px solid #c3ced5;
    color: #333;
    padding: 0 22px;
    padding: 12.5px 22px;
}

.delivery-block .form-block .select .select__current {
    overflow: hidden;
    max-height: 14px;
    display: block;
}

.delivery-block .form-block .select .select__list {
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0;
    width: auto;
    left: -1px;
    right: -1px;
    margin-top: 100%;
}

.delivery-block .form-block .select .select__item {
    padding: 10px 22px;
}

.checkout-sale {
    position: relative;
    background: #ec4a9b;
    color: #fff;
    font-size: 13px;

    height: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.checkout-sale::before {
    content: '';
    position: absolute;
    left: -28px;
    top: 0;
    border: 14px solid transparent;
    border-right: 14px solid #ec4a9b;
}

.checkout-finish {
    max-width: 650px;
    width: 100%;
    margin: 0 auto 110px;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .checkout-finish {
        margin: 0 auto 60px;
        padding: 0 15px;
    }
}

.checkout-finish__image {
    margin-bottom: 35px;
}

@media only screen and (max-width: 1449px) {
    .checkout-finish__image img {
        max-width: 120px;
    }
}

@media only screen and (max-width: 767px) {
    .checkout-finish__image img {
        max-width: 200px;
    }
}

.checkout-finish__title {
    font-size: 18px;

    color: #333;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.checkout-finish__text {
    font-size: 14px;
    color: #333;
    max-width: 500px;
    margin: 10px auto;
}

.checkout-finish__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.checkout-finish__buttons a {
    margin: 0 10px;
}

@media only screen and (max-width: 767px) {
    .checkout-finish__buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .checkout-finish__buttons a {
        margin: 5px 0;
    }

    .checkout-finish__buttons a:first-of-type {
        margin-top: 0;
    }

    .checkout-finish__buttons a:last-of-type {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 540px) {
    .checkout-finish__buttons a {
        max-width: 100%;
        width: 100%;
    }
}

.socials-block {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 110px;
}

@media only screen and (max-width: 1599px) {
    .socials-block {
        padding: 0 15px;
    }
}

.socials-block__title {
    font-size: 32px;

    text-transform: uppercase;
    color: #333;
    text-align: center;
    margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
    .socials-block__title {
        font-size: 28px;
    }
}

.socials-block__subtitle {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
}

.socials-block__subtitle::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #e1e6e9;
}

.socials-block__subtitle::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #e1e6e9;
}

.socials-block__subtitle span {
    font-size: 14px;
    color: #333;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0 40px;
}

@media only screen and (max-width: 767px) {
    .socials-block__subtitle span {
        -ms-flex-negative: 1;
        flex-shrink: 1;
        width: 100%;
        text-align: center;
    }

    .socials-block__subtitle::before, .socials-block__subtitle::after {
        display: none;
    }
}

.socials-block__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin: 0 -7px;
}

.socials-block__list .slick-dots {
    bottom: -40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.socials-block__list .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.socials-block__list .slick-dots li:first-of-type {
    margin-left: 0;
}

.socials-block__list .slick-dots li:last-of-type {
    margin-right: 0;
}

.socials-block__list .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.socials-block__list .slick-dots li button::before {
    display: none;
}

.socials-block__list .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

@media only screen and (max-width: 1249px) {
    .socials-block__list {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 1023px) {
    .socials-block__list {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.socials-block__item {
    position: relative;
    max-width: 285px;
    width: 100%;
    -ms-flex-preferred-size: 285px;
    flex-basis: 285px;
    margin: 0 7px 14px;
}

@media only screen and (max-width: 1023px) {
    .socials-block__item {
        max-width: 236px;
        -ms-flex-preferred-size: 236px;
        flex-basis: 236px;
    }
}

@media only screen and (max-width: 767px) {
    .socials-block__item {
        max-width: 285px;
        -ms-flex-preferred-size: 285px;
        flex-basis: 285px;
    }
}

.socials-block__item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
}

.socials-block__top {
    position: absolute;
    left: 18px;
    top: 18px;
}

.socials-block__item-title {
    font-family: 'Open Sans', serif;
    font-size: 18px;

    color: #fff;
    margin-bottom: 5px;
}

.socials-block__item-subtitle {
    font-size: 14px;
    color: #fff;
}

.socials-block__users {
    font-family: 'Open Sans', serif;
    font-size: 14px;
    color: #fff;
    position: absolute;
    top: 18px;
    right: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.socials-block__users svg {
    width: 12px;
    height: 12px;
    fill: #fff;
    margin-right: 6px;
}

.socials-block__link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    text-decoration: none;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    background: #ec4a9b;
}

.socials-block__link svg {
    fill: #fff;
    width: 15px;
    height: 15px;
    margin-right: 12px;
}

.socials-block__link--facebook {
    background: #3b5998;
}

.socials-block__link--facebook svg {
    width: 9px;
    height: 17px;
}

.socials-block__link--pinterest {
    background: #bd081c;
}

.socials-block__link--youtube {
    background: #ff0000;
}

.socials-block__link--google {
    background: #da4835;
}

.socials-block__link--instagram {
    background: #d62f7e;
}

@media only screen and (min-width: 1025px) {
    .socials-block__link:hover {
        height: 42px;
    }
}

@media only screen and (max-width: 1250px) {
    .compare {
        overflow-x: auto;
    }
}

.compare__table-wrap {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 130px;
}

@media only screen and (max-width: 1599px) {
    .compare__table-wrap {
        padding: 0 15px;
    }
}

.compare__table-wrap .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    height: 8px;
    background: #e9eef0;
}

.compare__table-wrap .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 8px;
    background: #b0bfc8;
    margin: 7px auto;
}

.compare__table-wrap .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: -4px;
}

.compare__table {
    border-collapse: collapse;
}

.compare__table thead tr td {
    border-left: 1px solid #efeff0;
    border-right: 1px solid #efeff0;
}

.compare__table tbody {
    border-top: 1px solid #efeff0;
    border-bottom: 1px solid #efeff0;
}

.compare__table tbody tr {
    background: #fff;
}

.compare__table tbody tr:nth-child(odd) {
    background: #f5f7f7;
}

.compare__table tbody tr td {
    font-size: 14px;

    color: #768187;
    height: 50px;
    padding: 0 20px;
    border-left: 1px solid #efeff0;
    border-right: 1px solid #efeff0;
}

.compare__table tbody tr td.bold {
    font-weight: 400;
    background: #fff;
    border-bottom: 1px solid #efeff0;
}

@media only screen and (max-width: 767px) {
    .compare__table tbody tr td {
        font-size: 12px;
        padding: 0 15px;
        height: 45px;
    }
}

.compare__params {
    min-width: 282px;
    padding-right: 30px;
    border-left: none !important;
}

@media only screen and (max-width: 767px) {
    .compare__params {
        min-width: 190px;
    }
}

.compare__params-item {
    font-size: 14px;
    color: #869198;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    padding: 18px 10px 18px 35px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.compare__params-item::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 34px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0;
    height: 2px;
    background: #9baab3;
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}

.compare__params-item:first-child {
    border-bottom: 1px solid #e7e7e7;
}

.compare__params-item.active {
    color: #333;

    padding-left: 70px;
}

.compare__params-item.active::before {
    width: 30px;
}

@media only screen and (max-width: 767px) {
    .compare__params-item.active::before {
        width: 10px;
    }
}

@media only screen and (min-width: 1025px) {
    .compare__params-item:hover {
        padding-left: 70px;
    }

    .compare__params-item:hover::before {
        width: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .compare__params-item {
        padding: 14px 10px;
        font-size: 12px;
        text-align: center;
    }

    .compare__params-item::before {
        left: 10px;
    }

    .compare__params-item.active {
        padding-left: 18px;
    }
}

.compare .product__item {
    margin: 0 0 30px;
    max-width: 295px;
    width: 295px;
}

.compare .product__item .product__image {
    border: none;
}

.compare .product__item .product__image img {
    max-width: 200px;
}

.compare .product__item .product__info {
    padding: 0 20px;
}

@media only screen and (max-width: 767px) {
    .compare .product__item {
        max-width: 260px;
        width: 260px;
    }

    .compare .product__item .product__image {
        border: none;
    }

    .compare .product__item .product__image img {
        max-width: 160px;
    }

    .compare .product__item .wishlist-btn {
        display: block;
    }
}

.contacts-block {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 195px;
}

@media only screen and (max-width: 1599px) {
    .contacts-block {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 1023px) {
    .contacts-block {
        margin: 0 auto 50px;
    }
}

@media only screen and (max-width: 767px) {
    .contacts-block {
        padding: 0;
        margin: 0 auto 30px;
    }
}

.contacts-block__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 767px) {
    .contacts-block__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.contacts-block__main {
    max-width: 560px;
    width: 100%;
    -ms-flex-preferred-size: 560px;
    flex-basis: 560px;
}

@media only screen and (max-width: 1023px) {
    .contacts-block__main {
        max-width: 340px;
        -ms-flex-preferred-size: 340px;
        flex-basis: 340px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
}

@media only screen and (max-width: 767px) {
    .contacts-block__main {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        -ms-flex-negative: 1;
        flex-shrink: 1;
    }
}

.contacts-block__tabs-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #e1e6e9;
}

@media only screen and (max-width: 767px) {
    .contacts-block__tabs-list {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.contacts-block__tabs-item {
    max-width: 275px;
    width: 100%;
    font-size: 16px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .contacts-block__tabs-item {
        max-width: 186px;
    }
}

.contacts-block__tabs-item {
    border-right: 1px solid #e1e6e9;
}

.contacts-block__tabs-item:last-of-type {
    border-right: none;
}

.contacts-block__tabs-item a {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 16px 0;
    text-decoration: none;
    color: #333;
}

.contacts-block__tabs-item a::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -1px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 2px;
    width: 0;
    background: #b0bfc8;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.contacts-block__tabs-item a.active {
    color: #333;
}

.contacts-block__tabs-item a.active::after {
    width: 100%;
}

.contacts-block__main-title {
    font-size: 26px;

    text-transform: uppercase;
    margin-bottom: 30px;
    color: #333;
}

@media only screen and (max-width: 767px) {
    .contacts-block__main-title {
        text-align: center;
        font-size: 18px;
        padding: 0 15px;
    }
}

.map {
    max-width: 920px;
    width: 100%;
    -ms-flex-preferred-size: 920px;
    flex-basis: 920px;
    height: 495px;
}

@media only screen and (max-width: 767px) {
    .map {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.contacts-popup__inner {
    max-width: 140px;
    width: 100%;
}

.contacts-popup__image {
    margin-bottom: 12px;
}

.contacts-popup__title {
    font-size: 11px;

    color: #333;
    text-transform: uppercase;
    text-align: center;
}

.contacts-content {
    padding: 25px 60px;
}

.contacts-content.active {
    background: #f0f3f4;
}

@media only screen and (max-width: 1023px) {
    .contacts-content {
        padding: 60px 30px 35px;
    }
}

@media only screen and (max-width: 767px) {
    .contacts-content {
        padding: 18px 20px;
    }
}

.contacts-content__main-title {
    text-transform: uppercase;
    font-size: 16px;

    color: #333;
    margin-bottom: 15px;
}

.contacts-content__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 25px;
}

.contacts-content__item:last-of-type {
    margin-bottom: 0;
}

.contacts-content__icon {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 12px;
}

.contacts-content__icon svg {
    fill: #949fa6;
    width: 22px;
    height: 22px;
}

.contacts-content__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #333;
    margin-bottom: 4px;
}

.contacts-content__value {
    font-size: 14px;
    color: #333;

    margin-bottom: 10px;
}

.contacts-content__value a {
    color: #333;
    text-decoration: none;
}

.contacts-content__value span {
    font-weight: 400;
    color: #333;
    display: block;
}

.contacts-content__value small {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: #333;
}

.contacts-content__value .phones-list__dropdown {
    top: 18px;
}

.contacts-content__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 1023px) {
    .contacts-content__box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.contacts-content__email {
    position: relative;
    margin: 0 22px;
}

.contacts-content__email:first-of-type {
    margin-left: 0;
}

.contacts-content__email:last-of-type {
    margin-right: 0;
}

@media only screen and (max-width: 1023px) {
    .contacts-content__email {
        margin: 9px 0;
    }

    .contacts-content__email:first-of-type {
        margin-top: 0;
    }

    .contacts-content__email:last-of-type {
        margin-bottom: 0;
    }

    .contacts-content__email::before {
        display: none;
    }
}

.contacts-info {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 60px;
}

@media only screen and (max-width: 1249px) {
    .contacts-info {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .contacts-info {
        margin: 0 auto 35px;
    }
}

.contacts-info__main-title {
    font-size: 26px;

    color: #333;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.contacts-info__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 1023px) {
    .contacts-info__list {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.contacts-info__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 0 40px;
}

.contacts-info__item:first-of-type {
    margin-left: 0;
}

.contacts-info__item:last-of-type {
    margin-right: 0;
}

.contacts-info__item::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -40px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 47px;
    background: #e1e6e9;
}

.contacts-info__item:last-child::after {
    display: none;
}

@media only screen and (max-width: 1249px) {
    .contacts-info__item {
        margin: 0 30px;
    }
}

@media only screen and (max-width: 1023px) {
    .contacts-info__item {
        max-width: 320px;
        width: 100%;
        margin: 20px 0;
    }

    .contacts-info__item:first-of-type {
        margin-top: 0;
    }

    .contacts-info__item:last-of-type {
        margin-bottom: 0;
    }

    .contacts-info__item::after {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .contacts-info__item {
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.contacts-info__icon {
    padding-top: 5px;
    margin-right: 18px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
    .contacts-info__icon {
        display: none;
    }
}

.contacts-info__icon svg {
    fill: #9fa9af;
    width: 26px;
    height: 26px;
}

.contacts-info__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
    .contacts-info__text {
        text-align: center;
    }
}

.contacts-info__value {
    font-size: 14px;

    color: #333;
}

.contacts-info__value a {
    display: block;
    font-size: 14px;

    color: #333;
    text-decoration: none;
}

.contacts-info__value small {
    font-size: 12px;
    font-weight: 400;
    color: #333;
    text-transform: uppercase;
}

.contacts-info__value .phones-list__current a {
    font-size: 18px;
}

.contacts-info__value .phones-list__dropdown li a {
    font-size: 13px;
    padding: 10px 15px;
}

@media only screen and (max-width: 767px) {
    .contacts-info__value {
        text-align: center;
        max-width: 180px;
    }
}

.contacts-form {
    background: #f0f3f4;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 125px;
}

@media only screen and (max-width: 1023px) {
    .contacts-form {
        background: transparent;
        margin: 0 auto 80px;
    }
}

@media only screen and (max-width: 767px) {
    .contacts-form {
        padding: 0 15px;
    }
}

.contacts-form__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 1023px) {
    .contacts-form__inner {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.contacts-form__image-box {
    max-width: 694px;
    width: 100%;
    -ms-flex-preferred-size: 694px;
    flex-basis: 694px;
    padding: 42px 102px 32px;
}

@media only screen and (max-width: 1249px) {
    .contacts-form__image-box {
        padding: 42px 70px 32px;
    }
}

@media only screen and (max-width: 1023px) {
    .contacts-form__image-box {
        display: none;
    }
}

.contacts-form__image {
    position: relative;
    border: 1px solid #c8ced2;
}

.contacts-form__image img {
    margin-top: -130px;
}

.contacts-form__name {
    text-align: center;
    font-size: 16px;

    color: #333;
    margin-bottom: 8px;
    margin-top: 3%;
}

.contacts-form__position {
    text-align: center;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.contacts-form__content {
    max-width: 765px;
    width: 100%;
    -ms-flex-preferred-size: 765px;
    flex-basis: 765px;
    padding: 20px 60px;
}

@media only screen and (max-width: 1249px) {
    .contacts-form__content {
        padding: 20px;
    }
}

@media only screen and (max-width: 1023px) {
    .contacts-form__content {
        padding: 0;
        max-width: 375px;
        -ms-flex-preferred-size: 375px;
        flex-basis: 375px;
    }
}

.contacts-form__form {
    max-width: 402px;
    width: 100%;
    -ms-flex-preferred-size: 402px;
    flex-basis: 402px;
}

.contacts-form__form .form-block {
    margin-bottom: 26px;
}

.contacts-form__form .form-block input {
    height: 42px;
}

.contacts-form__form .submit-btn {
    font-family: 'Open Sans', serif;
    max-width: 172px;
    height: 45px;
    margin: 0 auto;
}

.contacts-form__form-title {
    font-size: 26px;

    color: #333;
    text-align: center;
    margin-bottom: 15px;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .contacts-form__form-title {
        font-size: 14px;
        margin-bottom: 8px;
    }
}

.contacts-form__form-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
    .contacts-form__form-text {
        margin-bottom: 15px;
    }
}

.method-block {
    max-width: 1192px;
    width: 100%;
    margin: 0 auto 80px;
}

@media only screen and (max-width: 1249px) {
    .method-block {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 540px) {
    .method-block {
        padding: 0 10px;
    }
}

.method-block__main-title {
    font-size: 18px;

    text-transform: uppercase;
    color: #333;
    margin: 0 auto 20px;
    text-align: center;
}

.method-block__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

@media only screen and (max-width: 540px) {
    .method-block__list {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.method-block__item {
    max-width: 290px;
    width: 100%;
    -ms-flex-preferred-size: 290px;
    flex-basis: 290px;
    border: 1px solid #e1e6e9;
    padding: 50px 35px 24px;
    min-height: 372px;
    margin-left: -1px;
    margin-top: -1px;
}

@media only screen and (max-width: 1249px) {
    .method-block__item {
        max-width: 33.333%;
        -ms-flex-preferred-size: 33.333%;
        flex-basis: 33.333%;
    }
}

@media only screen and (max-width: 1023px) {
    .method-block__item {
        padding: 50px 15px 24px;
    }
}

@media only screen and (max-width: 767px) {
    .method-block__item {
        max-width: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    }
}

@media only screen and (max-width: 540px) {
    .method-block__item {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        min-height: auto;
        padding: 20px 25px 18px;
    }
}

.method-block__image {
    height: 130px;
    margin-bottom: 25px;
    text-align: center;
}

.method-block__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 137px;
    min-height: 138px;
}

@media only screen and (max-width: 540px) {
    .method-block__info {
        min-height: auto;
    }
}

.method-block__title {
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;

    text-transform: uppercase;
    color: #333;
    margin-bottom: 8px;
}

.method-block__title span {
    display: block;
    font-size: 14px;
    color: #6d7980;
    text-transform: none;
    font-weight: 400;
}

.method-block__text {
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
    font-size: 13px;
    color: #333;
    text-align: center;
}

@media only screen and (max-width: 540px) {
    .method-block__text {
        margin-bottom: 15px;
    }
}

.method-block__text-full {
    display: none;
}

.method-block__link {
    cursor: pointer;
    margin-top: auto;
    font-size: 13px;

    color: #333;
    padding-bottom: 2px;
    border-bottom: 1px dotted #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .method-block__link:hover {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }
}

.error-block {
    padding: 85px 0 125px;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
}

@media only screen and (max-width: 1599px) {
    .error-block {
        padding: 85px 15px 125px;
    }
}

@media only screen and (max-width: 1449px) {
    .error-block {
        padding: 25px 15px 100px;
    }
}

@media only screen and (max-width: 1249px) {
    .error-block {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 65px 15px 80px;
    }
}

@media only screen and (max-width: 767px) {
    .error-block {
        padding: 30px 15px 55px;
    }
}

.error-block__image {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -115px;
}

@media only screen and (max-width: 1599px) {
    .error-block__image {
        left: -60px;
        width: 50%;
    }
}

@media only screen and (max-width: 1459px) {
    .error-block__image {
        left: -20px;
    }
}

@media only screen and (max-width: 1249px) {
    .error-block__image {
        display: none;
    }
}

.error-block__content-image {
    margin-bottom: 35px;
}

.error-block__content {
    max-width: 1222px;
    width: 100%;
    border: 1px solid #d1d5d8;
    padding: 75px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-right: 145px;
}

@media only screen and (max-width: 1459px) {
    .error-block__content {
        max-width: 1050px;
        padding: 50px 70px 50px 0;
    }
}

@media only screen and (max-width: 1249px) {
    .error-block__content {
        border: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0;
        text-align: center;
    }

    .error-block__content .submit-btn {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 767px) {
    .error-block__content {
        padding: 0 20px;
    }
}

.error-block__content .submit-btn {
    max-width: 150px;
}

.error-block__title {
    font-size: 26px;

    color: #53595d;
    text-transform: uppercase;
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .error-block__title {
        font-size: 18px;
    }
}

.error-block__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 25px;
}

.faq-block {
    max-width: 1330px;
    width: 100%;
    margin: 0 auto 125px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1450px) {
    .faq-block {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) {
    .faq-block {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media only screen and (max-width: 767px) {
    .faq-block {
        padding: 0 10px;
        margin: 0 auto 50px;
    }
}

.faq-block__main {
    max-width: 820px;
    width: 100%;
    -ms-flex-preferred-size: 820px;
    flex-basis: 820px;
    border: 1px solid #e1e6e9;
    padding: 50px 100px 50px 55px;
}

@media only screen and (max-width: 1023px) {
    .faq-block__main {
        margin: 0 auto 10px;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        padding: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .faq-block__main {
        border: none;
        padding: 0;
    }
}

.faq-block__main-title {
    font-size: 18px;

    color: #333;
    text-transform: uppercase;
    margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
    .faq-block__main-title {
        font-size: 14px;
    }
}

.faq-block__item {
    margin-bottom: 20px;
}

.faq-block__item.active .faq-block__title {

    color: #333;
}

.faq-block__title {
    cursor: pointer;
    display: table;
    font-size: 14px;
    color: #6a777f;
    text-decoration: underline;
    -webkit-text-decoration-style: dotted;
    text-decoration-style: dotted;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.faq-block__inner {
    height: 0;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.faq-block__content {
    padding-top: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: #333;
}

.faq-block__box {
    margin-bottom: 30px;
}

.faq-block__sidebar {
    max-width: 480px;
    width: 100%;
    -ms-flex-preferred-size: 480px;
    flex-basis: 480px;
    margin-left: 20px;
}

@media only screen and (max-width: 1249px) {
    .faq-block__sidebar {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 380px;
    }
}

@media only screen and (max-width: 1023px) {
    .faq-block__sidebar {
        max-width: 480px;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        margin: 0 auto;
    }
}

.faq-form {
    padding: 40px;
    background: #fff;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 30px;
}

@media only screen and (max-width: 1023px) {
    .faq-form {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

@media only screen and (max-width: 767px) {
    .faq-form {
        padding: 0 0 0 18px;
    }
}

.faq-form__title {

    font-size: 26px;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .faq-form__title {
        font-size: 14px;
        margin-bottom: 16px;
    }
}

.faq-form__form .form-block {
    margin-bottom: 25px;
}

.faq-form__form .form-block .main-input {
    height: 42px;
}

.faq-form__form .form-block--required {
    position: relative;
}

.faq-form__form .form-block--required::before {
    content: '*';
    position: absolute;
    left: -18px;
    top: 14px;
    font-size: 25px;
    font-family: 'Open Sans', sans-serif;
    color: #ec4a9b;
}

.faq-form__form .submit-btn {
    font-family: 'Open Sans', serif;
    margin: 0 auto;
    max-width: 172px;
    height: 46px;
    background: #9baab3;
}

@media only screen and (min-width: 1025px) {
    .faq-form__form .submit-btn:hover {
        background: #ec4a9b;
    }
}

.send-block__title {
    font-size: 14px;
    color: #768187;
    text-align: center;
    margin-bottom: 15px;
}

.send-block__list {
    position: relative;
    padding: 14px 0;
    border-top: 1px solid #e1e6e9;
    border-bottom: 1px solid #e1e6e9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 580px) {
    .send-block__list {
        padding: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.send-block__list::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #e1e6e9;
    height: 40px;
    width: 1px;
}

@media only screen and (max-width: 580px) {
    .send-block__list::before {
        display: none;
    }
}

.send-block__item {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 580px) {
    .send-block__item {
        width: 100%;
        padding: 15px 0;
    }

    .send-block__item:first-of-type {
        border-bottom: 1px solid #e1e6e9;
    }
}

.send-block__icon {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 15px;
}

.send-block__icon svg {
    fill: #778b96;
    width: 23px;
    height: 17px;
}

.send-block__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #778b96;
    margin-bottom: 2px;
}

.send-block__email {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    font-size: 14px;

    color: #3b474d;
    text-decoration: none;
}

@media only screen and (min-width: 1025px) {
    .send-block__email:hover {
        color: #ec4a9b;
    }
}

.footer {
    background: #fff;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(47, 74, 90, 0.09);
    box-shadow: 0px 0px 40px 0px rgba(47, 74, 90, 0.09);
}

.footer__top {
    padding: 18px 0;
    border-bottom: 2px solid #8b979e;
}

.footer__inner {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 50px 0 40px;
}

@media only screen and (max-width: 1249px) {
    .footer__inner {
        padding: 50px 15px 40px;
    }
}

@media only screen and (max-width: 767px) {
    .footer__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 20px 15px 15px;
    }
}

.footer__left {
    max-width: 200px;
    width: 100%;
    -ms-flex-preferred-size: 200px;
    flex-basis: 200px;
}

.cookie-content__link {
    text-decoration-line: underline;
}

.cookie-pop-up {
    height: 80px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 98;
}

.cookie-pop-up.cookie-closed {
    display: none;
}

.cookie-content {
    max-width: 825px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
}

.cookie-content__info {
    color: white;
    font-size: 13px;
    line-height: 15px;
    font-family: 'Open Sans', serif;
    /*display: inline-block;*/
}

.cookie-content__link {
    color: #1bd1ff;
    margin-left: 3px;
}

.cookie-content__button {
    color: white;
    border: 1px solid white;
    padding: 5px 2px;
    max-width: 80px;
    width: 100%;
    background-color: transparent;
    outline: none;
    font-family: 'Open Sans', serif;
    cursor: pointer;
}

.b24-widget-button-show {
    animation: show linear forwards !important;
}

.b24-widget-button-hide {
    animation: hidden linear forwards !important;
}

@media only screen and (max-width: 1023px) {
    .footer__left {
        display: none;
    }

    .cookie-content {
        /*align-items: flex-end;*/
        /*padding: 10px 20px 40px;*/
        height: auto;
        padding: 10px 20px;
    }


    .cookie-content__info {
        /*max-width: 250px;*/
        max-width: calc(100% - 100px);
        padding: 0;
        margin: 0;
    }

    .cookie-pop-up {
        /*max-height: 100px;*/
        display: flex;
        height: 128px;
    }

    .cookie-content__button {
        /*align-self: flex-end;*/
    }

    .b24-widget-button-position-bottom-right {
        bottom: 95px !important;
    }
}

@media only screen and (max-width: 767px) {
    .footer__left {
        display: block;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .b24-widget-button-position-bottom-right {
        right: 25px !important;
    }
}

.footer__left .logo {
    width: 100%;
    margin-bottom: 45px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}


@media only screen and (max-width: 767px) {
    .footer__left .logo {
        display: none;
    }
}

.footer__middle {
    max-width: 455px;
    width: 100%;
    -ms-flex-preferred-size: 455px;
    flex-basis: 455px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 767px) {
    .footer__middle {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.footer__right {
    max-width: 320px;
    width: 100%;
    -ms-flex-preferred-size: 320px;
    flex-basis: 320px;
}

@media only screen and (max-width: 767px) {
    .footer__right {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.footer__bottom {
    border-top: 1px solid #e1e6e9;
}

.footer__bottom .footer__inner {
    padding: 19px 0;
}

@media only screen and (max-width: 1249px) {
    .footer__bottom .footer__inner {
        padding: 19px 15px;
    }
}

@media only screen and (max-width: 767px) {
    .footer__bottom .footer__inner {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.subscribe {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1249px) {
    .subscribe {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) {
    .subscribe {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.subscribe__title {
    max-width: 180px;
    width: 100%;
    font-size: 12px;

    text-transform: uppercase;
    color: #889197;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.subscribe__title svg {
    width: 26px;
    height: 22px;
    fill: #8b979e;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 15px;
}

@media only screen and (max-width: 1023px) {
    .subscribe__title {
        max-width: 100%;
        margin: 0 auto 10px;
    }
}

@media only screen and (max-width: 767px) {
    .subscribe__title svg {
        width: 16px;
        height: 14px;
        margin-right: 10px;
    }
}

.subscribe__form {
    max-width: 900px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .subscribe__form {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.subscribe__form .form-block {
    max-width: 310px;
    width: 100%;
    margin: 0 10px;
}

.subscribe__form .form-block:first-of-type {
    margin-left: 0;
}

.subscribe__form .form-block:last-of-type {
    margin-right: 0;
}

@media only screen and (max-width: 767px) {
    .subscribe__form .form-block {
        margin: 0 auto 10px;
    }

    .subscribe__form .form-block:first-of-type {
        margin-left: auto;
    }

    .subscribe__form .form-block:last-of-type {
        margin-right: auto;
    }
}

.subscribe__form .submit-btn {
    font-family: 'Open Sans', serif;
    margin-left: 18px;
}

@media only screen and (max-width: 767px) {
    .subscribe__form .submit-btn {
        margin: 10px auto 0;
        max-width: 310px;
    }
}

.socials__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .socials__list {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.socials__item {
    margin: 0 6px;
}

.socials__item:first-of-type {
    margin-left: 0;
}

.socials__item:last-of-type {
    margin-right: 0;
}

.socials__item svg {
    fill: #8b979e;
    width: 17px;
    height: 17px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .socials__item:hover svg {
        fill: #ec4a9b;
    }
}

.footer-subtitle {

    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .footer-subtitle {
        text-align: center;
    }
}

.footer-nav {
    max-width: 200px;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .footer-nav {
        max-width: 100%;
        margin-bottom: 20px;
    }
}

.footer-nav__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 767px) {
    .footer-nav__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.footer-nav__item {
    font-size: 13px;
    margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
    .footer-nav__item {
        margin: 0 7px 5px;
    }
}

.footer-nav__item a {
    color: #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    text-decoration: none;
}

@media only screen and (min-width: 1025px) {
    .footer-nav__item a:hover {
        color: #ec4a9b;
    }
}

@media only screen and (max-width: 767px) {
    .footer-contacts {
        max-width: 190px;
        margin: 0 auto 30px;
    }
}

.footer-contacts__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 22px;
}

.footer-contacts__icon svg {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 15px;
    width: 22px;
    height: 22px;
    fill: #333;
}

.footer-contacts__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #333;
    margin-bottom: 5px;
}

.footer-contacts__value {
    color: #333;
    font-size: 14px;
}

.footer-contacts__value strong {
    color: #333;
}

.footer-contacts__value a {
    font-size: 14px;

    text-decoration: none;
    color: #333;
}

.footer-contacts__value .phones-list {
    margin-bottom: 0;
}

.footer-contacts__value .phones-list__dropdown {
    left: -20px;
    right: -45px;
    top: 17px;
}

.devs {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
}

.devs__icon-box {
    width: 80px;
    height: 12px;
    position: relative;
    overflow: hidden;
    margin-left: 10px;
}

.devs__icon {
    position: relative;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 100%;
    height: 100%;
}

.devs__icon svg {
    fill: #333;
}

.devs__icon-hover {
    position: relative;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
}

.devs__icon-hover svg {
    fill: #8b979e;
}

@media only screen and (min-width: 1025px) {
    .devs:hover {
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }

    .devs:hover .devs__icon {
        -webkit-transform: translate(0, -100%);
        transform: translate(0, -100%);
    }

    .devs:hover .devs__icon-hover {
        -webkit-transform: translate(0, -100%);
        transform: translate(0, -100%);
    }
}

@media only screen and (max-width: 767px) {
    .devs {
        flex-direction: column;

    }

    .devs a {
        display: inline;
        width: 100%;
        margin-bottom: 5px;
        margin-left: 5px;
        margin-right: auto;
    }

    .footer__bottom .footer__inner {
        display: inline;
    }

    .devs__icon-box {
        margin-left: 5px;
        margin-right: auto;
        left: 50%;
        bottom: 14px;
    }

    /* .devs:nth-child(2){
         display: flex;
         flex-wrap: wrap;
     }*/
}

.copyright {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #333;
}

.login-block {
    max-width: 402px;
    width: 100%;
    margin: 0 auto 125px;
}

@media only screen and (max-width: 767px) {
    .login-block {
        padding: 0 15px;
        margin: 0 auto 65px;
    }
}

.login-block__form .form-block {
    margin-bottom: 25px;
}

.login-block__form .form-block input {
    height: 42px;
}

.login-block__form .form-block__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.login-block__form .submit-btn {
    font-family: 'Open Sans', serif;
    max-width: 220px;
    height: 46px;
    margin: 40px auto 0;
}

@media only screen and (max-width: 540px) {
    .login-block__form .submit-btn {
        max-width: 100%;
    }
}

.login-block__form.step-2 .form-block {
    margin-bottom: 0;
}

.login-block__form.step-2 .form-block label {
    display: block;
    font-size: 14px;
    color: #8b979e;
    margin-bottom: 12px;
}

.login-block__either {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 30px 0;
}

.login-block__either span {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 16px;
    text-align: center;

    color: #333;
    padding: 0 12px;
    text-transform: uppercase;
}

.login-block__either::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #e1e6e9;
}

.login-block__either::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #e1e6e9;
}

.login-block .socials-login {
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.login-block .socials-login__list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.login-block__info {
    text-align: center;
    margin-bottom: 30px;
}

.login-block__image {
    margin-bottom: 20px;
}

.login-block__text {
    font-size: 14px;
    color: #7d8488;
    margin-bottom: 4px;
}

.login-block__phone {
    font-size: 18px;

}

.phone-info {
    position: relative;
    margin-top: 8px;
}

.phone-info__text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    color: #8b979e;
}

.phone-info__text > span {
    cursor: pointer;
    margin-left: 12px;
}

.phone-info__text > span svg {
    width: 15px;
    height: 15px;
    fill: #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.phone-info__text > span.active svg {
    fill: #ec4a9b;
}

@media only screen and (max-width: 767px) {
    .phone-info__text {
        text-align: center;
    }

    .phone-info__text span {
        display: none;
    }
}

.phone-info__popup {
    position: absolute;
    background: #fff;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 10px;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    line-height: 1.6;
    color: #8b979e;
    padding: 22px 20px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.phone-info__popup::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: #fff;
    top: -8px;
    right: 13px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-box-shadow: -10px -12px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -10px -12px 20px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 767px) {
    .phone-info__popup::before {
        display: none;
    }
}

.phone-info__popup.open {
    visibility: visible;
    opacity: 1;
    overflow: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.password-recovery {
    max-width: 402px;
    width: 100%;
    margin: 0 auto 140px;
}

@media only screen and (max-width: 439px) {
    .password-recovery {
        padding: 0 15px;
        margin: 0 auto 70px;
    }
}

.password-recovery__title {
    font-size: 18px;

    color: #333;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto 22px;
}

.password-recovery__form .form-block {
    margin-bottom: 25px;
}

.password-recovery__form .submit-btn {
    margin: 0 auto;
    max-width: 218px;
}

.header.fixed {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-bottom: 1px solid #e1e6e9;
}

.header.fixed .header__inner {
    height: 93px;
}

.header.fixed .header__inner .header__left .catalog-opener {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 30px;
}

@media only screen and (max-width: 1350px) {
    .header.fixed .header__inner .header__left .catalog-opener {
        margin-right: 20px;
    }
}

.header.fixed .header__inner .header__left .main-menu--fixed {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    width: auto;
    padding-right: 0;
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu--fixed-opener {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu--fixed-opener svg {
    width: 18px;
    height: 13px;
    fill: #000;
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu__fixed-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 400px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 5;
    height: 100vh;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.08);
    margin-top: 0;
    padding-top: 93px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu__fixed-wrap.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu__fixed-wrap .main-menu__close {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu__fixed-wrap .main-menu__close svg {
    width: 14px;
    height: 14px;
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu__fixed-wrap .main-menu__list {
    display: block;
    width: 100%;
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu__fixed-wrap .main-menu__list li {
    margin: 0;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid #dcdcdc;
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu__fixed-wrap .main-menu__list li:first-child {
    border-top: 1px solid #dcdcdc;
}

.header.fixed .header__inner .header__left .main-menu--fixed .main-menu__fixed-wrap .main-menu__list li a {
    color: #333;
    display: block;
    padding: 16px 20px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .header.fixed .header__inner .header__left .main-menu--fixed .main-menu__fixed-wrap .main-menu__list li a:hover {
        color: #ec4a9b;
    }
}

.header.fixed .header__inner .header__left .phones {
    margin-right: 30px;
}

.header.fixed .header__inner .header__left .phones__icon {
    display: none;
}

.header.fixed .header__inner .header__left .language::before {
    display: none;
}

.header.fixed .logo img {
    height: 63px;
}

@media only screen and (max-width: 1023px) {
    .header {
        /*margin-bottom: 45px;*/
        display: flex;
        flex-direction: column;
    }
}

/*@media only screen and (max-width: 767px) {
    .header {
        margin-bottom: 10px;
    }
}*/

.header__inner {
    height: 122px;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (max-width: 1599px) {
    .header__inner {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) {
    .header__inner {
        height: auto;
        padding: 15px 15px 10px;
    }
}

.header__left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 33%;
}

.header__left .catalog-opener {
    display: none;
    height: 43px;
    width: 136px;
    cursor: pointer;
}

@media only screen and (max-width: 1350px) {
    .header__left .catalog-opener {
        height: 35px;
        width: 105px;
        margin-right: 20px;
    }
}

.header__left .catalog-opener .catalog-opener__btn {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}


.header__left .catalog-opener .catalog-opener__btn svg {
    display: none;
}

.header__left .main-menu--fixed,
.header__left .main-menu--fixed-opener {
    display: none;
}

.header__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 33%;
}

.header__bottom {
    padding: 0 15px;
}

.logo {
    width: 33%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 480px) {
    .logo {
        height: 54px;
    }
}

.phones {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 80px;
}

@media only screen and (max-width: 1023px) {
    .phones {
        display: none;
    }
}

.phones__icon {
    -ms-flex-preferred-size: 1;
    flex-basis: 1;
    margin-right: 16px;
}

.phones__icon svg {
    fill: #333;
    width: 22px;
    height: 22px;
}

.phones-list {
    margin-bottom: 5px;
    position: relative;
}

.phones-list__current {
    position: relative;
    font-size: 15px;

}

.phones-list__current::after {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: -12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.phones-list__current a {
    color: #333;
    text-decoration: none;
}

.phones-list__current.active::after {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.phones-list__dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    width: 200px;
    position: absolute;
    left: -20px;
    right: -40px;
    top: 22px;
    -webkit-box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.15);
    box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.15);
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.phones-list__dropdown.open {
    visibility: visible;
    opacity: 1;
    overflow: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.phones-list__dropdown li a {
    padding: 10px 20px;
    display: block;
    
    font-size: 14px;
    color: #333;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .phones-list__dropdown li a:hover {
        color: #ec4a9b;
    }
}

.callback {
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    font-size: 12px;
    color: #333;
    border-bottom: 1px dotted #333;
}

.language {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.language::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 24px;
    background: #e1e6e9;
    left: -50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.language__title {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #999;
    margin-right: 12px;
}

.language__current {
    position: relative;
    font-size: 13px;
    color: #333;
    cursor: pointer;
}

.language__current::after {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: -12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.language__current.active::after {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.language__dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    position: absolute;
    padding: 10px 0;
    right: -20px;
    top: 16px;
    -webkit-box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.15);
    box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.15);
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.language__dropdown.open {
    z-index: 3;
    visibility: visible;
    opacity: 1;
    overflow: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.language__dropdown li a {
    padding: 10px 20px;
    display: block;
    font-size: 13px;
    color: #333;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (max-width: 1023px) {
    .language__title {
        display: none;
    }

    .language::before {
        content: unset;
    }

    .language__current,
    .language__dropdown li a {
        font-size: 16px;
    }

    .language__dropdown.open li a {
        padding: 10px 0;
        display: block;
        width: 40px;
        text-align: center;
    }

    .language__dropdown {
        padding: unset;
        right: -9px;
    }
}

@media only screen and (min-width: 1025px) {
    .language__dropdown li a:hover {
        color: #ec4a9b;
    }
}

.search::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 1px;
    width: 0;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background: #333;
}

@media only screen and (max-width: 1023px) {
    .search {
        display: none;
    }
}

.search__button {
    cursor: pointer;
    background: transparent;
    border: none;
}

.search__button svg {
    fill: #333;
    width: 14px;
    height: 14px;
}

.search__input {
    height: 23px;
    max-width: 100px;
    width: 100%;
    padding: 0 15px;
    font-size: 12px;
    color: #333;
    border: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.search__input::-webkit-input-placeholder {
    color: #333;
}

.search__input::-moz-placeholder {
    color: #333;
}

.search__input::-ms-input-placeholder {
    color: #333;
}

.search__input::placeholder {
    color: #333;
}

.search.open {
    max-width: 65%;
}

.search.open::after {
    width: 100%;
}

.search.open .search__input {
    max-width: 295px;
}

.main-menu__inner .search.open .search__input {
    max-width: 100%;
    width: 1211px;
}

.header-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-links__item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    width: 43px;
    height: 43px;
}

.header-links__item::before {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: '';
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background: #f2f2f2;
    border: 1px solid #e2e2e2;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.header-links__item_mobile_search{
    display: none;
}

@media only screen and (max-width: 1023px) {
    .header-links__item:not(.header-links__item_mobile_search) {
        display: none;
    }

    .header-links__item_mobile_search{
        display: flex;
    }
}

.header-links__item svg {
    fill: #333;
    width: 20px;
    height: 20px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.header-links__item .quantity, .header-links__item .wishlist-quantity {
    position: absolute;
    top: 0;
    right: 0;
    background: #ec4a9b;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: none;
}

.header-links__item.has-quantity svg {
    fill: #333;
}

.header-links__item.has-quantity .quantity, .header-links__item.has-quantity .wishlist-quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.header-links__item--login svg {
    width: 20px;
    height: 23px;
}

.header-links__item--compare svg {
    width: 24px;
    height: 24px;
}

.header-links__item--wishlist svg {
    width: 23px;
    height: 20px;
}

.header-links__item--basket svg {
    width: 16px;
    height: 22px;
}

@media only screen and (min-width: 1025px) {
    .header-links__item:hover::before {
        visibility: visible;
        opacity: 1;
        overflow: visible;
    }

    .header-links__item:hover svg {
        fill: #333;
    }
}

.header-links__item.active::before {
    visibility: visible;
    opacity: 1;
    overflow: visible;
}

.header-links__item.active svg {
    fill: #333;
}

.main-menu {
    width: 100%;
    padding-right: 206px;
}

.main-menu__fixed-wrap .main-menu__close {
    display: none;
}

.main-menu__wrap {
    border-top: 1px solid #e1e6e9;
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 1023px) {
    .main-menu__wrap {
        display: none;
    }
}

.main-menu__inner {
    position: relative;
    height: 52px;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


@media only screen and (max-width: 1599px) {
    .main-menu__inner {
        padding: 0 15px;
    }
}


.main-menu__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
}

.main-menu__item {
    margin: 0 34px;
    font-size: 14px;
}

@media only screen and (max-width: 1599px) {
    .main-menu__item {
        margin: 0 15px;
    }

}

.main-menu__item:last-of-type {
    margin-right: 0;
}

.main-menu__item a {
    color: #333;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .main-menu__item a:hover {
        color: #ec4a9b;
    }
}

.main-menu__item--catalog {
    max-width: 206px;
    width: 100%;
    background: #000;
    
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.main-menu__item--catalog a {
    color: #fff;
}

.main-menu__item.active a {
    color: #ec4a9b;
}

@media only screen and (max-width: 1200px) {
    .main-menu {
        padding-right: 95px;
    }
}

.catalog-opener {
    max-width: 206px;
    width: 100%;
    height: 100%;
    /*position: relative;*/
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.catalog-opener__btn {
    width: 100%;
    height: 100%;
    background: #000;
    font-size: 14px;
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    margin-right: 86px;
}

@media only screen and (max-width: 860px) {
    .catalog-opener__btn {
        background: #fff;
        color: #333;
        border-bottom: 1px solid #dcdcdc;
    }
}

.catalog-opener__btn svg {
    fill: #fff;
    width: 14px;
    height: 10px;
    margin-right: 16px;
}

@media only screen and (max-width: 860px) {
    .catalog-opener__btn svg {
        fill: #000;
        display: none;
    }
}

.catalog-opener__btn.active {
    /*  background: #3f494e;*/
}

.catalog__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    background: #fff;
    z-index: 4;
    -webkit-box-shadow: 0px 0px 40px 0px rgba(47, 74, 90, 0.29);
    box-shadow: 0px 0px 40px 0px rgba(47, 74, 90, 0.29);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
}

@media only screen and (max-width: 1023px) {
    .catalog__dropdown {
        width: 100%;
        top: -55px;
        display: block;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .catalog__dropdown {
        width: 600px;
        background: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.catalog__dropdown.active {
    visibility: visible;
    opacity: 1;
    overflow: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.catalog__list {
    margin: 0;
    padding: 20px 0;
    list-style: none;
    background: #fff;
    width: 300px;
}

@media only screen and (max-width: 1023px) {
    .catalog__list {
        width: 100%;
        padding: 0;
    }
}

.catalog__list li {
    position: relative;
    font-size: 14px;
}

.catalog__list li a {
    position: relative;
    color: #333;
    text-decoration: none;
    display: block;
    padding: 13px 45px 13px 30px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .catalog__list li a:hover {
        background: #333;
        color: #fff;
    }
}

.catalog__list li a.has-child::after {
    border-bottom: 1px solid #b6b6b6;
    border-right: 1px solid #b6b6b6;
    content: '';
    display: block;
    height: 6px;
    width: 6px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.catalog__list li a.main-cat {
    position: relative;
    background: #000;
    height: 56px;
    color: #fff;
    font-size: 14px;
    text-transform: none;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.catalog__list li a.main-cat::before {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    content: '';
    display: block;
    height: 7px;
    width: 7px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.catalog__list li.main-cat a {
    position: relative;
    background: #000;
    height: 56px;
    color: #fff;
    font-size: 14px;
    text-transform: none;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.catalog__list li.main-cat a::before {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    content: '';
    display: block;
    height: 7px;
    width: 7px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.catalog__list li.main-cat a::after {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .catalog__list li {
        text-transform: uppercase;
        font-size: 12px;
        border-bottom: 1px solid #dcdcdc;
    }
}

.catalog__first-level {
    height: 816px;
    overflow: hidden;
}

@media only screen and (max-width: 1023px) {
    .catalog__first-level {
        width: 100%;
        height: 100vh;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .catalog__first-level {
        -webkit-box-shadow: 0px 0px 20px 0px rgba(47, 74, 90, 0.29);
        box-shadow: 0px 0px 20px 0px rgba(47, 74, 90, 0.29);
        background: #fff;
    }
}

.catalog__first-level .mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.catalog__first-level .mCSB_scrollTools {
    right: -6px;
}

.catalog__second-level {
    height: 816px;
    overflow: hidden;
    background: #fff;
    border-left: 1px solid #dadfe2;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .catalog__second-level {
        -webkit-box-shadow: 5px 0px 20px 0px rgba(47, 74, 90, 0.29);
        box-shadow: 5px 0px 20px 0px rgba(47, 74, 90, 0.29);
        border-left: none;
    }
}

@media only screen and (max-width: 1023px) {
    .catalog__second-level {
        width: 100%;
        height: 100vh;
    }
}

.catalog__second-level .mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.catalog__second-level .mCSB_scrollTools {
    right: -6px;
}

.catalog__third-level {
    height: 816px;
    overflow: hidden;
    background: #fff;
    border-left: 1px solid #dadfe2;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .catalog__third-level {
        -webkit-box-shadow: 5px 0px 20px 0px rgba(47, 74, 90, 0.29);
        box-shadow: 5px 0px 20px 0px rgba(47, 74, 90, 0.29);
        border-left: none;
    }
}

@media only screen and (max-width: 1023px) {
    .catalog__third-level {
        width: 100%;
        height: 100vh;
    }
}

.catalog__third-level .mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.catalog__third-level .mCSB_scrollTools {
    right: -6px;
}

.prev-step {
    position: relative;
    background: #000;
    height: 56px;
    color: #fff;
    font-size: 14px;
    text-transform: none;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: none;
}

.prev-step svg {
    width: 12px;
    height: 12px;
    fill: #fff;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.prev-step::before {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    content: '';
    display: block;
    height: 7px;
    width: 7px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

@media only screen and (max-width: 1023px) {
    .prev-step {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.mobile-menu {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .mobile-menu {
        display: block;
    }
}

.mobile-menu__open svg {
    width: 18px;
    height: 13px;
}

.mobile-menu__dropdown {
    max-width: 380px;
    width: 100%;
    position: absolute;
    left: -1px;
    top: 0;
    background: #fff;
    z-index: 29;
    height: 100vh;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.mobile-menu__dropdown.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.mobile-menu__main-menu .main-menu__list {
    margin-top: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.mobile-menu__main-menu .main-menu__list li {
    margin: 0;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid #dcdcdc;
}

.mobile-menu__main-menu .main-menu__list li a {
    color: #333;
    display: block;
    padding: 16px 20px;
}

.mobile-menu__catalog {
    height: 50px;
    position: relative;
}

.mobile-menu__catalog .catalog-opener__btn {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0 20px;
    text-transform: uppercase;
    display: block;
    padding: 16px 20px;
}

.mobile-menu__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #9da7ae;
    height: 55px;
}

.mobile-menu__top-item {
    width: 25%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

.mobile-menu__top-item svg {
    fill: #fff;
    width: 22px;
    height: 22px;
}

.mobile-menu__top-item::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1px;
    height: 11px;
    background: #ced3d7;
}

.mobile-menu__top-item:last-child {
    position: static;
}

.mobile-menu__top-item:last-child::after {
    display: none;
}

.mobile-menu__top-item--close svg {
    width: 18px;
    height: 18px;
}

.mobile-menu__top-item.active {
    background: #fff;
}

.mobile-menu__top-item.active svg {
    fill: #9da7ae;
}

.mobile-menu__bottom .callback {
    margin: 0 auto 15px;
    display: table;
}

.mobile-login {
    position: absolute;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    left: 0;
    top: 55px;
    margin: 0;
    width: 100%;
    background: #fff;
    z-index: 4;
    padding: 30px 28px 40px;
    -webkit-box-shadow: 0px 3px 40px 0px rgba(71, 82, 88, 0.17);
    box-shadow: 0px 3px 40px 0px rgba(71, 82, 88, 0.17);
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.mobile-login.open {
    visibility: visible;
    opacity: 1;
    overflow: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.mobile-phones {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.mobile-phones li {
    font-size: 13px;
    
    color: #000;
    margin: 0 8px 12px;
}

.mobile-phones li a {
    color: #333;
    text-decoration: none;
}

.mobile-languages {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.mobile-languages li {
    width: 48px;
    height: 24px;
    text-align: center;
    font-size: 13px;
    line-height: 24px;
    
    color: #333;
    border-left: 1px solid #e1e6e9;
}

.mobile-languages li:first-of-type {
    border-left: none;
}

.mobile-languages li a {
    color: #333;
    text-decoration: none;
}

.prev-btn {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .prev-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .prev-btn svg {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}

.all-category-btn {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .all-category-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}


.product-counter {
    position: absolute;
    left: 16px;
    top: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 3;
}

.product-counter__item {
    border-radius: 6px;
    background: #ec4a9b;
    width: 50px;
    height: 50px;
    margin: 0 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.product-counter__item:first-of-type {
    margin-left: 0;
}

.product-counter__item:last-of-type {
    margin-right: 0;
}

.product-counter__time {
    font-size: 26px;
    
    color: #fff;
}

.product-counter__text {
    font-size: 10px;
    
    color: #f2a8ab;
}

.lazyload, .lazyloading {
    opacity: 0;
    min-width: 100%;
}

.product-colors__item .lazyload, .product-colors__item .lazyloading {
    max-width: 26px;
    max-height: 26px;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}

.instagram-block {
    position: relative;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 75px;
}

@media only screen and (max-width: 1599px) {
    .instagram-block {
        padding: 0 15px;
    }
}

.instagram-block__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 -7px 20px;
}

.instagram-block__item {
    position: relative;
    max-width: calc(16.66666% - 12px);
    width: 100%;
    -ms-flex-preferred-size: calc(16.66666% - 12px);
    flex-basis: calc(16.66666% - 12px);
    margin: 0 6px 12px;
    overflow: hidden;
}

.instagram-block__item::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.instagram-block__item img {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;

}

@media only screen and (max-width: 1249px) {
    .instagram-block__item {
        max-width: calc(25% - 12px);
        -ms-flex-preferred-size: calc(25% - 12px);
        flex-basis: calc(25% - 12px);
    }
}

@media only screen and (max-width: 767px) {
    .instagram-block__item {
        max-width: calc(50% - 10px);
        -ms-flex-preferred-size: calc(50% - 10px);
        flex-basis: calc(50% - 10px);
        margin: 0 5px 10px;
    }
}

@media only screen and (min-width: 1025px) {
    .instagram-block__item:hover img {
        -webkit-transform: scale(1.08);
        transform: scale(1.08);

    }

    .instagram-block__item:hover::before {
        visibility: visible;
        opacity: 1;
        overflow: visible;
    }
}

.main-news {
    position: relative;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 95px;
}

@media only screen and (max-width: 1599px) {
    .main-news {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .main-news {
        margin: 0 auto 45px;
    }
}

.main-news__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -10px 30px;
}

.main-news__list .news__item {
    max-width: 355px;
    -ms-flex-preferred-size: 355px;
    flex-basis: 355px;
    margin: 0 10px 20px;
}

@media only screen and (max-width: 1599px) {
    .main-news__list .news__item {
        max-width: 23%;
        -ms-flex-preferred-size: 23%;
        flex-basis: 23%;
    }
}

@media only screen and (max-width: 1249px) {
    .main-news__list .news__item {
        max-width: 33%;
        -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
    }
}

@media only screen and (max-width: 1023px) {
    .main-news__list .news__item {
        max-width: 355px;
        -ms-flex-preferred-size: 355px;
        flex-basis: 355px;
    }
}

.main-content {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 95px;
}

@media only screen and (max-width: 1599px) {
    .main-content {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .main-content {
        margin: 0 auto 35px;
    }
}

.main-content__title {
    font-size: 14px;
    
    text-transform: uppercase;
    color: #333;
    margin-bottom: 10px;
}

.main-content__text {
    color: #333;
    line-height: 1.5;
    font-size: 14px;
    max-height: 160px;
    overflow: hidden;
    margin-bottom: 5px;
}

.main-content__text.active {
    max-height: unset;
}

.main-content__text.discount {
    color: #ec4a9b;
    text-align: center;
    border: #ec4a9b;
    border-style: solid;
    border-width: thin;
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
    width: 100%;
    font-size: 13px;
    margin-bottom: 15px;
    margin-top: 15px;
}

@media only screen and (max-width: 1023px) {
    .main-content__text.discount {
        padding: 0 30px;
    }
}

.read-more {
    cursor: pointer;
    display: none;
    font-size: 13px;
    color: #333;
    position: relative;
    padding-right: 15px;
}

.read-more::after {
    border-bottom: 1px solid #333;
    border-right: 1px solid #333;
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.read-more.open::after {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.news {
    position: relative;
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 75px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
    .news--single .news__sidebar-open {
        display: none;
    }
}

@media only screen and (max-width: 1599px) {
    .news {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) {
    .news {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.news__main {
    max-width: 1182px;
    width: 100%;
    -ms-flex-preferred-size: 1182px;
    flex-basis: 1182px;
    min-width: 0;
    margin-right: 20px;
}

@media only screen and (max-width: 1023px) {
    .news__main {
        margin: 0 auto;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.news__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -13px 35px;
    padding-bottom: 25px;
    border-bottom: 1px solid #e1e6e9;
}

@media only screen and (max-width: 1023px) {
    .news__list {
        margin: 0 0 35px;
    }
}

.news__item {
    max-width: 380px;
    width: 100%;
    -ms-flex-preferred-size: 380px;
    flex-basis: 380px;
    margin: 0 11px 35px;
}

@media only screen and (max-width: 1599px) {
    .news__item {
        max-width: 360px;
        -ms-flex-preferred-size: 360px;
        flex-basis: 360px;
    }
}

@media only screen and (max-width: 1449px) {
    .news__item {
        max-width: 31.8%;
        -ms-flex-preferred-size: 31.8%;
        flex-basis: 31.8%;
        margin: 0 6px 20px;
    }
}

@media only screen and (max-width: 1249px) {
    .news__item {
        max-width: 345px;
        -ms-flex-preferred-size: 345px;
        flex-basis: 345px;
    }
}

@media only screen and (max-width: 1023px) {
    .news__item {
        max-width: 352px;
        -ms-flex-preferred-size: 352px;
        flex-basis: 352px;
    }

    .news__item:last-child {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .news__item {
        margin: 0 0 16px;
    }

    .news__item:last-child {
        display: block;
    }
}

.news__image {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.news__image img {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;

}

@media only screen and (min-width: 1025px) {
    .news__image:hover img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);

    }
}

.news__date {
    max-width: 105px;
    width: 100%;
    height: 32px;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    color: #333;
}

@media only screen and (max-width: 767px) {
    .news__date {
        margin-bottom: 15px;
    }
}

.news__title {
    font-size: 15px;
    
    color: #333;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1449px) {
    .news__title {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .news__title {
        margin-bottom: 20px;
        position: relative;
        width: 100%;
        background: rgb(0, 0, 0);
        font-size: 13px;
        
        color: #fff;
        padding: 7px 30px;
        line-height: 1.2;
    }

    .news__list .news__title a {
        color: white;
    }

    .news__item .news__title a {
        color: white;
    }

    .news__image {
        margin-bottom: -27px;
    }

    .news__date {
        margin-bottom: 43px;
        height: 32px;
    }

    .news__text {
        display: none;
    }
}

.news__title a {
    text-decoration: none;
    color: #333;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .news__title a:hover {
        color: #ec4a9b;
    }
}

.news__text {
    font-size: 13px;
    color: #333;
    line-height: 1.5;
}

@media only screen and (max-width: 1449px) {
    .news__text {
        font-size: 12px;
    }
}

.news__sidebar {
    max-width: 280px;
    width: 100%;
    -ms-flex-preferred-size: 280px;
    flex-basis: 280px;
    min-width: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media only screen and (max-width: 1023px) {
    .news__sidebar {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 47px;
        max-width: 415px;
        width: 100%;
        height: 100%;
        background: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 0 50px;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }

    .news__sidebar.open {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

.news__nav {
    max-width: 280px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
}

@media only screen and (max-width: 1023px) {
    .news__nav {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.news__nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

@media only screen and (max-width: 1023px) {
    .news__nav-list {
        -webkit-box-shadow: none;
        box-shadow: none;
        padding-top: 20px;
        border-top: 1px solid #e1e6e9;
    }
}

.news__nav-item {
    position: relative;
    padding: 13px 10px;
    border-bottom: 1px solid #e1e6e9;
}

.news__nav-item::before {
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: '';
    width: 0;
    height: 2px;
    background: #9baab3;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.news__nav-item:last-of-type {
    border-bottom: none;
}

.news__nav-item a {
    font-size: 15px;
    color: #333;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.news__nav-item small {
    font-size: 12px;
    color: #333;
}

.news__nav-item.active::before {
    width: 30px;
}

.news__nav-item.active a {
    color: #333;
    
    padding-left: 38px;
}

.news__nav-item.active small {
    
    color: #333;
}

@media only screen and (min-width: 1025px) {
    .news__nav-item:hover::before {
        width: 30px;
    }

    .news__nav-item:hover a {
        padding-left: 38px;
    }
}

.news__sidebar-open {
    display: none;
    cursor: pointer;
}

.news__sidebar-open.active {
    background: #e6ebec;
    color: #333;
}

@media only screen and (max-width: 1023px) {
    .news__sidebar-open {
        -ms-flex-item-align: start;
        align-self: flex-start;
        background: #333;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        max-width: 180px;
        width: 100%;
        height: 36px;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 480px) {
    .news__sidebar-open {
        max-width: 100%;
    }
}

.latest-news {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 0 -13px;
}

@media only screen and (max-width: 1023px) {
    .latest-news {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 1023px) {
    .latest-news {
        margin: 0;
    }
}

.latest-news__item {
    text-decoration: none;
    display: block;
    max-width: 580px;
    width: 100%;
    -ms-flex-preferred-size: 580px;
    flex-basis: 580px;
    position: relative;
    margin: 0 12px 24px;
    overflow: hidden;
}

@media only screen and (max-width: 767px) {
    .latest-news__item {
        margin: 0 0 16px;
    }
}

.latest-news__item img {
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;

}

@media only screen and (min-width: 1025px) {
    .latest-news__item:hover img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);

    }
}

@media only screen and (max-width: 1023px) {
    .latest-news__item {
        max-width: 770px;
        width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .latest-news__item img {
        width: 100%;

    }
}

.latest-news__box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.latest-news__date {
    max-width: 105px;
    width: 100%;
    height: 32px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    color: #333;
}

@media only screen and (max-width: 767px) {
    .latest-news__date {
        margin-bottom: 15px;
    }
}

.latest-news__title {
    width: 100%;
    background: rgb(0, 0, 0);
    font-size: 15px;
    
    color: #fff;
    padding: 7px 30px;
    line-height: 1.2;
}

.latest-news__title span {
    display: block;
    max-width: 400px;
    width: 100%;
}

@media only screen and (max-width: 1249px) {
    .latest-news__title {
        font-size: 13px;
        padding: 5px 20px;
    }
}

.special-banner {
    position: relative;
    margin-bottom: 48px;
}

.special-banner__link {
    max-width: 128px;
    width: 100%;
    height: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 26px;
    text-decoration: none;
    font-size: 12px;
    
    text-transform: uppercase;
    color: #fff;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .special-banner__link:hover {
        opacity: .9;
    }
}

.blog-subscribe {
    max-width: 280px;
    width: 100%;
    position: relative;
    background: #f0f3f4;
    border: 1px solid #e1e6e9;
    padding: 65px 20px 35px;
}

@media only screen and (max-width: 1023px) {
    .blog-subscribe {
        display: none;
    }
}

.blog-subscribe__logo {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -20px;
}

.blog-subscribe__title {
    font-size: 16px;
    
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    padding: 0 60px;
}

.blog-subscribe__text {
    font-size: 14px;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
    padding: 0 15px;
}

.blog-subscribe__form .submit-btn {
    margin: 0;
    font-family: 'Open Sans', serif;
}

.blog-subscribe .form-block {
    margin-bottom: 16px;
}

.article__image {
    margin-bottom: 25px;
}

.article__image img {
    width: 100%;

}

.article__title {
    font-size: 26px;
    
    color: #333;
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .article__title {
        font-size: 22px;
    }
}

.article__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.article__tags {
    font-size: 14px;
    color: #333;
}

.article__date {
    position: relative;
    font-size: 12px;
    color: #333;
    margin-left: 30px;
}

.article__date::before {
    content: '';
    position: absolute;
    left: -14px;
    top: 2px;
    height: 10px;
    width: 1px;
    background: #e6e6e6;
}

.article__content {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #333;
    line-height: 1.4;
    margin-bottom: 30px;
}

.article__content ul {
    /* list-style: none;
  margin: 0;
  padding: 0; */
}

.article__content img {
    vertical-align: unset;

}

/*.article__content a {
    text-decoration: underline;
    color: unset;
    transition: unset;
    color: #0000FF;
}

.article__content a:hover {
    color: #0000FF;
}

.article__content a:visited {
    color: #800080;
}

.article__content a:active {
    color: #ec4a9b;
}*/

.share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 28px;
    border-top: 1px solid #e1e6e9;
}

.share__title {
    font-size: 12px;
    color: #333;
    margin-right: 25px;
}

.share .share-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.share .share-item {
    margin: 0 8px;
    cursor: pointer;
}

.share .share-item:first-of-type {
    margin-left: 0;
}

.share .share-item:last-of-type {
    margin-right: 0;
}

.share .share-item svg {
    width: 20px;
    height: 20px;
    fill: #b5bdc1;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .share .share-item:hover svg {
        fill: #ec4a9b;
    }
}

.social-likes__icon_facebook,
.social-likes__icon_pinterest,
.social-likes__icon_telegram {
    display: none;
}

.news-latest {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 120px;
}

@media only screen and (max-width: 767px) {
    .news-latest {
        padding: 0 15px;
        margin: 0 auto 60px;
    }
}

.news-latest__list .main-catalog__btn {
    top: 50%;
}

.news-latest__list .slick-dots {
    bottom: -20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.news-latest__list .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.news-latest__list .slick-dots li:first-of-type {
    margin-left: 0;
}

.news-latest__list .slick-dots li:last-of-type {
    margin-right: 0;
}

.news-latest__list .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.news-latest__list .slick-dots li button::before {
    display: none;
}

.news-latest__list .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

.partners-info {
    max-width: 1330px;
    width: 100%;
    margin: 0 auto 130px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.partners-info .read-more {
    display: none;
}

@media only screen and (max-width: 767px) {
    .partners-info .read-more {
        margin-top: 10px;
        display: table;
    }
}

@media only screen and (max-width: 1449px) {
    .partners-info {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 1023px) {
    .partners-info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0 auto 70px;
    }
}

@media only screen and (max-width: 767px) {
    .partners-info {
        padding: 0 10px;
        margin: 0 auto 40px;
    }
}

.partners-info__main {
    max-width: 806px;
    width: 100%;
    -ms-flex-preferred-size: 806px;
    flex-basis: 806px;
}

@media only screen and (max-width: 1023px) {
    .partners-info__main {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        padding-bottom: 25px;
        border-bottom: 1px solid #e1e6e9;
        margin-bottom: 45px;
    }
}

.partners-info__block {
    padding-bottom: 40px;
    margin-bottom: 50px;
    border-bottom: 1px solid #e1e6e9;
}

.partners-info__block:last-of-type {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .partners-info__block {
        padding-bottom: 18px;
        margin-bottom: 25px;
    }
}

.partners-info__title {
    font-size: 18px;
    
    color: #333;
    text-transform: uppercase;
    margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
    .partners-info__title {
        font-size: 14px;
    }
}

.partners-info__text {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    color: #333;
    line-height: 1.8;
}

@media only screen and (max-width: 767px) {
    .partners-info__text {
        max-height: 160px;
        overflow: hidden;
    }

    .partners-info__text.active {
        max-height: 9999px;
    }
}

.partners-info__text ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.partners-info__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.partners-info__item {
    max-width: 20%;
    width: 100%;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
    height: 65px;
    margin: 0 15px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .partners-info__item {
        max-width: 40%;
        -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
    }
}

.partners-info__contacts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 -16px;
}

@media only screen and (max-width: 767px) {
    .partners-info__contacts {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin: 0;
    }
}

.partners-info__contacts-item {
    margin: 0 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.partners-info__contacts-item a {
    font-size: 14px;
    
    color: #3b474d;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .partners-info__contacts-item a:hover {
        color: #ec4a9b;
    }
}

.partners-info__contacts-item svg {
    width: 16px;
    height: 16px;
    fill: #82949e;
    margin-right: 8px;
}

@media only screen and (max-width: 767px) {
    .partners-info__contacts-item {
        margin: 0 0 15px;
    }

    .partners-info__contacts-item:last-of-type {
        margin-bottom: 0;
    }
}

.partners-info__sidebar {
    max-width: 480px;
    width: 100%;
    -ms-flex-preferred-size: 480px;
    flex-basis: 480px;
    background: #fff;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    padding: 45px 40px 55px;
    margin-left: 35px;
}

@media only screen and (max-width: 1023px) {
    .partners-info__sidebar {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

@media only screen and (max-width: 767px) {
    .partners-info__sidebar {
        padding: 0 0 0 20px;
    }
}

.partners-info__sidebar-title {
    font-size: 26px;
    
    color: #333;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .partners-info__sidebar-title {
        font-size: 14px;
        margin-bottom: 10px;
    }
}

.partners-info__sidebar-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #3b474d;
    text-align: center;
    margin-bottom: 25px;
    padding: 0 20px;
}

@media only screen and (max-width: 767px) {
    .partners-info__sidebar-text {
        margin-bottom: 15px;
    }
}

.partners-info__sidebar-form .form-block {
    margin-bottom: 25px;
}

.partners-info__sidebar-form .form-block .main-input {
    height: 44px;
}

.partners-info__sidebar-form .form-block label {
    display: block;
    font-size: 13px;
    color: #8b979e;
    margin-bottom: 10px;
}

.partners-info__sidebar-form .form-block .select {
    font-size: 13px;
    padding: 14px 24px;
}

.partners-info__sidebar-form .form-block .select .select__list {
    width: auto;
    left: -1px;
    right: -1px;
    border-radius: 0;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    margin-top: 100%;
}

.partners-info__sidebar-form .form-block .select .select__item {
    padding: 12px 24px;
}

.partners-info__sidebar-form .form-block--required {
    position: relative;
}

.partners-info__sidebar-form .form-block--required::before {
    content: '*';
    position: absolute;
    left: -18px;
    top: 14px;
    font-size: 25px;
    font-family: 'Open Sans', sans-serif;
    color: #ec4a9b;
}

.partners-info__sidebar-form .form-block--select::before {
    top: 36px;
}

.partners-info__sidebar-form .submit-btn {
    font-family: 'Open Sans', sans-serif;
    max-width: 172px;
    height: 45px;
    background: #9baab3;
    margin: 0 auto;
}

@media only screen and (min-width: 1025px) {
    .partners-info__sidebar-form .submit-btn:hover {
        background: #ec4a9b;
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100000;
    display: none;
}

.popup.active {
    display: block;
}

.popup__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.popup__holder {
    background: #fff;
    padding: 65px 76px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 999;
    max-width: 564px;
    width: 100%;
    overflow: hidden;
}

@media only screen and (max-width: 767px) {
    .popup__holder {
        padding: 30px;
    }
}

.popup__holder--subscribe .popup__text {
    max-width: 325px;
}

.popup__holder--subscribe form .form-block {
    margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
    .popup__holder--subscribe form .form-block {
        margin-bottom: 20px;
    }
}

.popup__holder--subscribe form .form-block .main-input {
    height: 44px;
}

.popup__holder--subscribe form .submit-btn {
    margin: 0 auto;
}

.popup__holder--callback .popup__text {
    max-width: 325px;
}

.popup__holder--callback form .form-block {
    margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
    .popup__holder--callback form .form-block {
        margin-bottom: 20px;
    }
}

#bx-callback-form {
    width: 100%;
}

.popup__holder--callback form .form-block .main-input {
    height: 44px;
}

.popup__holder--callback form .submit-btn {
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    .popup__holder--callback {
        padding: 30px;
        padding: 20px 16px;
    }
}

@media only screen and (max-width: 480px) {
    .popup__holder--callback {
        max-width: 300px;
    }
}

.popup__holder--add-cart {
    max-width: 880px;
    padding: 45px 50px 55px;
}

@media only screen and (max-width: 767px) {
    .popup__holder--add-cart {
        padding: 20px 16px 30px;
    }
}

@media only screen and (max-width: 480px) {
    .popup__holder--add-cart {
        max-width: 300px;
    }
}

.popup__holder--add-cart .product-quantity {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup__holder--add-cart .product-quantity__button {
    padding: 0;
}

.popup__holder--add-cart .product-quantity__input {
    line-height: 25px;
    height: 30px;
}

.popup__holder--video {
    padding: 40px 15px 15px;
    max-width: 1000px;
    width: 100%;
}

@media only screen and (max-width: 1449px) {
    .popup__holder--video {
        max-width: 650px;
    }
}

.popup__holder--video .popup__close svg {
    fill: #333;
}

.popup__holder--video .popup__content {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.popup__holder--video .popup__content iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.popup__holder--buy-later {
    max-width: 768px;
    height: 425px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: visible;
}

.popup__holder--buy-later .popup__image {
    margin-bottom: 30px;
}

.popup__holder--buy-later .popup__title {
    font-size: 18px;
    margin-bottom: 15px;
}

.popup__holder--buy-later .popup__text {
    max-width: 100%;
}

@media only screen and (min-width: 991px) {
    .popup__holder--buy-later .popup__close {
        right: -25px;
        top: 5px;
    }

    .popup__holder--buy-later .popup__close svg {
        fill: #fff;
    }
}

.popup__holder--color-change {
    max-width: 500px;
    padding: 20px 0;
}

.popup__holder--color-change .popup__title {
    font-size: 18px;
    text-align: left;
    margin: 0 0 20px;
    padding-left: 30px;
}

.popup__holder--color-change .product-colors__list {
    max-height: 180px;
    position: static;
    -webkit-transform: none;
    transform: none;
    visibility: visible;
    opacity: 1;
    overflow: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-top: 1px solid #e1e6e9;
    border-bottom: 1px solid #e1e6e9;
}

.popup__holder--color-change .popup__close {
    top: 20px;
}

.popup__holder--color-change .submit-btn {
    margin: 20px auto 0;
    background: #333;
}

@media only screen and (min-width: 1025px) {
    .popup__holder--color-change .submit-btn:hover {
        background: #ec4a9b;
    }
}

.popup__holder--sale-info {
    padding: 65px 25px 50px;
    max-width: 1015px;
}

@media only screen and (max-width: 767px) {
    .popup__holder--sale-info {
        max-height: 440px;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 480px) {
    .popup__holder--sale-info {
        max-width: 90%;
        overflow-y: auto;
    }
}

.popup__holder--add-media {
    overflow: visible;
    max-width: 740px;
    padding: 0;
}

@media only screen and (max-width: 767px) {
    .popup__holder--add-media {
        max-height: 480px;
        overflow-y: auto;
    }
}

.popup__holder--add-media .popup__close {
    right: -30px;
}

@media only screen and (max-width: 767px) {
    .popup__holder--add-media .popup__close {
        top: -30px;
        right: 20px;
    }
}

.popup__holder--add-media .popup__close svg {
    fill: #fff;
}

.popup__holder--add-media .photo-btn {
    margin: 0 auto 40px;
}

.popup__holder--photo-reviews {
    max-width: 670px;
    padding: 40px 15px;
}

.popup__holder--info {
    max-width: 850px;
    padding: 60px 48px 65px;
}

@media only screen and (max-width: 767px) {
    .popup__holder--info {
        padding: 30px 15px 35px;
    }
}

.popup__holder--add-set {
    padding: 30px 60px 55px;
    max-width: 880px;
}

@media only screen and (max-width: 1449px) {
    .popup__holder--add-set {
        padding: 30px 60px 45px;
    }
}

@media only screen and (max-width: 767px) {
    .popup__holder--add-set {
        padding: 30px 15px 20px;
    }
}

.popup__holder--popup-password {
    max-width: 564px;
    padding: 60px 45px 65px;
}

@media only screen and (max-width: 767px) {
    .popup__holder--popup-password {
        padding: 30px 15px 35px;
    }
}

.popup__close {
    position: absolute;
    right: 17px;
    top: 15px;
    cursor: pointer;
}

.popup__close svg {
    width: 14px;
    height: 14px;
    fill: #333;
}

.popup__title {
    font-size: 26px;
    
    color: #333;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .popup__title {
        padding: 0 15px;
        font-size: 16px;
        margin-bottom: 12px;
    }
}

.popup__text {
    max-width: 395px;
    width: 100%;
    margin: 0 auto 40px;
    text-align: center;
    font-size: 14px;
    color: #333;
}

@media only screen and (max-width: 767px) {
    .popup__text {
        margin: 0 auto 25px;
    }
}

.popup__image {
    text-align: center;
}

.add-set__inner {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.add-set__inner .add-cart__content {
    max-width: 512px;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    border-top: 1px solid #e1e6e9;
    padding-top: 20px;
    margin-right: 10px;
}

@media only screen and (max-width: 767px) {
    .add-set__inner .add-cart__content {
        margin-right: 0;
    }
}

.add-set__inner .add-cart__status {
    border: none;
    padding: 0;
    margin-bottom: 15px;
}

.add-set__inner .add-cart__article {
    margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
    .add-set__inner .add-cart__article {
        margin-bottom: 15px;
    }
}

.add-set__inner .add-cart__options {
    margin-bottom: 28px;
}

@media only screen and (max-width: 767px) {
    .add-set__inner .add-cart__options {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .add-set__inner .add-cart__options-text {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .add-set__inner .add-cart__options-item {
        margin-bottom: 10px;
    }
}

.add-set__inner .add-cart__options-value {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.add-set__inner .add-cart__options-value .total-new {
    color: #ec4a9b;
    font-size: 20px;
    
}

.add-set__inner .add-cart__options-value .total-old {
    font-size: 16px;
    
    color: #6f7980;
    margin-left: 10px;
}

.add-set__inner .add-cart__buttons {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0 -13px;
}

@media only screen and (max-width: 767px) {
    .add-set__inner .add-cart__buttons {
        margin: 0 auto;
    }
}

.add-set__inner .add-cart__buttons a {
    margin: 0 13px;
}

@media only screen and (max-width: 767px) {
    .add-set__inner .add-cart__buttons a {
        margin: 0 0 10px;
    }

    .add-set__inner .add-cart__buttons a:last-of-type {
        margin-bottom: 0;
    }
}

.add-set__main-title {
    font-size: 18px;
    
    text-align: center;
    margin-bottom: 40px;
}

.add-set__list {
    width: 100%;
    max-height: 338px;
    overflow-y: auto;
}

@media only screen and (max-width: 1449px) {
    .add-set__list {
        max-height: 220px;
    }
}

@media only screen and (max-width: 767px) {
    .add-set__list {
        max-height: 120px;
    }
}

.add-set__item {
    padding: 15px 0 15px 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e1e6e9;
    margin-left: 40px;
}

@media only screen and (max-width: 1023px) {
    .add-set__item {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .add-set__item {
        margin-left: 0;
        padding: 15px 0;
    }
}

.add-set__item:last-of-type {
    border-bottom: none;
}

.add-set__image {
    max-width: 106px;
    width: 100%;
    -ms-flex-preferred-size: 106px;
    flex-basis: 106px;
    margin-right: 15px;
}

.add-set__info {
    max-width: 510px;
    width: 100%;
    display: 510px;
}

.add-set__title {
    font-size: 18px;
    
    color: #333;
    margin-bottom: 18px;
}

@media only screen and (max-width: 767px) {
    .add-set__title {
        margin-bottom: 10px;
        font-size: 16px;
    }
}

.add-set__title a {
    color: #333;
    text-decoration: none;
}

.add-set__price-text {
    font-size: 13px;
    color: #97a1a8;
    margin-bottom: 12px;
}

.add-set__price-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.add-set__new-price {
    color: #ec4a9b;
    font-size: 16px;
    
}

@media only screen and (max-width: 767px) {
    .add-set__new-price {
        font-size: 14px;
    }
}

.add-set__old-price {
    color: #6f7980;
    margin-left: 12px;
    font-size: 16px;
    
    text-decoration: line-through;
}

@media only screen and (max-width: 767px) {
    .add-set__old-price {
        font-size: 14px;
        margin-left: 8px;
    }
}

.iosBugFixCaret {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.add-cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 767px) {
    .add-cart {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.add-cart__image {
    max-width: 270px;
    width: 100%;
    -ms-flex-preferred-size: 270px;
    flex-basis: 270px;
    margin-right: 30px;
}

@media only screen and (max-width: 767px) {
    .add-cart__image {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto 10px;
        text-align: center;
    }

    .add-cart__image img {
        height: 115px;
        width: auto;

    }
}

.add-cart__content {
    max-width: 466px;
    width: 100%;
    -ms-flex-preferred-size: 466px;
    flex-basis: 466px;
}

@media only screen and (max-width: 767px) {
    .add-cart__content {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.add-cart__title {
    max-width: 300px;
    width: 100%;
    font-size: 18px;
    
    color: #333;
    margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
    .add-cart__title {
        font-size: 13px;
    }
}

.add-cart__title a {
    color: #333;
    text-decoration: none;
}

.add-cart__status {
    font-size: 13px;
    color: #333;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 15px;
}

.add-cart__status span {
    position: relative;
    width: 16px;
    height: 16px;
    background: #9175c9;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

.add-cart__status span::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.add-cart__article {
    font-size: 13px;
    color: #333;
    margin-bottom: 38px;
}

.add-cart__article span {
    font-size: 14px;
    
}

@media only screen and (max-width: 767px) {
    .add-cart__article {
        margin-bottom: 25px;
    }
}

.add-cart__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
    .add-cart__options {
        margin-bottom: 20px;
    }
}

.add-cart__options-item:last-child {
    margin-right: 30px;
}

@media only screen and (max-width: 767px) {
    .add-cart__options-item:last-child {
        margin-right: 0;
    }
}

.add-cart__options-text {
    font-size: 13px;
    color: #333;
    margin-bottom: 16px;
}

.add-cart__options-value .price {
    font-size: 16px;
    
    color: #333;
}

@media only screen and (max-width: 767px) {
    .add-cart__options-value .price {
        font-size: 13px;
    }
}

.add-cart__options-value .total {
    font-size: 20px;
    
    color: #333;
}

@media only screen and (max-width: 767px) {
    .add-cart__options-value .total {
        font-size: 13px;
    }
}

@media only screen and (max-width: 767px) {
    .add-cart__options-value .product-quantity {
        height: 15px;
    }

    .add-cart__options-value .product-quantity__button {
        background: transparent;
    }

    .add-cart__options-value .product-quantity__input {
        width: 30px;
        border: none;
    }
}

.add-cart__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 767px) {
    .add-cart__buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .add-cart__buttons .white-btn {
        margin-bottom: 8px;
    }
}

.login-popup {
    position: absolute;
    top: 100%;
    right: -600%;
    -webkit-transform: translateY(-5%);
    transform: translateY(-5%);
    margin-top: 30px;
    width: 692px;
    background: #fff;
    z-index: 4;
    -webkit-box-shadow: 0px 3px 40px 0px rgba(71, 82, 88, 0.17);
    box-shadow: 0px 3px 40px 0px rgba(71, 82, 88, 0.17);
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 1023px) {
    .login-popup {
        width: 360px;
    }
}

.login-popup.open {
    visibility: visible;
    opacity: 1;
    overflow: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.login-popup__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 1023px) {
    .login-popup__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.login-popup__main {
    position: relative;
    padding: 35px 40px 40px;
    max-width: 370px;
    width: 100%;
    -ms-flex-preferred-size: 370px;
    flex-basis: 370px;
}

.login-popup__main::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 80%;
    background: #e7e7e7;
}

@media only screen and (max-width: 1023px) {
    .login-popup__main {
        padding: 0;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .login-popup__main::before {
        display: none;
    }
}

.login-popup__socials {
    position: relative;
    max-width: 320px;
    width: 100%;
    -ms-flex-preferred-size: 320px;
    flex-basis: 320px;
}

@media only screen and (max-width: 1023px) {
    .login-popup__socials {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin-top: 40px;
    }
}

.login-popup__socials .socials-login {
    margin: 0 auto;
    text-align: center;
}

.login-popup__socials .socials-login__list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.login-popup__title {
    font-size: 18px;
    
    text-align: center;
    color: #333;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.login-popup__form .form-block {
    margin-bottom: 26px;
}

.login-popup__form .form-block__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 40px;
}

.login-popup__form .submit-btn {
    margin: 0;
    max-width: 100%;
}

.login-popup__bottom {
    padding-top: 20px;
    text-align: center;
}

.login-popup__bottom-text {
    font-size: 13px;
    color: #697379;
    margin-bottom: 5px;
}

.login-popup__text {
    font-size: 14px;
    color: #333;
    text-align: center;
    margin: -15px auto 16px;
}

.login-popup__temporary-password .form-block {
    margin-bottom: 18px;
}

.login-popup__temporary-password .submit-btn {
    max-width: 100%;
}

.login-popup__bottom-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 28px auto 0;
}

.login-popup__bottom-row .login-popup__bottom-text {
    margin: 0;
    text-decoration: none;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border-bottom: 1px dotted #697379;
}

@media only screen and (min-width: 1025px) {
    .login-popup__bottom-row .login-popup__bottom-text:hover {
        border-color: transparent;
    }
}

.forgot-password {
    font-size: 13px;
    padding-bottom: 2px;
    color: #333;
    text-decoration: none;
    border-bottom: 1px dotted #333;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .forgot-password:hover {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }
}

.registration-link {
    font-size: 14px;
    
    color: #333;
    -webkit-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .registration-link:hover {
        color: #ec4a9b;
    }
}

.basket-popup {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 420px;
    background: #fff;
    z-index: 10102;
    box-shadow: 0 3px 40px 0 rgba(71, 82, 88, 0.17);
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transform: translateX(100%);
    transition: 0.3s ease-in-out;
    color: #333;
    height: 100vh;
}

@media only screen and (max-width: 480px) {
    .basket-popup {
        left: 0;
        right: 0;
        top: 0;
        margin: 0;
        height: 100vh;
        max-height: -webkit-fill-available;
        width: 100%;
    }
}

.basket-popup.open {
    visibility: visible;
    opacity: 1;
    overflow: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.basket-popup__empty {
    text-align: center;
    padding: 30px 15px;
    font-size: 18px;
    font-weight: normal;
}

.basket-popup__main-title {
    font-size: 18px;
    
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #e1e6e9;
    padding: 20px;
}

.basket-popup__list {
    overflow-y: auto;
}

@media only screen and (max-width: 480px) {
    .basket-popup__list {
        height: calc(100% - 62px);
    }
}

.basket-popup__list .mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.basket-popup__list .mCSB_scrollTools {
    right: -6px;
}

.basket-popup__list .mCSB_scrollTools .mCSB_draggerContainer {
    top: 0;
}

.basket-popup__list .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #89959b;
}

.basket-popup__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 15px 0 40px;
    padding: 20px 0;
    border-bottom: 1px solid #e1e6e9;
}

.basket-popup__item:last-of-type {
    border-bottom: none;
    padding-bottom: 50px;
}

.basket-popup__delete {
    cursor: pointer;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -20px;
}

.basket-popup__delete svg {
    width: 12px;
    height: 12px;
    fill: #89959b !important;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .basket-popup__delete:hover svg {
        fill: #ec4a9b;
    }
}

.basket-popup__image {
    max-width: 70px;
    width: 100%;
    -ms-flex-preferred-size: 70px;
    flex-basis: 70px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 10px;
}

.basket-popup__content {
    max-width: 208px;
    width: 100%;
    -ms-flex-preferred-size: 208px;
    flex-basis: 208px;
}

.basket-popup__title {
    font-size: 13px;
    
    color: #333;
    margin-bottom: 15px;
}

.basket-popup__title a {
    color: #333;
    text-decoration: none;
}

@media only screen and (min-width: 1025px) {
    .basket-popup__title a:hover {
        color: #ec4a9b;
    }
}

.basket-popup .basket-items {
    display: flex;
    flex-direction: column;
    height: calc(100% - 62px);
}

@media (max-width: 480px) {
    .basket-popup .basket-items {
        justify-content: space-between;
    }
}

.basket-popup__info {
    flex-wrap: wrap;
    max-width: 193px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.basket-popup__price {
    font-size: 14px;
    
    color: #333;
}

.basket-popup__item .checkout-basket__price-new {
    font-size: 14px !important;
}

.basket-popup__item .checkout-basket__price-old {
    font-size: 12px !important;
    margin-bottom: 3px !important;
}

.basket-popup__quantity .product-quantity {
    width: 78px;
    height: 26px;
}

.basket-popup__quantity .product-quantity__input {
    width: 35px;
    font-size: 13px;
    border-radius: 0;
}

.basket-popup__quantity .product-quantity__button svg {
    width: 5px;
    height: 6px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.basket-popup__bottom {
    padding: 18px 32px 26px;
    border-top: 1px solid #e1e6e9;
    text-align: center;
}

.basket-popup__bottom .submit-btn {
    margin: 0 auto;
}

@media (max-width: 480px) {

}

.basket-popup__total {
    font-size: 13px;
    color: #333;
    margin-bottom: 18px;
}

.basket-popup__total span {
    font-size: 16px;
    
    color: #333;
}

.basket-popup__close {
    width: 20px;
    height: 20px;
    top: 20px;
    right: 20px;
    position: absolute;
    cursor: pointer;
}

.basket-popup__close svg {
    fill: #9fa9b0 !important;
    width: 20px;
    height: 20px;
}

@media only screen and (max-width: 480px) {
    .basket-popup__close {
        display: block;
    }
}

.buy-later__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .buy-later__buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.buy-later__button {
    max-width: 236px;
    width: 100%;
    height: 40px;
    font-size: 13px;
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 10px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
    .buy-later__button {
        margin: 5px 0;
    }
}

.buy-later__cancel {
    border: 1px solid #c3ced5;
    background: #fff;
    color: #768187;
}

@media only screen and (min-width: 1025px) {
    .buy-later__cancel:hover {
        background: #768187;
        color: #fff;
    }
}

.buy-later__confirm {
    background: #768187;
    color: #fff;
}

@media only screen and (min-width: 1025px) {
    .buy-later__confirm:hover {
        background: #ec4a9b;
    }
}

.sale-info__subtitle {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    
    color: #768187;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
}

.sale-info__description {
    max-width: 390px;
    width: 100%;
    margin: 0 auto 30px;
    text-align: center;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    color: #768187;
    border-top: 1px solid #dee1e3;
    padding-top: 10px;
}

.sale-info__list {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .sale-info__list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.sale-info__list::before {
    content: "";
    position: absolute;
    height: 6px;
    width: 70%;
    background: #d7dfe4;
    top: 28px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
}

@media only screen and (max-width: 767px) {
    .sale-info__list::before {
        display: none;
    }
}

.sale-info__item {
    max-width: 215px;
    width: 100%;
    -ms-flex-preferred-size: 215px;
    flex-basis: 215px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 65px;
}

@media only screen and (max-width: 1023px) {
    .sale-info__item {
        margin: 0 25px;
    }
}

@media only screen and (max-width: 767px) {
    .sale-info__item {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 15px 20px;
    }
}

.sale-info__number {
    background: #768187;
    border: 4px solid #fff;
    -webkit-box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.08);
    width: 62px;
    height: 62px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    
    color: #fff;
    border-radius: 50%;
    margin-bottom: 15px;
}

.sale-info__title {
    
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.sale-info__text {
    max-width: 215px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #333;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.2;
}

.file {
    width: 100%;
    cursor: pointer;
}

.file__title {
    max-width: 218px;
    width: 100%;
    height: 45px;
    margin: 0 auto;
    background: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px;
    
    text-transform: uppercase;
    color: #fff;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .file__title:hover {
        background: #ec4a9b;
    }
}

#uploadfiles {
    display: none;
}

.add-media__tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: 1px solid #e1e6e9;
}

.add-media__tabs-item {
    width: 50%;
    text-align: center;
    border-right: 1px solid #e1e6e9;
}

.add-media__tabs-item:last-child {
    border-right: none;
}

.add-media__tabs-item a {
    position: relative;
    padding: 16px 0;
    font-size: 16px;
    
    color: #333;
    text-decoration: none;
    display: block;
}

.add-media__tabs-item a::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    width: 0;
    height: 2px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #b0bfc8;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.add-media__tabs-item a.active {
    color: #333;
}

.add-media__tabs-item a.active::before {
    width: 100%;
}

.video-add {
    padding: 40px 20px 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 1px solid #e1e6e9;
}

.video-add__button {
    max-width: 104px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    
    color: #333;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.video-add__button label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.video-add__button input {
    display: none;
}

.video-add__button svg {
    fill: #333;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .video-add__button:hover {
        color: #ec4a9b;
    }

    .video-add__button:hover svg {
        fill: #ec4a9b;
    }
}

.video-add__wrap .submit-btn {
    font-family: 'Open Sans', serif;
    max-width: 215px;
    margin: 0 auto 32px;
}

.video-add__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-height: 310px;
    overflow-y: auto;
    margin-bottom: 15px;
}

.video-item {
    max-width: 222px;
    width: 100%;
    margin: 0 2px 15px;
}

@media only screen and (max-width: 767px) {
    .video-item {
        margin: 0 auto 15px;
    }
}

.video-item__video {
    margin-bottom: 14px;
}

.video-item__video iframe {
    border: none;
}

.video-item__name {
    text-align: center;
    font-size: 12px;
    
    color: #768187;
    margin-bottom: 15px;
}

.video-item__delete {
    max-width: 106px;
    width: 100%;
    height: 28px;
    margin: 0 auto;
    font-size: 12px;
    color: #798890;
    border: 1px solid #c0cfd8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.video-item__delete svg {
    fill: #6d7a82;
    width: 10px;
    height: 13px;
    margin-right: 8px;
}

@media only screen and (min-width: 1025px) {
    .video-item__delete:hover {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }

    .video-item__delete:hover svg {
        fill: #ec4a9b;
    }
}

.video-frame {
    padding: 60px 0 30px;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    .video-frame {
        padding: 40px 15px 50px;
    }
}

.video-frame .form-block {
    margin-bottom: 12px;
}

.video-frame .form-block label {
    font-size: 14px;
    color: #333;
    display: block;
    margin-bottom: 8px;
}

.video-frame__text {
    font-size: 12px;
    color: #333;
    margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
    .video-frame__text {
        margin-bottom: 30px;
    }
}

.add-photos {
    min-height: 400px;
    padding: 30px 0;
}

@media only screen and (max-width: 767px) {
    .add-photos {
        min-height: 200px;
    }
}

#fileElem {
    display: none;
}

#drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 100%;
    padding: 20px;
    max-width: 635px;
    margin: 0 auto;
}

#drop-area p {
    text-align: center;
    color: #333;
}

#drop-area .submit-btn {
    margin: 0 auto;
}

#gallery {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 20px -5px 0;
    max-height: 265px;
    overflow-y: auto;
}

@media only screen and (max-width: 767px) {
    #gallery {
        max-height: 170px;
    }
}

.added-image__item {
    position: relative;
    max-width: 125px;
    width: 100%;
    height: 80px;
    overflow: hidden;
    margin: 0 5px 10px;
}

.added-image__item img {
    width: 100%;
    min-height: 80px;

}

@media only screen and (min-width: 1025px) {
    .added-image__item:hover .added-image__remove {
        opacity: 1;
    }
}

.added-image__remove {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 15px;
    width: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #fff;
    opacity: 0;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.added-image__remove svg {
    cursor: pointer;
    fill: #ec4a9b;
    width: 8px;
    height: 8px;
}

.wrapper-all {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.photo-reviews__image {
    margin-bottom: 20px;
    text-align: center;
}

.photo-reviews__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 18px;
}

.popup-info__title {
    font-size: 18px;
    
    text-transform: uppercase;
    color: #333;
    margin-bottom: 18px;
    text-align: center;
}

.popup-info__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    line-height: 1.4;
    max-height: 400px;
    overflow-y: auto;
}

@media only screen and (max-width: 767px) {
    .popup-info__text {
        max-height: 300px;
    }
}

.product-remove__title {
    font-size: 26px;
    
    color: #333;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto 15px;
}

.product-remove__text {
    font-family: "PT Sans", sans-serif;
    font-size: 14px;
    color: #56656d;
    text-align: center;
    max-width: 330px;
    width: 100%;
    margin: 0 auto 35px;
}

.product-remove__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.product-remove__buttons .submit-btn {
    max-width: 142px;
    width: 100%;
    height: 45px;
    margin: 0 11px;
}

.product-remove__buttons .submit-btn.delete {
    background: #c0cad0;
}

.product-remove__buttons .submit-btn.cancel {
    background: #333;
}

@media only screen and (min-width: 1025px) {
    .product-remove__buttons .submit-btn:hover {
        background: #ec4a9b;
    }
}

.recovery-password {
    display: none;
}

.popup-password__title {
    font-size: 26px;
    
    color: #333;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto 18px;
}

@media only screen and (max-width: 767px) {
    .popup-password__title {
        font-size: 18px;
    }
}

.popup-password__text {
    max-width: 260px;
    width: 100%;
    margin: 0 auto 30px;
    text-align: center;
    font-size: 14px;
    color: #56656d;
}

.popup-password .submit-btn {
    max-width: 218px;
    margin: 0 auto;
}

.sms-code {
    text-align: center;
    width: 30px;
    font-size: 26px;
    
    color: #333;
    border: none;
    border-bottom: 1px solid #8b979e;
    margin: 0 12px;
    text-transform: uppercase;
}

.sms-code:focus {
    border-bottom-color: #333;
}

.sms-code__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 220px;
    width: 100%;
    margin: 0 auto 50px;
}

.product-page {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
}

@media only screen and (max-width: 1599px) {
    .product-page {
        padding: 0 15px;
    }
}

.product-page__main {
    margin-bottom: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    gap: 15px;
}

@media only screen and (max-width: 1249px) {
    .product-page__main {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media only screen and (max-width: 767px) {
    .product-page__main {
        margin-bottom: unset;
    }

    .product-page__options {
        margin-bottom: unset;
    }
}

.product-page__lg-slider-inner {
    position: relative;
    max-width: 605px;
    width: 100%;
    -ms-flex-preferred-size: 605px;
    flex-basis: 605px;
}

@media only screen and (max-width: 767px) {
    .product-page__lg-slider-inner {
        margin: 0 auto;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.product-page__lg-slider-inner .product-delivery {
    background: #fff;
    max-width: 225px;
    width: 100%;
    height: 42px;
    position: absolute;
    right: 1px;
    top: 20px;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(47, 74, 90, 0.12);
    box-shadow: 0px 5px 20px 0px rgba(47, 74, 90, 0.12);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 15px;
}

@media only screen and (max-width: 767px) {
    .product-page__lg-slider-inner .product-delivery {
        max-width: 155px;
        height: auto;
        padding: 5px 10px;
    }
}

.product-page__lg-slider-inner .product-delivery__icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 12px;
}

.product-page__lg-slider-inner .product-delivery__icon svg {
    fill: #ec4a9b;
    width: 34px;
    height: 20px;
}

.product-page__lg-slider-inner .product-delivery__text {
    font-size: 14px;
    color: #333;
}

.product-page__lg-slider-inner .product-delivery__text span {
    display: block;
}

.product-page__lg-slider-inner .product__image-inner {
    height: 135px;
    pointer-events: none;
}

.product-page__lg-slider-inner .product__image-inner .product-sale {
    left: -10px;
    top: 35px;
    max-width: 128px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media only screen and (max-width: 767px) {
    .product-page__lg-slider-inner .product__image-inner .product-sale {
        left: -15px;
        top: 30px;
        font-size: 14px;
        max-width: 128px;
    }
}

.product-page__lg-slider-inner .product-counter {
    top: auto;
    bottom: 16px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media only screen and (max-width: 767px) {
    .product-page__lg-slider-inner .product-counter {
        display: none;
    }
}

.product-page__lg-slider-inner .product-counter__item {
    margin: 0 7px;
    width: 66px;
    background: transparent;
}

.product-page__lg-slider-inner .product-counter__time {
    max-width: 66px;
    position: relative;
    margin-bottom: 8px;
    font-size: 32px;
    
    color: #fff;
    letter-spacing: 17px;
}

.product-page__lg-slider-inner .product-counter__time > span {
    margin-left: 6px;
}

.product-page__lg-slider-inner .product-counter__time::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 31px;
    height: 40px;
    background: #333;
    top: 0;
    left: 0;
    border-radius: 4px;
    z-index: -1;
}

.product-page__lg-slider-inner .product-counter__time::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 31px;
    height: 40px;
    background: #333;
    top: 0;
    right: 0;
    border-radius: 4px;
    z-index: -1;
}

.product-page__lg-slider-inner .product-counter__text {
    color: #818d94;
}

.product-page__slider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    max-width: 735px;
    width: 100%;
    -ms-flex-preferred-size: 735px;
    flex-basis: 735px;
}

.product-page__slider .swiper-wrapper {
    display: flex;
    flex-direction: column;
}

@media (max-width: 767.98px) {
    .product-page__slider .swiper-wrapper {
        flex-direction: row;
    }

    .product-page__slider .slider__image img{
        width: 96% !important;
        margin: 0 auto !important;
    }
}

.product-page__slider .swiper-slide {
    margin: 10px 0;
    border: 1px solid #e1e6e9;
}

.product-page__slider .slider__images .swiper-slide{
    margin-top: 0px;
}

@media only screen and (max-width: 1249px) {
    .product-page__slider {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto 40px;
    }
}

.product-page__sm-slider {
    max-width: 100px;
    width: 100%;
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    max-height: 605px;
    margin-right: 25px;
    -ms-flex-item-align: center;
    align-self: center;
    margin: -12px 25px -12px 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
    .product-page__sm-slider {
        display: none;
    }

    .product-page__lg-slider-inner .product-counter__time::before {

        width: 25px;
        height: 32px;
    }

    .product-page__lg-slider-inner .product-counter__time::after {

        width: 25px;
        height: 32px;
    }

    .product-page__lg-slider-inner .product-counter__time {
        max-width: 56px;
        font-size: 26px;
    }

    .product-page__lg-slider-inner .product-counter__item {
        margin: -1px -1px;
    }
}

.product-page__sm-slide {
    position: relative;
    border: 1px solid #e1e6e9 !important;
    margin: 10px 0;
    max-width: 100px;
    width: 100%;
    min-height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex !important;
}

.product-page__sm-slide::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: #b0bfc8;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-page__sm-slide::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: #b0bfc8;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-page__sm-slide.slick-current.slick-active {
    border: 1px solid transparent !important;
}

.product-page__sm-slide.slick-current.slick-active::before, .product-page__sm-slide.slick-current.slick-active::after {
    visibility: visible;
    opacity: 1;
    overflow: visible;
}

.product-page__lg-slider {
    max-width: 605px;
    width: 100%;
    -ms-flex-preferred-size: 605px;
    flex-basis: 605px;
}

@media only screen and (max-width: 767px) {
    .product-page__lg-slider {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.product-page__lg-slider .slick-dots {
    bottom: -50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

@media only screen and (max-width: 767px) {
    .product-page__lg-slider .slick-dots {
        bottom: -35px;
    }
}

.product-page__lg-slider .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.product-page__lg-slider .slick-dots li:first-of-type {
    margin-left: 0;
}

.product-page__lg-slider .slick-dots li:last-of-type {
    margin-right: 0;
}

.product-page__lg-slider .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-page__lg-slider .slick-dots li button::before {
    display: none;
}

.product-page__lg-slider .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

.product-page__lg-slide {
    max-width: 605px;
    width: 100%;
    -ms-flex-preferred-size: 605px;
    flex-basis: 605px;
    height: 605px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #e1e6e9;
}

@media only screen and (max-width: 767px) {
    .product-page__lg-slide {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        max-width: 100%;
        height: auto;
    }
}

.product-page__button {
    background: transparent;
    position: absolute;
    left: 0;
    width: 100%;
    height: 22px;
    cursor: pointer;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-page__button svg {
    fill: #c7d2d8;
    width: 8px;
    height: 17px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

@media only screen and (min-width: 1025px) {
    .product-page__button:hover {
        background: #eff2f4;
    }
}

.product-page__prev-btn {
    top: -12px;
}

.product-page__next-btn {
    bottom: -12px;
}

.product-page__info {
    max-width: 712px;
    width: 100%;
    -ms-flex-preferred-size: 712px;
    flex-basis: 712px;
    min-width: 0;
}

@media only screen and (max-width: 1249px) {
    .product-page__info {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .product-page__info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.product-page__info-top .in-stock {
    min-width: 106px;
}

.product-page__info-top .product-page__article {
    min-width: 217px;
}

.product-page__info-top .product-page__reviews {
    min-width: 148px;
}

.product-page__info-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .product-page__info-top {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.product-page__reviews {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .product-page__reviews {
        margin: 12px 0 0;
    }
}

.product-page__reviews-link {
    font-size: 14px;
    color: #8b979e;
    padding-bottom: 2px;
    text-decoration: none;
    margin-right: 10px;
    transition: .3s ease-in-out;
    display: flex;
    gap: 5px;
    align-items: center;
}

@media only screen and (min-width: 1025px) {
    .product-page__reviews-link:hover {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }
}

.product-page__status {
    font-size: 13px;
    color: #333;
    margin-right: 22px;
}

@media only screen and (max-width: 767px) {
    .product-page__status {
        margin-right: 5px;
    }
}

.product-page__status span {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

.product-page__status span::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: none;
}

.product-page__status.in-stock span {
    background: #9175c9;
}

.product-page__status.in-stock span::before {
    display: block;
}

.product-page__article {
    font-size: 13px;
    color: #333;
    border-left: 1px solid #cecece;
    padding: 0 22px;
}

.product-page__article span {
    font-size: 14px;
    
    margin-left: 5px;
}

.product-page__table {
    background: #fbfcfc;
    border: 1px dashed #d9e0e5;
    padding: 20px;
    margin-bottom: 35px;
}

.product-page__table-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product-page__brand {
    border: 1px solid #e1e6e9;
    background: #fff;
    max-width: 125px;
    height: 86px;
    padding: 10px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .product-page__brand {
        display: none;
    }
}

.product-page__info-list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 465px;
    width: 100%;
    margin-right: 20px;
}

.product-page__info-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.product-page__info-item:last-of-type {
    margin-bottom: 0;
}

.product-page__info-item .name {
    font-size: 14px;
    color: #333;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 5px;
}

.product-page__info-item .dots {
    -ms-flex-item-align: end;
    align-self: flex-end;
    border-bottom: 1px dotted #333;
    width: 100%;
    margin-bottom: 3px;
}

.product-page__info-item .value {
    font-size: 14px;
    
    color: #333;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 5px;
}

.product-page__controls {
    max-width: 320px;
    width: 100%;
    -ms-flex-preferred-size: 320px;
    flex-basis: 320px;
    margin-right: 20px;
}

@media only screen and (max-width: 767px) {
    .product-page__controls.dropdown_price {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;

    }

    .product-page__controls {
        flex-basis: auto;
        margin: 0 auto 10px;
    }

    .dropdown > .mCustomScrollBox.mCS-light.mCSB_vertical.mCSB_inside {
        width: 450px;
    }

    .product-colors__list.scroll-text.tree-values-scroll.open.mCustomScrollbar.dropdown {
        margin-bottom: 0px !important;
    }
}

@media only screen and (max-width: 480px) {
    .dropdown > .mCustomScrollBox.mCS-light.mCSB_vertical.mCSB_inside {
        width: auto;
    }

    .product-colors__list.scroll-text.tree-values-scroll.open.mCustomScrollbar.dropdown {
        width: auto;
    }
}

.product-page__price-box {
    margin-bottom: 20px;
}

.product-page__price-old {
    color: #333;
    font-size: 15px;
    text-decoration: line-through;
}

.product-page__price-new {
    
    font-size: 26px;
    color: #ec4a9b;
}

.product-page__price-current {
    color: #333;
    
    font-size: 26px;
}

.order-details-wrap {
    display: flex;
    flex-direction: column;
}

.order-buttons {
    display: flex;
    flex-direction: row;

}

.order-buttons__pay-btn {
    width: 100%;
    min-width: 220px;
    padding: 10px 0px;
    margin-right: 5px;
    background-color: #ec4a9b;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.order-buttons__pay-btn:hover {
    background: #333;
}

.order-buttons__pay-btn.blocked {
    background-color: #d9dadb;
}

.order-buttons__pay-btn.blocked:hover {
    background-color: #d9dadb;
    cursor: default;
}

.product-page__buy, .menu-popup__buy {
    width: 100%;
    height: 44px;
    background: #ec4a9b;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.menu-popup__buy {
    width: 44px;
}

.product-page__buy svg, .menu-popup__buy svg {
    width: 23px;
    height: 17px;
    fill: #fff;
}

@media only screen and (min-width: 1025px) {
    .product-page__buy:hover, .menu-popup__buy:hover {
        background: #333;
    }
}

.product-page__buttons {
    position: relative;
    max-width: 245px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product-page__buttons::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 26px;
    background: #e1e6e9;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
}

.product-page__buttons-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 11px;
    color: #333;
    max-width: 110px;
    width: 100%;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-page__buttons-item span {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 12px;
    position: relative;
}

.product-page__buttons-item span svg {
    fill: #9ca7ae;
    width: 23px;
    height: 21px;
}

.product-page__buttons-item .chek {
    top: -8px;
    right: -10px;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ec4a9b;
    display: none;
}

.product-page__buttons-item .chek::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.product-page__buttons-item.added .chek {
    display: block;
}

.product-page__buttons-item.added svg {
    fill: #333;
}

@media only screen and (min-width: 1025px) {
    .product-page__buttons-item:hover {
        color: #ec4a9b;
    }
}

.product-page__compare span svg {
    width: 24px;
    height: 24px;
}

.product-page__wishlist span svg {
    width: 23px;
    height: 21px;
}

.product-page__wishlist.active .added{
    display: flex;
}

.product-page__wishlist .added {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    background: #ec4a9b;
    border-radius: 50%;
    position: absolute;
    right: -22px;
    top: -8px;
    display: none;
}

.product-page__wishlist .added > svg {
    width: 9px;
    height: 7px;
    fill: #fff;
}

.product-page__info-top .product-page__wishlist {
    padding: 0 22px;
}

.wishlist_text {
    flex: auto;
    flex-shrink: initial !important;
}

.product-page__info-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product-page__text-delivery p {
    font-size: 20px;
    font-weight: normal;
    color: #ec4a9b;
    text-transform: uppercase;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .product-page__info-inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.product-page__color {
    position: relative;
    margin-bottom: 20px;
}

.product-page__color .product-colors {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.product-page__color .product-colors .product-colors__item {
    width: 30px;
    height: 30px;
}

.product-page__color .product-colors > .product-colors__item {
    margin: 4px;
}

/*@media only screen and (max-width: 767px) {
    .product-page__color .product-colors > .product-colors__item {
        display: none;
    }*/

.product-page__color .product-colors > .product-colors__item:nth-child(-n+5) {
    display: block;
}

.product-page__color .product-colors .product-colors__list {
    top: 100%;
}

.product-page__color-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 12px;
}

.product-page__options {
    margin-bottom: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .product-page__options {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
}

@media only screen and (max-width: 767px) {
    .product-page__options {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.product-page__options-item {
    position: relative;
    max-width: 330px;
    width: 100%;
    -ms-flex-preferred-size: 330px;
    flex-basis: 330px;
}

.product-page__options-item .product-colors__list.dropdown {
    max-height: unset !important;
    height: 210px;
    margin-top: 15px;
}

.product-page__options-item .product-colors__list {

    top: 100%;
    left: -15px;
    right: -15px;
    width: auto;
}


.product-page__options-item .product-colors__list-inne {
    padding: 15px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.product-page__options-item--size .options-item {
    width: auto;
    padding: 0 20px;
}

.product-page__options-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -5px;
}

.product-page__options-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 12px;
}

.product-page__overview {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.product-page__overview .product-page__button-3d,
.product-page__overview .product-page__button-video {
    max-width: 142px;
    width: 100%;
    height: 38px;
    border: 1px solid #e1e6e9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    font-size: 11px;
    color: #8a9ba5;
    margin: 0 3px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-page__overview .product-page__button-3d:first-of-type,
.product-page__overview .product-page__button-video:first-of-type {
    margin-left: 0;
}

.product-page__overview .product-page__button-3d:last-of-type,
.product-page__overview .product-page__button-video:last-of-type {
    margin-right: 0;
}

.product-page__overview .product-page__button-3d svg,
.product-page__overview .product-page__button-video svg {
    width: 20px;
    height: 15px;
    fill: #8a9ba5;
    margin-right: 8px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .product-page__overview .product-page__button-3d:hover,
    .product-page__overview .product-page__button-video:hover {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }

    .product-page__overview .product-page__button-3d:hover svg,
    .product-page__overview .product-page__button-video:hover svg {
        fill: #ec4a9b;
    }
}

.dropdown > .options-item {
    position: relative;
    width: 200px;
    height: 35px;
}

.options-item {
    position: relative;
    margin: 0 5px 10px;
    width: auto;
    padding: 0 25px;
    height: 27px;
}

.options-item label {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.options-item input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.options-item input[type="radio"] + label {
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.options-item input[type="radio"] + span {
    font-size: 12px;
    
    color: #97a1a8;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    display: block;
    width: unset;
}

.product-colors__list-inner.dropdown > .options-item input[type="radio"] + span {
    font-size: 13px;
    
    color: #97a1a8;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    display: block;
    width: 100%;
}

.options-item input[type="radio"] + span::before {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    border: 1px solid #9baab3;
}

.options-item input[type="radio"]:checked + span {
    
    color: #ec4a9b;
}

.product-colors__list-inner.dropdown > .options-item input[type="radio"]:checked + span {
    color: #ec4a9b;
}

.options-item input[type="radio"]:checked + span::before {
    border: 2px solid #ec4a9b;
}

.buy-onclick, .product-availability {
    margin-bottom: 25px;
}

.buy-onclick__title, .product-availability__title {
    font-size: 12px;
    color: #8b979e;
    margin-bottom: 12px;
}

.buy-onclick form, .product-availability form{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-availability form{
    margin-bottom: 12px;
}

.buy-onclick form .form-block, .product-availability .form-block {
    width: 100%;
}

.buy-onclick form .submit-btn, .product-availability form .submit-btn {
    font-family: 'PT Serif', serif;
    background: #9baab3;
    max-width: 75px;
    font-size: 11px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-availability form .submit-btn {
    max-width: 90px;
}

@media only screen and (min-width: 1025px) {
    .buy-onclick form .submit-btn:hover, .product-availability form .submit-btn:hover {
        background: #ec4a9b;
    }
}

.buy-onclick input, .product-availability input {
    color: #333;
    
    font-size: 13px;
}

.buy-onclick input::-webkit-input-placeholder, .product-availability input::-webkit-input-placeholder {
    font-weight: 400;
}

.buy-onclick input::-moz-placeholder, .product-availability input::-moz-placeholder {
    font-weight: 400;
}

.buy-onclick input::-ms-input-placeholder, .product-availability input::-ms-input-placeholder {
    font-weight: 400;
}

.buy-onclick input::placeholder, .product-availability input::placeholder {
    font-weight: 400;
}

#form_success_message{
    display: none;
    font-size: 16px;
    color: #61b149;
}

.info-tabs {
    max-width: 330px;
    width: 100%;
    -ms-flex-preferred-size: 330px;
    flex-basis: 330px;
}

@media only screen and (max-width: 767px) {
    .info-tabs {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto 20px;
    }
}

.info-tabs__list {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #e1e6e9;
}

.info-tabs__item {
    width: 100%;
    font-size: 12px;
    
    text-transform: uppercase;
    text-align: center;
}

.info-tabs__item a {
    position: relative;
    display: block;
    padding: 10px 0;
    color: #97a1a8;
    text-decoration: none;
}

.info-tabs__item a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background: #b0bfc8;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.info-tabs__item a::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 10px;
    background: #b0bfc8;
}

.info-tabs__item a.active {
    color: #545e65;
}

.info-tabs__item a.active::before {
    width: 100%;
}

.info-tabs__item:last-of-type a::after {
    display: none;
}

.info-tabs .more-link {
    position: relative;
    display: table;
    font-size: 13px;
    color: #97a1a8;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.info-tabs .more-link::after {
    border-bottom: 1px solid #999;
    border-right: 1px solid #999;
    content: '';
    display: block;
    height: 6px;
    width: 6px;
    margin-top: -3px;
    pointer-events: none;
    position: absolute;
    right: -15px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .info-tabs .more-link:hover {
        color: #ec4a9b;
    }

    .info-tabs .more-link:hover::after {
        border-color: #ec4a9b;
    }
}

.delivery-info__inner {
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 15px;
}

.delivery-info__inner > ul,
.payment-info__inner > ul {
    width: 100%;
    font-size: 14px;
    padding-left: 20px;
    margin-right: 10px;
    color: #768187;
}

@media only screen and (max-width: 1450px) {
    .delivery-info__inner > ul {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin-bottom: 20px;
    }
}

.delivery-info__inner > ul li {
    margin-bottom: 5px;
}

.delivery-info__inner > ul li:last-of-type {
    margin-bottom: 0;
}

.delivery-info__inner > ul li span {
    display: block;
}

.delivery-info__text {
    font-size: 13px;
    color: #8b979e;
    margin-bottom: 5px;
}

.delivery-info__item {
    margin-bottom: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.delivery-info__item .select {
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #768187;
    padding: 0px 20px;
}

.delivery-info__item .select::after {
    right: 5px;
    height: 5px;
    width: 5px;
    border-bottom: 1px solid #768187;
    border-right: 1px solid #768187;
}

.delivery-info__item .select .select__current {
    border-bottom: 1px dotted #768187;
    display: table;
}

.delivery-info__item .select .select__list {
    -webkit-box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.12);
    box-shadow: 0px 6px 30px 0px rgba(47, 74, 90, 0.12);
    background: #fff;
    min-width: 135px;
    max-height: 220px;
    overflow-y: auto;
    border-radius: 0;
    left: auto;
    right: 0;
    margin-top: 100%;
}

.delivery-info__item .select .select__item {
    font-size: 14px;
    color: #768187;
    text-align: right;
    padding: 7px 30px 7px 15px;
}

.delivery-info__item .select .select__item--active {
    background: #fff;
}

.payment-info__inner,
.guarantee-info__inner {
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 15px;
}

.payment-info .text,
.guarantee-info .text {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
    font-size: 14px;
    color: #8b979e;
}

.together-products {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 60px;
}

@media only screen and (max-width: 1599px) {
    .together-products {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .together-products {
        padding: 0;
        margin: 0 auto 55px;
    }
}

.together-products__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

@media only screen and (max-width: 1249px) {
    .together-products__list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media only screen and (max-width: 767px) {
    .together-products__list {
        padding-top: 7px;
        border-top: 1px solid #e1e6e9;
    }
}

.together-products__block {
    position: relative;
    max-width: 370px;
    width: 100%;
    -ms-flex-preferred-size: 370px;
    flex-basis: 370px;
    border: 1px solid #e1e6e9;
    border-right: none;
}

.together-products__block .slick-dots {
    bottom: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.together-products__block .slick-dots li {
    width: 6px;
    height: 6px;
    margin: 0 5px;
}

.together-products__block .slick-dots li:first-of-type {
    margin-left: 0;
}

.together-products__block .slick-dots li:last-of-type {
    margin-right: 0;
}

.together-products__block .slick-dots li button {
    padding: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #e1e6e9;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.together-products__block .slick-dots li button::before {
    display: none;
}

.together-products__block .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

@media only screen and (max-width: 1249px) {
    .together-products__block {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        max-width: 100%;
        border-right: 1px solid #e1e6e9;
        border-bottom: none;
    }
}

@media only screen and (max-width: 767px) {
    .together-products__block {
        border: none;
    }

    .together-products__block.slick-dotted.slick-slider {
        margin-bottom: 0;
    }
}

.together-products__block::after {
    content: '+';
    position: absolute;
    right: -19px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: #eff2f4;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 22px;
    
    color: #333;
}

@media only screen and (max-width: 1249px) {
    .together-products__block::after {
        top: auto;
        bottom: -19px;
        left: 50%;
        -webkit-transform: translateY(0) translateX(-50%);
        transform: translateY(0) translateX(-50%);
    }
}

@media only screen and (max-width: 767px) {
    .together-products__block::after {
        border-radius: 0;
        width: 100%;
        height: 33px;
        position: static;
        -webkit-transform: none;
        transform: none;
    }
}

.together-products__block:nth-child(3)::after {
    content: '=';
}

.together-products__block .product-page__button {
    background: #eff2f4;
}

@media only screen and (max-width: 1249px) {
    .together-products__block .product-page__button svg {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@media only screen and (min-width: 1025px) {
    .together-products__block .product-page__button:hover {
        background: #c0cad0;
    }

    .together-products__block .product-page__button:hover svg {
        fill: #fff;
    }
}

.together-products__block .product-page__prev-btn {
    top: 0;
}

@media only screen and (max-width: 1249px) {
    .together-products__block .product-page__prev-btn {
        width: 25px;
        height: 100%;
        left: 0;
        -webkit-transform: none;
        transform: none;
    }
}

.together-products__block .product-page__next-btn {
    bottom: 0;
    left: auto;
}

@media only screen and (max-width: 1249px) {
    .together-products__block .product-page__next-btn {
        width: 25px;
        height: 100%;
        right: 0;
        -webkit-transform: none;
        transform: none;
    }
}

@media only screen and (max-width: 767px) {
    .together-products__block.slick-slider.slick-dotted .together-products__item.slick-slide {
        -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.06);
        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.06);
        background: #fff;
        margin: 20px 10px 35px;
    }
}

.together-products__item {
    padding: 55px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex !important;
}

@media only screen and (max-width: 1249px) {
    .together-products__item {
        padding: 13px 0;
    }
}

@media only screen and (max-width: 767px) {
    .together-products__item {
        padding: 13px 20px 13px 30px;
    }
}

.together-products__image {
    position: relative;
    margin-right: 20px;
}

.together-products__info {
    max-width: 175px;
    width: 100%;
    -ms-flex-preferred-size: 175px;
    flex-basis: 175px;
}

@media only screen and (max-width: 1249px) {
    .together-products__info {
        max-width: 375px;
        -ms-flex-preferred-size: 375px;
        flex-basis: 375px;
    }
}

.together-products__title {
    max-width: 150px;
    width: 100%;
    font-size: 13px;
    
    color: #333;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1249px) {
    .together-products__title {
        max-width: 100%;
        margin-bottom: 10px;
    }
}

.together-products__title a {
    color: #333;
    text-decoration: none;
}

.together-products__rate {
    margin-bottom: 15px;
}

@media only screen and (max-width: 1249px) {
    .together-products__rate {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin: 0 15px 0 0;
    }
}

@media only screen and (max-width: 767px) {
    .together-products__rate {
        display: none;
    }
}

.together-products__price-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

@media only screen and (max-width: 1249px) {
    .together-products__price-box {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}

.together-products__price-new {
    font-size: 15px;
    
    color: #ec4a9b;
}

.together-products__price-current {
    font-size: 15px;
    
    color: #333;
}

.together-products__price-old {
    font-size: 12px;
    
    color: #818d94;
    text-decoration: line-through;
    margin-left: 12px;
}

.together-products__results {
    position: relative;
    max-width: 370px;
    width: 100%;
    -ms-flex-preferred-size: 370px;
    flex-basis: 370px;
    border: 1px solid #e1e6e9;
    padding: 50px 45px 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 1249px) {
    .together-products__results {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        padding: 30px 45px;
    }

    .together-products__results .product-page__buy {
        max-width: 270px;
    }
}

@media only screen and (max-width: 767px) {
    .together-products__results {
        border: none;
        padding: 30px 25px 0;
    }

    .together-products__results .product-page__buy {
        margin-bottom: 0;
    }
}

.together-products__results-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 25px;
}

.together-products__results-price .together-products__price-current,
.together-products__results-price .together-products__price-new {
    font-size: 18px;
}

.together-products__results-price .together-products__price-old {
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .together-products__results-price {
        -webkit-box-ordinal-group: -1;
        -ms-flex-order: -2;
        order: -2;
        margin: 0 auto 10px;
    }
}

.together-products__code {
    font-size: 13px;
    color: #b6c0c6;
    margin: auto auto 0;
}

.together-products__code span {
    font-size: 14px;
    
}

@media only screen and (max-width: 767px) {
    .together-products__code {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
        margin: 0 auto 15px;
    }
}

.together-products__label {
    position: absolute;
    right: 0;
    top: 0;
    background: #ec4a9b;
    font-size: 12px;
    color: #fff;
    padding: 1px 6px;
    border-radius: 4px;
}

@media only screen and (max-width: 767px) {
    .together-products__label {
        top: auto;
        bottom: -10px;
        font-size: 10px;
        padding: 1px 5px;
    }
}

@media only screen and (max-width: 767px) {
    .product-acardion__inner {
        height: auto;
        overflow: hidden;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }
}

.product-block {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 110px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1599px) {
    .product-block {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .product-block {
        padding: 0;
        margin: 0 auto 20px;
    }
}

#recommended-products-slider .popular-products {
    margin-bottom: 0px;
}

.product-block__main #recommended-products-slider {
    border: 1px solid #e1e6e9;
    border-bottom: none;
    padding-top: 75px;
    padding-bottom: 20px;
}

.product-block__main {
    max-width: 1080px;
    width: 100%;
    -ms-flex-preferred-size: 1080px;
    flex-basis: 1080px;
    margin-right: 20px;
    min-width: 0;
}

@media only screen and (max-width: 1023px) {
    .product-block__main {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .product-block__main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.product-block__sidebar {
    max-width: 380px;
    width: 100%;
    -ms-flex-preferred-size: 380px;
    flex-basis: 380px;
    min-width: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 1450px) {
    .product-block__sidebar {
        max-width: 320px;
        -ms-flex-preferred-size: 320px;
        flex-basis: 320px;
    }
}

@media only screen and (max-width: 1023px) {
    .product-block__sidebar {
        display: none;
    }
}

.product-block__sidebar .inner-wrapper-sticky {
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
}

.product-block__item {
    border: 1px solid #e1e6e9;
    border-bottom: none;
}

.product-block__item.active .product-block__title {
    border-bottom: 1px solid #e1e6e9;
}

.product-block__item.active .product-block__title::after {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.product-block__item.tools .description-block:nth-child(2) .description-block__thumb {
    display: none;
}

.product-block__title {
    font-size: 26px;
    
    text-transform: uppercase;
    color: #333;
    padding: 75px 55px 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 1023px) {
    .product-block__title {
        padding: 30px 30px 20px;
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .product-block__title {
        font-size: 18px;
        padding: 16px 10px;
    }

    .product-block__title .add-reviews__btn {
        display: none;
    }

    .product-block__title.product-acardion__title {
        position: relative;
    }

    .product-block__title.product-acardion__title::after {
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 15px;
        top: 50%;
        -webkit-transform-origin: 66% 66%;
        transform-origin: 66% 66%;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
    }
}

.product-block__description {
    padding: 0 55px 90px;
    font-size: 14px;
    color: #333;
    line-height: 1.4;
}

@media only screen and (max-width: 1023px) {
    .product-block__description {
        padding: 0 30px 30px;
    }
}

@media only screen and (max-width: 767px) {
    .product-block__description {
        background: #f6f6f6;
        padding: 20px 10px 15px;
    }
}

.product-block__characteristic {
    padding: 0 55px 72px;
}

.product-block__characteristic table {
    width: 100% !important;
    border-collapse: collapse;
}

.product-block__characteristic table tr td {
    font-size: 14px;
    line-height: 23px;
    color: #333;
    padding: 14px 30px;
    width: 50%;
}

.product-block__characteristic table tr td:nth-child(even) {
    
}

.product-block__characteristic table tr:nth-child(odd) {
    background: #f5f7f7;
}

@media only screen and (max-width: 1023px) {
    .product-block__characteristic {
        padding: 0 30px 5px;
    }
}

@media only screen and (max-width: 767px) {
    .product-block__characteristic {
        background: #f6f6f6;
        padding: 20px 10px 15px;
    }

    .product-block__characteristic table tr td {
        padding: 5px 5px;
        font-size: 14px;
        line-height: 16px;
    }
}

.product-block__video {
    padding: 0 75px 75px 55px;
}

@media only screen and (max-width: 1023px) {
    .product-block__video {
        padding: 0 30px 30px;
    }
}

@media only screen and (max-width: 767px) {
    .product-block__video {
        padding: 15px 10px 50px;
        background: #fafafa;
    }
}

.product-block__video-subtitle {
    font-size: 16px;
    
    text-transform: uppercase;
    color: #768187;
    margin-bottom: 24px;
    margin-top: -20px;
}

@media only screen and (max-width: 767px) {
    .product-block__video-subtitle {
        font-size: 14px;
        margin-top: 0;
    }
}

.product-block__lg-slider {
    margin-bottom: 18px;
}

.product-block__lg-slider .slick-dots {
    bottom: -35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.product-block__lg-slider .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.product-block__lg-slider .slick-dots li:first-of-type {
    margin-left: 0;
}

.product-block__lg-slider .slick-dots li:last-of-type {
    margin-right: 0;
}

.product-block__lg-slider .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-block__lg-slider .slick-dots li button::before {
    display: none;
}

.product-block__lg-slider .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

.product-block__lg-slide iframe {
    width: 100%;
    height: 440px;
    border: none;
}

@media only screen and (max-width: 767px) {
    .product-block__lg-slide iframe {
        height: 180px;
    }

    iframe {
        height: auto;
        width: auto;
    }
}

.product-block__sm-slider {
    margin: 0 -10px;
}

@media only screen and (max-width: 991px) {
    .product-block__sm-slider {
        margin: 0 20px;
    }
}

@media only screen and (max-width: 767px) {
    .product-block__sm-slider {
        display: none;
    }
}

.product-block__sm-slide {
    max-width: 222px;
    width: 100%;
    -ms-flex-preferred-size: 222px;
    flex-basis: 222px;
    margin: 0 10px;
}

.product-block__sm-slide-title {
    font-size: 12px;
    
    color: #768187;
    text-align: center;
    padding: 10px 15px 0;
}

.product-block__arrow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 3;
    cursor: pointer;
}

.product-block__arrow svg {
    fill: #b2c0c9;
    width: 10px;
    height: 20px;
}

@media only screen and (max-width: 991px) {
    .product-block__arrow {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background: #c0cad0;
        width: 22px;
        height: 100%;
        top: 0;
        -webkit-transform: none;
        transform: none;
    }

    .product-block__arrow svg {
        fill: #e3e7ea;
    }
}

.product-block__prev-btn {
    left: -20px;
}

@media only screen and (max-width: 1023px) {
    .product-block__prev-btn {
        left: -10px;
    }
}

@media only screen and (max-width: 991px) {
    .product-block__prev-btn {
        left: -20px;
    }
}

.product-block__next-btn {
    right: -20px;
}

@media only screen and (max-width: 1023px) {
    .product-block__next-btn {
        right: -10px;
    }
}

@media only screen and (max-width: 991px) {
    .product-block__next-btn {
        right: -20px;
    }
}

@media only screen and (max-width: 1023px) {
    .product-block--consumables .description-block {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .product-block--consumables .description-block__content {
        max-width: 380px;
    }
}

@media only screen and (max-width: 767px) {
    .product-block--consumables #related {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .product-block--consumables #similar {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .product-block--consumables #description {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

.related-products__list {
    padding: 26px 50px;
    border-top: 1px solid #e1e6e9;
}

.related-products__list.slick-dotted.slick-slider {
    margin-bottom: 0;
}

@media only screen and (max-width: 1249px) {
    .related-products__list {
        padding: 26px 20px;
    }
}

@media only screen and (max-width: 1023px) {
    .related-products__list {
        padding: 26px 20px 80px;
        border-top: none;
    }
}

@media only screen and (max-width: 767px) {
    .related-products__list {
        padding: 26px 0 75px;
    }
}

.related-products__list .product__item .product__info-list {
    display: none;
}

.related-products__list .product__item .product__image {
    padding: 30px;
    height: 232px;
}

.related-products__list .product__item .product__title {
    font-size: 13px;
    margin-bottom: 12px;
}

.related-products__list .product__item .product__rate {
    margin-bottom: 20px;
}

@media only screen and (max-width: 1249px) {
    .related-products__list .product__item {
        margin: 0 5px;
    }

    .related-products__list .product__item .compare-btn,
    .related-products__list .product__item .wishlist-btn {
        margin: 0 5px;
    }

    .related-products__list .product__item .buy-btn {
        max-width: 125px;
    }
}

@media only screen and (max-width: 767px) {
    .related-products__list .product__item .product__image {
        padding: 20px;
        height: 172px;
    }

    .related-products__list .product__item .product__title {
        font-size: 12px;
    }

    .related-products__list .product__item .product__rate {
        margin-bottom: 10px;
    }
}

.related-products__list .slick-dots {
    bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.related-products__list .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.related-products__list .slick-dots li:first-of-type {
    margin-left: 0;
}

.related-products__list .slick-dots li:last-of-type {
    margin-right: 0;
}

.related-products__list .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.related-products__list .slick-dots li button::before {
    display: none;
}

.related-products__list .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

.related-products__btn {
    position: absolute;
    top: 0;
    width: 22px;
    height: 100%;
    background: #eff2f4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.related-products__btn svg {
    width: 5px;
    height: 11px;
    fill: #a6b2b8;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .related-products__btn:hover {
        background: #c0cad0;
    }

    .related-products__btn:hover svg {
        fill: #fff;
    }
}

.related-products__prev-btn {
    left: 0;
}

.related-products__next-btn {
    right: 0;
}

.reviews-block {
    padding: 0 60px 60px;
}

.reviews-block .add-reviews__btn {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .reviews-block {
        padding: 0 30px 30px;
    }
}

@media only screen and (max-width: 767px) {
    .reviews-block {
        padding: 20px 10px 10px;
        background: #fafafa;
    }

    .reviews-block .add-reviews__btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0 auto 24px;
        max-width: 480px;
    }
}

.reviews-block__item {
    padding: 25px 32px 65px;
    background: #f7f9f9;
    border: 1px solid #e1e6e9;
    margin-bottom: 30px;
}

#reviews_section .reviews-block__item{
    border: none !important;
}

#reviews_section .item_reviews-block{
    border: 1px solid #e1e6e9;
    gap: 0px !important;
    margin-bottom: 20px;
}

#reviews_section .product__item{
    margin: 0 20px 40px;
}

@media only screen and (max-width: 767px) {
    .reviews-block__item {
        padding: 15px 10px;
    }
}

.reviews-block__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 16px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.reviews-block__buy {
    width: 100%;
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    margin-top: 8px;
    color: #333;
}

.reviews-block__buy span {
    
    color: #333;
}

.reviews-block__name {
    font-size: 14px;
    
    color: #333;
    margin-right: 15px;
}

.reviews-block__date {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #333;
}

.reviews-block__rate {
    margin-left: auto;
}

.reviews-block__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 18px;
}

.reviews-block__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid #e1e6e9;
    padding-top: 18px;
}

.reviews-block__bottom-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.reviews-block__bottom-like {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    color: #333;
}

.reviews-block__bottom-like::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 1px;
    height: 20px;
    background: #e4e6e6;
}

.reviews-block__bottom-like svg {
    width: 16px;
    height: 16px;
    fill: #7f919c;
    margin-right: 5px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.reviews-block__bottom-like a {
    margin: 0 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
}

.reviews-block__bottom-like a:first-of-type {
    margin-left: 0;
}

.reviews-block__bottom-like a:last-of-type {
    margin-right: 0;
}

.reviews-block__bottom-like a span {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #333;
}

@media only screen and (min-width: 1025px) {
    .reviews-block__bottom-like a:hover > svg {
        fill: #ec4a9b;
    }
}

.reviews-block__reply {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    margin-right: 20px;
}

.reviews-block__reply svg {
    width: 19px;
    height: 11px;
    fill: #778b96;
    margin-right: 8px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.reviews-block__reply span {
    font-size: 13px;
    color: #333;
    border-bottom: 1px dotted #778b96;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .reviews-block__reply:hover span {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }

    .reviews-block__reply:hover svg {
        fill: #ec4a9b;
    }
}

.reviews-block__answer {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
}

.reviews-block__answer svg {
    width: 15px;
    height: 15px;
    fill: #778b96;
    margin-right: 8px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.reviews-block__answer span {
    font-size: 13px;
    color: #333;
    border-bottom: 1px dotted #778b96;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .reviews-block__answer:hover span {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }

    .reviews-block__answer:hover svg {
        fill: #ec4a9b;
    }
}

.reviews-block__answer.no-answers {
    display: none;
}

.reviews-block__sublist {
    background: #fff;
    margin-top: 25px;
    padding: 15px 40px;
    overflow: hidden;
}

@media only screen and (max-width: 767px) {
    .reviews-block__sublist {
        padding: 15px 20px;
    }
}

.reviews-block__subitem {
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e6e9;
    margin-bottom: 25px;
    padding: 14px;
}

.reviews-block__subitem:last-of-type {
    padding-bottom: 15px;
    margin-bottom: 0;
    border-bottom: none;
}

.reviews-block__subitem-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.reviews-block__subitem-name {
    font-size: 14px;
    
    color: #333;
    margin-right: 10px;
}

.reviews-block__subitem-date {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #333;
}

.reviews-block__subitem-text {
    font-size: 14px;
    color: #333;
}

.reviews-block__video {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.reviews-block__video-item {
    cursor: pointer;
    margin: 0 9px;
}

.reviews-block__video-item:first-of-type {
    margin-left: 0;
}

.reviews-block__video-item:last-of-type {
    margin-right: 0;
}

.reviews-block__link {
    max-width: 100%;
    width: 100%;
    height: 52px;
    border: 3px solid #e1e6e9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    color: #778b96;
    margin-bottom: 70px;
    text-decoration: none;
}

.reviews-block__link span {
    color: #a5b1b8;
    margin-left: 5px;
}

.reviews-block__login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 45px;
}

@media only screen and (max-width: 767px) {
    .reviews-block__login {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.reviews-block__login-left {
    max-width: 406px;
    width: 100%;
    -ms-flex-preferred-size: 406px;
    flex-basis: 406px;
}

.reviews-block__login-left .login-popup__bottom {
    max-width: 220px;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .reviews-block__login-left {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto 30px;
    }

    .reviews-block__login-left .login-popup__bottom,
    .reviews-block__login-left .submit-btn {
        margin: 0 auto;
    }
}

.reviews-block__login-title {
    font-size: 18px;
    
    color: #333;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.reviews-block__login-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 30px;
}

.reviews-block__login-form .form-block {
    margin-bottom: 25px;
}

.reviews-block__login-form .form-block .main-input {
    height: 44px;
}

.reviews-block__login-form .form-block__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 35px;
}

.reviews-block__login-form .submit-btn {
    font-family: 'Open Sans', serif;
    max-width: 220px;
    height: 45px;
}

.reviews-block__login-either {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 15px;
}

.reviews-block__login-either::before, .reviews-block__login-either::after {
    content: '';
    display: inline-block;
    background: #efeff0;
    height: 140px;
    width: 1px;
}

.reviews-block__login-either span {
    font-size: 16px;
    
    color: #333;
    padding: 20px 0;
}

@media only screen and (max-width: 767px) {
    .reviews-block__login-either {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        width: 100%;
        margin: 0 auto 30px;
    }

    .reviews-block__login-either::before, .reviews-block__login-either::after {
        width: 100%;
        height: 1px;
    }

    .reviews-block__login-either span {
        padding: 0 20px;
    }
}

.reviews-block__login-right {
    max-width: 406px;
    width: 100%;
    -ms-flex-preferred-size: 406px;
    flex-basis: 406px;
    padding-left: 50px;
}

@media only screen and (max-width: 767px) {
    .reviews-block__login-right {
        padding: 0;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        text-align: center;
    }
}

.reviews-block__photo-item {
    cursor: pointer;
    max-width: 226px;
    width: 100%;
    -ms-flex-preferred-size: 226px;
    flex-basis: 226px;
    margin: 0 12px 24px;
}

.reviews-block__photo-item img {
    margin-bottom: 12px;

}

.reviews-block__photo-item .reviews-block__video-title {
    font-size: 12px;
    
    color: #333;
    text-align: center;
    margin-bottom: 10px;
    padding: 0 5px;
    max-height: 39px;
    overflow: hidden;
}

.reviews-block__photo-item .reviews-block__video-date {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #333;
    text-align: center;
    pointer-events: none;
}

.reviews-block__photo {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.reviews-block__photo-item {
    cursor: pointer;
    margin: 0 9px;
}

.reviews-block__photo-item:first-of-type {
    margin-left: 0;
}

.reviews-block__photo-item:last-of-type {
    margin-right: 0;
}

.reviews-block__add-answer {
    margin-top: 15px;
    background: #fff;
    padding: 30px 40px;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.04);
}

#reviews_section .product-acardion__inner{

}

#reviews_section .product-page__reviews, .product__item .product-page__reviews{
    margin-bottom: 10px;
}

#reviews_section{
    border: none !important;
    max-width: 1480px;
    margin: 0 auto;
}

#reviews_section .reviews-block{
    padding: 0 !important;
}

#reviews_section .product__image{
    border: none !important;
}

#reviews_section .reviews-block__item{
    background: none !important;
}

.item_reviews-block{
    display: flex;
    gap: 20px;
}

.item_reviews-block .product__item {
    flex: 1;
}

.item_reviews-block .reviews-block__item {
    flex: 1;
    align-self: flex-start;
}

@media (max-width: 768px) {
    .item_reviews-block {
        flex-direction: column;
    }

    .item_reviews-block .product__item,
    .item_reviews-block .reviews-block__item {
        flex: none;
        width: 100%;
        max-width: none !important;
        margin: 0;
        padding: 0 20px 40px;
    }

    .item_reviews-block iframe{
        max-height: 78px !important;
    }
}

#reviews h2 {
    font-size: 19px;
}

.add-answer__form .form-block {
    margin-bottom: 26px;
}

.add-answer__form .form-block__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 -12px 26px;
}

.add-answer__form .form-block__row .form-block {
    width: 100%;
    margin: 0 12px;
}

.add-answer__form .form-block__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.add-answer__form .form-block__buttons .submit-btn {
    max-width: 300px;
    background: #9baab3;
}

@media only screen and (min-width: 1025px) {
    .add-answer__form .form-block__buttons .submit-btn:hover {
        background: #ec4a9b;
    }
}

.add-answer__form .form-block__buttons .forgot-password {
    margin-left: 24px;
}

.socials-login {
    max-width: 290px;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .socials-login {
        margin: 0 auto;
    }
}

.socials-login__title {
    font-size: 14px;
    
    color: #596368;
    margin-bottom: 5px;
}

.socials-login__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
}

.socials-login__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .socials-login__list {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.socials-login__item {
    margin: 0 13px;
}

.socials-login__item:first-of-type {
    margin-left: 0;
}

.socials-login__item:last-of-type {
    margin-right: 0;
}

.socials-login__item svg {
    fill: #8b979e;
    width: 22px;
    height: 22px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .socials-login__item:hover svg {
        fill: #ec4a9b;
    }
}

.add-reviews {
    max-width: 400px;
    width: 100%;
    margin: 0 auto 40px;
}

.add-reviews__title {
    font-size: 18px;
    
    color: #333;
    text-transform: uppercase;
    margin-bottom: 15px;
    text-align: center;
}

.add-reviews__person {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 25px;
}

.add-reviews__avatar {
    width: 30px;
    height: 30px;
    border: 1px solid #e2e6e9;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 10px;
}

.add-reviews__name {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
}

.add-reviews__form .form-block {
    margin-bottom: 25px;
}

.add-reviews__form .form-block .main-input {
    height: 44px;
}

.add-reviews__form .form-media {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
}

.add-reviews__form .form-media::before {
    content: '';
    height: 10px;
    width: 1px;
    background: #e1e6e9;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.success-form-send-text{
    text-align: center;
    color: #61b149;
}

.answer-text-block_consultant{
    background: #fdeef6;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.answer-text-block_consultant .reviews-block__subitem-logo img{
    max-width: 39px;
    margin-right: 12px;
}

.answer-name-block_consultant{
    color: #d13138;
}

.add-reviews__form .submit-btn {
    font-family: 'Open Sans', serif;
    background: #9baab3;
    max-width: 170px;
    width: 100%;
    height: 45px;
    margin: 0 auto;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    margin-top: 25px;
}

@media only screen and (min-width: 1025px) {
    .add-reviews__form .submit-btn:hover {
        background: #ec4a9b;
    }
}

.add-reviews__rate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 28px;
}

.add-reviews__rate .rate-text {
    font-size: 13px;
    color: #333;
    margin-right: 25px;
}

.add-reviews__rate .rate__star {
    cursor: pointer;
}

.add-reviews__rate .rate__star svg {
    width: 30px;
    height: 30px;
}

.add-photo {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #778b96;
    font-size: 11px;
    margin-right: 40px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (max-width: 767px) {
    .add-photo {
        margin-right: 20px;
    }
}

.add-photo svg {
    fill: #778b96;
    width: 20px;
    height: 17px;
    margin-right: 14px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .add-photo:hover {
        color: #ec4a9b;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }

    .add-photo:hover svg {
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        fill: #ec4a9b;
    }
}

.add-video {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #778b96;
    font-size: 11px;
    margin-left: 40px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (max-width: 767px) {
    .add-video {
        margin-left: 20px;
    }
}

.add-video svg {
    fill: #778b96;
    width: 19px;
    height: 15px;
    margin-right: 14px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .add-video:hover {
        color: #ec4a9b;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }

    .add-video:hover svg {
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        fill: #ec4a9b;
    }
}

.similar-products {
    border: 1px solid #e1e6e9;
    border-top: none;
}

@media only screen and (max-width: 767px) {
    .similar-products {
        border-bottom: none;
    }
}

.similar-products__title {
    padding: 0 50px;
    margin-bottom: 30px;
    font-size: 26px;
    color: #333;
    font-size: 26px;
    
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .similar-products__title {
        font-size: 18px;
        padding: 16px 10px;
        padding-bottom: 0;
        text-align: center;
        margin-bottom: 0;
    }
}

.add-reviews__btn {
    max-width: 186px;
    width: 100%;
    height: 40px;
    font-size: 13px;
    
    text-transform: uppercase;
    background: transparent;
    border: 1px solid #c3ced5;
    text-decoration: none;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #333;
    margin-left: auto;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .add-reviews__btn:hover {
        background: #333;
        color: #fff;
        border-color: #333;
    }
}

.product-nav__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

@media only screen and (max-width: 1023px) {
    .product-nav__list {
        -webkit-box-shadow: none;
        box-shadow: none;
        padding-top: 20px;
        border-top: 1px solid #e1e6e9;
    }
}

.product-nav__item {
    position: relative;
    border-bottom: 1px solid #e1e6e9;
}

.product-nav__item::before {
    position: absolute;
    left: 35px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: '';
    width: 0;
    height: 2px;
    background: #9baab3;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-nav__item a {
    display: block;
    padding: 16px 35px;
    font-size: 14px;
    color: #333;
    text-transform: uppercase;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.product-nav__item.active::before {
    width: 30px;
}

.product-nav__item.active a {
    color: #333;
    
    padding-left: 74px;
}

@media only screen and (min-width: 1025px) {
    .product-nav__item:hover::before {
        width: 30px;
    }

    .product-nav__item:hover a {
        padding-left: 74px;
    }
}

.sidebar-products__item {
    padding: 40px 30px 28px;
}

@media only screen and (max-width: 1450px) {
    .sidebar-products__item {
        padding: 40px 15px 28px;
    }
}

.sidebar-products__item .product-page__buttons {
    margin: 0 auto;
}

.sidebar-products__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sidebar-products__image {
    max-width: 117px;
    width: 100%;
    -ms-flex-preferred-size: 117px;
    flex-basis: 117px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 15px;
}

.sidebar-products__info {
    max-width: 182px;
    width: 100%;
    -ms-flex-preferred-size: 182px;
    flex-basis: 182px;
}

.sidebar-products__title {
    max-width: 140px;
    font-size: 13px;
    
    color: #333;
    margin-bottom: 5px;
}

.sidebar-products__title a {
    color: #333;
    text-decoration: none;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .sidebar-products__title a:hover {
        color: #ec4a9b;
    }
}

.sidebar-products__price-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sidebar-products__price-current {
    font-size: 15px;
    
    color: #333;
}

.sidebar-products__price-new {
    font-size: 15px;
    
    color: #ec4a9b;
}

.sidebar-products__price-old {
    font-size: 12px;
    
    color: #333;
    text-decoration: line-through;
    margin-left: 8px;
}

.sidebar-products__rate {
    margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
    #description {
        -webkit-box-ordinal-group: -3;
        -ms-flex-order: -4;
        order: -4;
    }
}

@media only screen and (max-width: 767px) {
    #characteristic {
        -webkit-box-ordinal-group: -2;
        -ms-flex-order: -3;
        order: -3;
    }
}

@media only screen and (max-width: 767px) {
    #video {
        -webkit-box-ordinal-group: -1;
        -ms-flex-order: -2;
        order: -2;
    }
}

@media only screen and (max-width: 767px) {
    #reviews {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }
}

@media only screen and (max-width: 1023px) {
    #related .product-block__title {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-bottom: 0;
    }
}

.viewed-products {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 120px;
}

@media only screen and (max-width: 1599px) {
    .viewed-products {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .viewed-products {
        padding: 0;
    }
}

.viewed-products__title {
    font-size: 26px;
    
    text-transform: uppercase;
    color: #333;
    text-align: center;
    margin: 0 auto 30px;
}

@media only screen and (max-width: 767px) {
    .viewed-products__title {
        font-size: 18px;
    }
}

.viewed-products__list .product__item .product__info-list {
    display: none;
}

.viewed-products__list .product__item .product__image {
    padding: 30px;
    height: 232px;
}

.viewed-products__list .product__item .product__title {
    font-size: 13px;
    margin-bottom: 12px;
}

.viewed-products__list .product__item .product__rate {
    margin-bottom: 20px;
}

@media only screen and (max-width: 1249px) {
    .viewed-products__list .product__item {
        margin: 0 5px;
    }

    .viewed-products__list .product__item .compare-btn,
    .viewed-products__list .product__item .wishlist-btn {
        margin: 0 5px;
    }

    .viewed-products__list .product__item .buy-btn {
        max-width: 125px;
    }
}

@media only screen and (max-width: 767px) {
    .viewed-products__list .product__item .product__image {
        padding: 20px;
        height: 172px;
    }

    .viewed-products__list .product__item .product__title {
        font-size: 12px;
    }

    .viewed-products__list .product__item .product__rate {
        margin-bottom: 10px;
    }
}

.viewed-products__list .slick-dots {
    bottom: -50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.viewed-products__list .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.viewed-products__list .slick-dots li:first-of-type {
    margin-left: 0;
}

.viewed-products__list .slick-dots li:last-of-type {
    margin-right: 0;
}

.viewed-products__list .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.viewed-products__list .slick-dots li button::before {
    display: none;
}

.viewed-products__list .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

@media only screen and (max-width: 1450px) {
    .viewed-products__list .main-catalog__prev-btn {
        left: -10px;
    }
}

@media only screen and (max-width: 1450px) {
    .viewed-products__list .main-catalog__next-btn {
        right: -10px;
    }
}

.viewed-products__subtitle {
    font-size: 15px;
    color: #768187;
    text-align: center;
    margin: -10px auto 25px;
}

@media only screen and (max-width: 767px) {
    .viewed-products__subtitle {
        padding: 0 15px;
    }
}

.view-kit {
    font-size: 12px;
    color: #515d64;
    text-decoration: none;
    border-bottom: 1px dotted #515d64;
    padding-bottom: 2px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .view-kit:hover {
        color: #ec4a9b;
        border-color: #ec4a9b;
    }
}

.composition-block {
    padding: 0 70px 85px;
}

@media only screen and (max-width: 1449px) {
    .composition-block {
        padding: 0 55px 85px;
    }
}

@media only screen and (max-width: 1023px) {
    .composition-block {
        padding: 0;
    }
}

.composition-block__text {
    font-size: 14px;
    
    color: #768187;
    margin: -20px 0 30px;
}

@media only screen and (max-width: 767px) {
    .composition-block__text {
        margin: 0 auto 18px;
        text-align: center;
        padding: 0 20px;
    }
}

.composition-block__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -20px;
}

@media only screen and (max-width: 1449px) {
    .composition-block__list {
        margin: 0 -9px;
    }
}

@media only screen and (max-width: 767px) {
    .composition-block__list {
        margin: 0;
        display: block;
    }
}

.composition-block__list .product__item {
    margin: 0 20px 40px;
}

@media only screen and (max-width: 1449px) {
    .composition-block__list .product__item {
        margin: 0 9px 40px;
        max-width: 31%;
        -ms-flex-preferred-size: 31%;
        flex-basis: 31%;
    }
}

@media only screen and (max-width: 1249px) {
    .composition-block__list .product__item {
        max-width: 46%;
        -ms-flex-preferred-size: 46%;
        flex-basis: 46%;
    }
}

@media only screen and (max-width: 1023px) {
    .composition-block__list .product__item {
        max-width: 30%;
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
    }
}

.composition-block__list.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.composition-block__list .slick-dots {
    bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.composition-block__list .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.composition-block__list .slick-dots li:first-of-type {
    margin-left: 0;
}

.composition-block__list .slick-dots li:last-of-type {
    margin-right: 0;
}

.composition-block__list .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.composition-block__list .slick-dots li button::before {
    display: none;
}

.composition-block__list .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

.composition-block__bottom {
    border-top: 1px solid #e1e6e9;
    padding-top: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@media only screen and (max-width: 1023px) {
    .composition-block__bottom {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .composition-block__bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-top: 0;
        border-top: none;
    }
}

.composition-block__bottom .product-page__buy {
    max-width: 320px;
    width: 100%;
    margin: 0;
}

@media only screen and (max-width: 767px) {
    .composition-block__bottom .product-page__buy {
        max-width: 300px;
    }
}

.composition-block__price {
    font-size: 20px;
    
    color: #333;
    margin-right: 45px;
}

@media only screen and (max-width: 767px) {
    .composition-block__price {
        margin: 0 auto 18px;
    }
}

#composition .product-block__title {
    padding: 75px 70px 25px;
}

@media only screen and (max-width: 1023px) {
    #composition .product-block__title {
        padding: 0 0 25px;
    }
}

@media only screen and (max-width: 767px) {
    #composition .product-block__title {
        margin: 0 auto;
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 1023px) {
    #composition {
        border: none;
    }
}

@media only screen and (max-width: 767px) {
    #composition {
        -webkit-box-ordinal-group: -4;
        -ms-flex-order: -5;
        order: -5;
    }
}

.description-block {
    background-size: 101% 101%;
    background-repeat: no-repeat;
    background-position: center;
    height: 549px;
    min-height: 550px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #e1e6e9;
}

@media only screen and (max-width: 767px) {
    .description-block {
        min-height: auto;
        background-image: none !important;
        padding: 20px 0;
    }
}

.description-block:last-of-type {
    border-bottom: none;
}

.description-block__up-title {
    font-size: 26px;
    text-transform: uppercase;
    font-weight: normal;
    color: #333;
    margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
    .description-block__up-title {
        font-size: 22px;
    }
}

@media only screen and (max-width: 992px) {
    .description-block__thumb {
        margin-bottom: 30px;
    }
}

.description-block__content {
    max-width: 480px;
    width: 100%;
    margin: 0 80px;
}

.description-block__content ul {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;
}

.description-block__content ul li {
    position: relative;
    padding-left: 10px;
    margin-bottom: 3px;
}

.description-block__content ul li::before {
    content: '•';
    position: absolute;
    left: 0;
    top: 0;
}

.description-block__subtitle {
    font-size: 24px;
    
    color: #697175;
}

@media only screen and (max-width: 767px) {
    .description-block__subtitle {
        font-size: 16px;
    }
}

.description-block__main-title {
    font-size: 72px;
    
    text-transform: uppercase;
}

@media only screen and (max-width: 1023px) {
    .description-block__main-title {
        font-size: 50px;
    }
}

@media only screen and (max-width: 767px) {
    .description-block__main-title {
        font-size: 30px;
        margin-bottom: 10px;
    }
}

.description-block__title {
    font-size: 42px;
    
}

@media only screen and (max-width: 1023px) {
    .description-block__title {
        font-size: 30px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .description-block__title {
        font-size: 25px;
        margin-bottom: 10px;
    }
}

.description-block__text {
    font-size: 14px;
    color: #697175;
    line-height: 1.5;
}

.description-block--reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

@media only screen and (max-width: 1400px) {
    .description-block {
        background-size: cover;
    }

    .description-block .description-block__content {
        background: rgba(255, 255, 255, 0.3);
        margin: 0 30px;
        padding: 0 15px;
    }
}

@media only screen and (max-width: 992px) {
    .description-block {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        height: auto;
    }
}

@media only screen and (max-width: 768px) {
    .description-block {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 767px) {
    .description-block .description-block__content {
        background: rgba(255, 255, 255, 0.5);
        margin: 0;
        padding: 0 10px;
    }
}

.view-3d {
    text-align: center;
    padding-bottom: 45px;
}

.equipment .description-block {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: auto;
    min-height: 0;
    padding: 0 15px;
}

.equipment .description-block__sub-title {
    font-size: 13px;
    color: #767676;
    margin-bottom: 15px;
}

.equipment .description-block__thumb {
    padding-top: 90px;
}

.equipment .description-block__thumb .description-block__main-title {
    margin-bottom: 5px;
}

.equipment .description-block__content {
    position: relative;
}

@media only screen and (max-width: 992px) {
    .equipment .description-block__content {
        padding: 30px 0;
    }
}

.equipment .description-block .decor-title {
    position: absolute;
    right: -30px;
    top: 0;
    font-size: 14px;
    font-weight: normal;
    color: #d3d6d9;
    -webkit-transform: translateX(100%) rotate(-90deg) translateX(-100%);
    transform: translateX(100%) rotate(-90deg) translateX(-100%);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.equipment .description-block .decor-title::after {
    content: '';
    display: block;
    width: 105px;
    margin-left: 30px;
    border-top: 1px solid #e8ecf0;
}

@media only screen and (max-width: 992px) {
    .equipment .description-block .decor-title {
        display: none;
    }
}

.equipment .description-block--reverse .decor-title {
    position: absolute;
    left: -30px;
    right: auto;
    top: 0;
    font-size: 14px;
    font-weight: normal;
    color: #d3d6d9;
    -webkit-transform: translateX(-100%) rotate(-90deg);
    transform: translateX(-100%) rotate(-90deg);
    -webkit-transform-origin: right center;
    transform-origin: right center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.equipment .description-block--reverse .decor-title::after {
    content: '';
    display: block;
    width: 105px;
    margin-left: 30px;
    border-top: 1px solid #e8ecf0;
}

@media only screen and (max-width: 992px) {
    .equipment .description-block--reverse .decor-title {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .equipment .description-block:nth-child(1) .description-block__thumb, .equipment .description-block:nth-child(2) .description-block__thumb {
        display: none;
    }
}

.description-tools {
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-bottom: 90px;
}

.description-tools__line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 12px 0;
}

.description-tools__line:last-child {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-top: 2px solid #aebbc4;
}

.description-tools__line:last-child .description-tools__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.description-tools__line:last-child .description-tools__item img {
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1);

}

.description-tools__line:last-child .description-tools__thumb {
    margin: 15px 0 0;
}

.description-tools__item {
    text-align: center;
    margin-right: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 57px;
}

@media only screen and (max-width: 767px) {
    .description-tools__item {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 50px;
        margin-right: 10px;
    }
}

.description-tools__title {
    font-size: 13px;
    color: #8b979e;
}

@media only screen and (max-width: 767px) {
    .description-tools__title {
        font-size: 11px;
    }
}

.description-tools__text {
    font-size: 13px;
    font-weight: normal;
    color: #5d6b73;
}

@media only screen and (max-width: 767px) {
    .description-tools__text {
        font-size: 10px;
    }
}

.description-tools__thumb {
    margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
    .description-tools {
        overflow-x: auto;
    }
}

.tools-list {
    position: relative;
    padding: 70px 60px;
}

.tools-list::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 140px;
    top: -50px;
    left: 0;
    border-top: 10px solid #f7f8f9;
    background: #fff;
    -webkit-transform: skewY(5deg);
    transform: skewY(5deg);
}

@media only screen and (max-width: 992px) {
    .tools-list::before {
        display: none;
    }
}

.tools-list__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 992px) {
    .tools-list__row {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 1249px) {
    .tools-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 767px) {
    .tools-list {
        padding: 50px 20px;
    }
}

.tools-item {
    position: relative;
}

.tools-item .description-block__sub-title {
    font-size: 13px;
    color: #767676;
    margin-bottom: 30px;
}

.tools-item .description-tools__line {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.tools-item__thumb {
    margin-bottom: 25px;
}

.tools-item:nth-child(2n) {
    top: 40px;
}

@media only screen and (max-width: 992px) {
    .tools-item:nth-child(2n) {
        top: 0;
    }
}

.tools-item:nth-child(3n) {
    top: 80px;
}

@media only screen and (max-width: 992px) {
    .tools-item:nth-child(3n) {
        top: 0;
    }
}

.product__item {
    max-width: 280px;
    width: 100%;
    -ms-flex-preferred-size: 280px;
    flex-basis: 280px;
    margin: 0 9px 40px;
}

.product__item.slick-slide {
    height: 100%;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.product__item.slick-slide .product__image {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.product__item.slick-slide .product__info {
    min-height: 172px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.product__item.slick-slide .product__buttons {
    margin-top: auto;
}

.product__item.product__item--special {
    position: relative;
    height: auto;
}

@media only screen and (max-width: 767px) {
    .product__item--special {
        height: auto;
    }
}

.product__item--special .product__image {
    padding: 0;
    height: auto;
    margin: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .product__item--special .product__image a > img {
        max-width: 100%;

    }
}

.product__item--special .product__info {
    height: auto;
    background: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 15px 10px;
    z-index: 2;
}

.product__item--special .product__info .product__title {
    height: auto;
}

.product__item--special .product__info .product__title a {
    height: 40px;
    max-height: 36px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

@media only screen and (max-width: 1599px) {
    .product__item--special .product__info {
        min-height: 170px;
    }
}

@media only screen and (max-width: 991px) {
    .product__item--special .product__info {
        min-height: 215px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 510px) {
    .product__item--special .product__info {
        min-height: auto;
        padding: 11px 5px 11px;
    }
}

.product__item--special .product__title a {
    color: #fff;
}

.product__item--special .buy-btn {
    color: #fff;
    border: 1px solid #fff;
}

.product__item--special .buy-btn svg {
    fill: #fff;
}

@media only screen and (max-width: 767px) {
    .product__item {
        margin: 0 5px 30px;
    }
}

@media only screen and (max-width: 510px) {
    .product__item {
        min-height: 350px;
    }
}

.product__image {
    position: relative;
    width: 100%;
    height: 280px;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #e1e6e9;
    margin-bottom: 20px;
}

swiper-container {
    height: 100%;
}

swiper-container::part(bullet-active) {
    background-color: #959795;
}

swiper-container,
swiper-container swiper-slide {
    width: 100%;
    display: flex;
}

swiper-container swiper-slide {
    justify-content: center;
    align-items: center;
}

swiper-container swiper-slide > img {
    width: 100%;
    height: auto;
}

swiper-container ::slotted(swiper-slide) {
    height: auto;
}

.nav-actions {
    height: 40px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: end;
    gap: 20px;
    margin-bottom: 20px;
}

.nav-actions .button-prev,
.nav-actions .button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.nav-actions .button-prev svg,
.nav-actions .button-next svg {
    color: #ec4a9b;
    width: auto;
    height: 25px;
}

.nav-actions .button-prev {

}

.nav-actions .button-next {

}

.product__image .nav-actions {
    position: absolute;
    bottom: 0;
    height: auto;
}

.product__image .nav-actions .button-prev svg,
.product__image .nav-actions .button-next svg {
    height: 15px;
}

@media only screen and (max-width: 767px) {
    .product__image {
        height: 272px;
        padding-left: 0;
        padding-right: 0;
    }
}

.product__image a {
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .product__image a > img {
        max-width: 160px;

    }
}

@media only screen and (-ms-high-contrast: active) and (max-width: 767px) {
    .product__image a > img {
        width: auto;
        max-width: 100%;

    }
}

.product__image .product-colors__box {
    position: absolute;
    left: 16px;
    bottom: 15px;
    z-index: 3;
}

@media only screen and (max-width: 767px) {
    .product__image .product-colors__box {
        left: 10px;
    }
}

@media only screen and (max-width: 480px) {
    .product__image .product-colors__box {
        left: 10px;
    }
}

.product__image .product-delivery {
    max-width: 198px;
    width: 100%;
    height: 38px;
    position: absolute;
    left: 0;
    bottom: 60px;
    background: #9baab3;
    z-index: 4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .product__image .product-delivery {
        display: none;
    }
}

.product__image .product-delivery__icon {
    margin-top: 3px;
    margin-right: 12px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.product__image .product-delivery__icon svg {
    fill: #fff;
    width: 30px;
    height: 17px;
}

.product__image .product-delivery__text {
    font-size: 14px;
    color: #fff;
}

.product__image .product-delivery__text span {
    display: block;
}

.product__image-inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 85px;
    overflow: hidden;
    z-index: 3;
    pointer-events: none;
}

.product__image-inner .product-label {
    position: absolute;
    left: -60px;
    top: -60px;
    width: 120px;
    height: 120px;
    color: #fff;
    text-align: center;
    line-height: 190px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 2;
}

.product__image-inner .product-label-detail {
    position: absolute;
    left: -60px;
    top: -60px;
    width: 120px;
    height: 120px;
    color: #fff;
    text-align: center;
    line-height: 190px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 2;
}
.product__image-inner .freeShipping {
    position: absolute;
    width: 55%;
    height: max-content;
    top: 10px;
    right: 10px;
    z-index: 2;
}
.product-page__main .product__image-inner .freeShipping {
    width: 40%;
}

.product__item .product__image-inner .freeShipping img {
    width: 100%;
}



@media only screen and (max-width: 767px) {
    .product__image-inner .product-label-detail {
        left: -50px;
        top: -50px;
        width: 100px;
        height: 100px;
        line-height: 170px;
        font-size: 15px;
    }

    .product__image-inner .product-label {
        left: -50px;
        top: -50px;
        width: 90px;
        height: 90px;
        line-height: 150px;
        font-size: 12px;
    }
}

.product__image-inner .product-label--new {
    text-transform: uppercase;
    background: #96d052;
}

.product__image-inner .product-label--sale {
    text-transform: uppercase;
    background: #e74d99;
}

.product__image-inner .product-label--top {
    text-transform: uppercase;
    background: #9175c9;
}

.product__image-inner .product-label--hot {
    background: #f3cc55;
}

.product__image-inner .product-sale {
    position: absolute;
    right: 0;
    left: -10px;
    top: 35px;
    max-width: 128px;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 100%;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    font-weight: normal;
    color: #333;
}

.product__image-inner .product-sale span {
    margin-left: 8px;
    font-size: 18px;
    
    color: #ec4a9b;
}

@media only screen and (max-width: 767px) {
    .product__image-inner .product-sale {
        font-size: 0;
        max-width: max-content;
        top: 29px;
        left: 19px;
    }

    .product__image-inner .product-sale span {
        font-size: 16px;
    }
}

.product__title {
    font-size: 15px;
    
    margin-bottom: 10px;
    height: 50px;
    overflow: hidden;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}

@media only screen and (max-width: 767px) {
    .product__title {
        font-size: 13px;
        height: 85px;
    }

    .product__item--special .product__title {
        height: 42px;
    }
}

.product__title a {
    color: #333;
    text-decoration: none;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .product__title a:hover {
        color: #ec4a9b;
    }
}

.product__price-box {
    margin-bottom: 16px;
}

.product__price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

@media only screen and (max-width: 767px) {
    .product__price {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.product__price-current {
    font-size: 18px;
    
    color: #333;
}

@media only screen and (max-width: 767px) {
    .product__price-current {
        font-size: 16px;
    }
}

.product__price-new {
    font-size: 18px;
    
    color: #ec4a9b;
}

@media only screen and (max-width: 767px) {
    .product__price-new {
        font-size: 16px;
    }
}

.product__price-old {
    font-size: 14px;
    
    color: #333;
    text-decoration: line-through;
    margin-left: 6px;
}

@media only screen and (max-width: 767px) {
    .product__price-old {
        font-size: 12px;
        margin-left: 0;
    }
}

.product__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product__info-list {
    list-style: none;
    margin: 10px 0 15px;
    padding: 0;
    height: 62px;
    overflow: hidden;
}

@media only screen and (max-width: 767px) {
    .product__info-list {
        display: none;
    }
}

.product__info-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.product__info-list li .text {
    font-size: 13px;
    color: #333;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 5px;
}

.product__info-list li .dots {
    -ms-flex-item-align: end;
    align-self: flex-end;
    border-bottom: 1px dotted #333;
    width: 100%;
    margin-bottom: 3px;
}

.product__info-list li .value {
    font-size: 13px;
    
    color: #333;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 5px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product__remove {
    cursor: pointer;
    background: #c0cad0;
    width: 100%;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.product__remove svg {
    fill: #fff;
    width: 10px;
    height: 10px;
    margin-right: 7px;
}

.product__remove.removed {
    background: #f1f1f1;
    color: #8b969d;
}

.product__remove.removed svg {
    fill: #8b969d;
    width: 15px;
    height: 10px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .product__remove:hover {
        background: #ec4a9b;
        color: #fff;
    }

    .product__remove:hover svg {
        fill: #fff;
    }
}

.buy-btn {
    max-width: 152px;
    width: 100%;
    height: 40px;
    border: 1px solid #ec4a9b;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px;
    
    color: #ec4a9b;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    margin-right: 10px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.buy-btn.not-avail {
    color: #333;
    border: 1px solid #c3ced5;
}

.buy-btn span {
    position: relative;
}

.buy-btn span > svg {
    fill: #ec4a9b;
    width: 21px;
    height: 20px;
    margin-right: 14px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.buy-btn.not-avail span > svg {
    fill: #707070;
}

.buy-btn .added {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    background: #ec4a9b;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    top: -3px;
    display: none;
}

.buy-btn .added > svg {
    width: 9px;
    height: 7px;
    fill: #fff;
    margin: 0;
    transition: 0.3s ease-in-out;
}

.buy-btn.active span .added {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
    .buy-btn {
        margin-right: 8px;
        font-size: 10px;
        margin-top: -8px;
    }

    .buy-btn span > svg {
        margin-right: 5px;
    }

    .buy-btn .added {
        right: -1px;
    }

    .product__item--special .buy-btn {
        margin-top: unset;
    }
}

@media only screen and (min-width: 1025px) {
    .buy-btn:hover {
        background: #ec4a9b;
        color: #fff;
    }

    .buy-btn:hover svg {
        fill: #fff;
    }

    .buy-btn:hover .added {
        border: 1px solid white;
        background: white;
    }

    .buy-btn:hover .added > svg {
        fill: #ec4a9b;
    }

    .buy-btn.not-avail:hover svg {
        fill: #fff;
    }
}

.compare-btn {
    position: relative;
    cursor: pointer;
    margin: 0 10px;
}

@media only screen and (max-width: 767px) {
    .compare-btn {
        margin: 0 10px 0 0;
    }
}

.compare-btn > svg {
    fill: #a6b0b6;
    width: 21px;
    height: 20px;
}

.compare-btn .added {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    background: #ec4a9b;
    border-radius: 50%;
    position: absolute;
    right: -12px;
    top: -8px;
    display: none;
}

@media only screen and (max-width: 767px) {
    .compare-btn .added {
        right: -8px;
    }
}

.compare-btn .added > svg {
    width: 9px;
    height: 7px;
    fill: #fff;
}

.compare-btn.active > svg {
    fill: #393939;
}

.compare-btn.active .added {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.wishlist-btn {
    position: relative;
    cursor: pointer;
    margin: 0 25px 0 10px;
}

.wishlist-btn > svg {
    fill: #a6b0b6;
    width: 20px;
    height: 17px;
}

.wishlist-btn .added {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    background: #ec4a9b;
    border-radius: 50%;
    position: absolute;
    right: -12px;
    top: -8px;
    display: none;
}

.wishlist-btn .added > svg {
    width: 9px;
    height: 7px;
    fill: #fff;
}

.wishlist-btn.active > svg {
    fill: #393939;
}

.wishlist-btn.active .added {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.rate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.rate__star {
    margin: 0 2px;
}

.rate__star:first-of-type {
    margin-left: 0;
}

.rate__star:last-of-type {
    margin-right: 0;
}

.rate__star svg {
    width: 15px;
    height: 15px;
    fill: #f0c348;
}

.rate[data-rate="1"] .rate__star:nth-child(n + 2) path,
.rate[data-rate="2"] .rate__star:nth-child(n + 3) path,
.rate[data-rate="3"] .rate__star:nth-child(n + 4) path,
.rate[data-rate="4"] .rate__star:nth-child(n + 5) path,
.rate[data-rate="5"] .rate__star:nth-child(n + 6) path,
.rate[data-rate="0"] .rate__star path {
    fill: #e7ecef;
}

.product-colors {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-colors__all {
    margin-left: 6px;
}
@media only screen and (max-width: 330px) {
    .product-colors__all {
        margin-left: 1px;
    }
}


.product-colors__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-colors__item {
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 4px;
    position: relative;
    width: 22px;
    height: 22px;
}

.product-colors__item img {
    border-radius: 50%;
}

/*@media only screen and (max-width: 767px) {
    .product-colors__item:last-of-type {
        display: none;
    }
}*/

.product-colors__item input[type="radio"],
.product-colors__item input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.product-colors__item input[type="radio"] + label,
.product-colors__item input[type="checkbox"] + label {
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.product-colors__item input[type="radio"] + span,
.product-colors__item input[type="checkbox"] + span {
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.product-colors__item input[type="radio"] + span::before,
.product-colors__item input[type="checkbox"] + span::before {
    content: " ";
    position: absolute;
    left: -3px;
    right: -3px;
    top: -3px;
    bottom: -3px;
    border-radius: 50%;
    border: 2px solid #ec4a9b;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.product-colors__item input[type="radio"] + span img,
.product-colors__item input[type="checkbox"] + span img {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    border: 2px solid #d9e1e5;
    width: 100%;
    max-width: none;
}

.product-colors__item input[type="radio"]:checked + span::before,
.product-colors__item input[type="checkbox"]:checked + span::before {
    visibility: visible;
    opacity: 1;
}

.product-colors__item input[type="radio"]:checked + span img,
.product-colors__item input[type="checkbox"]:checked + span img {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);

}

.product-colors__btn {
    
    color: #333;
    cursor: pointer;
    font-size: 12px;
    /*padding-left: 5px;*/
}

.product-colors__btn.dropdown {
    border: solid 1px #d9e1e5;
}

.product-colors__btn input {
    height: 30px;
    width: 320px;
}

@media only screen and (max-width: 767px) {
    .product-colors__btn {
        font-size: 10px;

    }
}

@media only screen and (max-width: 480px) {
    .product-colors__btn.dropdown {
        width: auto;
    }

    .product-colors__btn.dropdown input {
        width: auto;
    }

}

.product-colors__list {
    position: absolute;
    background: #fff;
    width: 100%;
    left: -15px;
    top: 31px;
    -webkit-box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.13);
    min-width: 280px;
    z-index: 3;
    max-height: 230px;
    overflow: auto;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.product-colors__list.dropdown {
    position: inherit;
    background: #fff;
    left: -15px;
    top: 31px;
    -webkit-box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.13);
    min-width: 320px;
    z-index: 3;
    max-height: 104px;
    overflow: auto;
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
    .product-colors__list {
        min-width: 154px;
    }
}

.product-colors__list.open {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
}


.dropdown > .mCustomScrollBox.mCS-light.mCSB_vertical.mCSB_inside {
    max-height: 210px !important;
}

.product-colors__list .mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.product-colors__list.scroll-text.tree-values-scroll.open.mCustomScrollbar.dropdown {
    margin-top: 5px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .product-colors__list.dropdown {
        width: 450px;
        height: 150px;
    }

    .dropdown > .mCustomScrollBox.mCS-light.mCSB_vertical.mCSB_inside {
        max-height: 150px !important;
    }

    .product-colors__list.scroll-text.tree-values-scroll.dropdown.mCustomScrollbar {
        max-height: 150px !important;
        margin-top: 5px;
    }
}

@media only screen and (max-width: 480px) {
    .product-colors__list.dropdown {
        min-width: unset;
        height: 150px;
        width: unset;
    }
}

.product-colors__list .mCSB_scrollTools .mCSB_draggerContainer {
    top: 0px;
    left: 5px;
}

.product-colors__list-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 16px 15px 40px;
}

.product-colors__list-inner .product-colors__item {
    cursor: pointer;
    margin: 0 4px 8px;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .product-colors__list-inner.dropdown {
        padding: 16px 10px 52px;
    }
}

.product-colors__list-inner.dropdown > .options-item input[type="radio"] + span::before {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    border: unset;
}

.product-colors__info {
    width: 114px;
    padding: 0 5px;
    height: auto;
    position: absolute;
    top: 100%;
    left: -46px;
    margin-top: 20px;
    background: #fff;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(47, 74, 90, 0.33);
    box-shadow: 0px 3px 30px 0px rgba(47, 74, 90, 0.33);
    font-size: 13px;
    color: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
    display: none;
}

.product-colors__info.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.product-colors__info::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: #fff;
    top: -8px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    z-index: -1;
}

.price-slider__inner {
    margin-bottom: 15px;
}

.price-slider__inner .main-link {
    
    font-size: 15px;
    text-transform: uppercase;
    margin: 0 auto;
    display: table;
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .price-slider__inner .main-link:hover {
        color: #3e3e3e;
        border-color: #3e3e3e;
        -webkit-transition: .4s ease-in-out;
        transition: .4s ease-in-out;
    }
}

#price-slider {
    max-width: 256px;
    margin: 0 12px 50px 8px;
}

@media only screen and (max-width: 480px) {
    #price-slider {
        max-width: 100%;
    }
}

.noUi-handle.noUi-handle-lower,
.noUi-handle.noUi-handle-upper {
    border: 1px solid #a6aeb2;
    background-color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: -5px;
}

.noUi-connect {
    background: #a6aeb2;
    height: 8px;
    top: 0px;
}

.noUi-handle {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    right: -11px !important;
}

.noUi-handle:before,
.noUi-handle:after {
    display: none;
}

.noUi-horizontal {
    height: 7px;
    background: #e2e8eb;
    border: none;
    border-radius: 0;
}

.price-slider__value-block {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto 28px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 12px;
}

.price-slider__value-block::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 1px;
    background: #999;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    right: -12px;
}

.price-slider__value-block input {
    font-size: 13px;
    color: #333;
    max-width: 108px;
    width: 100%;
    font-weight: normal;
    border: 1px solid #c3ced5;
    height: 33px;
    padding: 0 10px;
    text-align: center;
}

.noUi-tooltip {
    background: transparent;
    border: none;
    font-size: 12px;
    color: #000;
}

.year-slider__inner {
    margin-top: 40px;
    margin-left: 10px;
    max-width: 230px;
}

.price-slider__input-inner {
    position: relative;
}

.price-slider__input-inner span {
    position: absolute;
    font-size: 12px;
    color: #3e3e3e;
    opacity: .5;
    top: 10px;
    right: 10px;
}

.price-slider__input-inner:last-of-type {
    margin-left: auto;
}

.noUi-horizontal .noUi-tooltip {
    bottom: -160%;
    color: #333;
    font-size: 13px;
}

.reviews-page {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 135px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1599px) {
    .reviews-page {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 1023px) {
    .reviews-page {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media only screen and (max-width: 767px) {
    .reviews-page {
        padding: 0 10px;
        margin: 0 auto 70px;
    }
}

.reviews-page__main {
    max-width: 980px;
    width: 100%;
    -ms-flex-preferred-size: 980px;
    flex-basis: 980px;
}

@media only screen and (max-width: 1023px) {
    .reviews-page__main {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto 20px;
    }
}

.reviews-page__main .reviews-block {
    padding: 0;
}

.reviews-page__main .pagination {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media only screen and (max-width: 1023px) {
    .reviews-page__main .pagination {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.reviews-page__main .reviews-block__item {
    padding-bottom: 25px;
}

.reviews-page__top-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .reviews-page__top-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.reviews-page__top-wrap .add-reviews__btn {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .reviews-page__top-wrap .add-reviews__btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media only screen and (max-width: 767px) {
    .reviews-page__top-wrap .add-reviews__btn {
        margin: 10px auto 0;
    }
}

.reviews-page__block {
    margin-bottom: 80px;
}

.reviews-page__block .pagination {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-top: 25px;
    border-top: 1px solid #e1e6e9;
}

@media only screen and (max-width: 1023px) {
    .reviews-page__block {
        margin-bottom: 40px;
    }

    .reviews-page__block .pagination {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.reviews-page__block-title {
    font-size: 18px;
    
    color: #333;
    text-transform: uppercase;
}

@media only screen and (max-width: 1023px) {
    .reviews-page__block-title {
        text-align: center;
    }
}

@media only screen and (max-width: 767px) {
    .reviews-page__block-title {
        font-size: 16px;
    }
}

.reviews-page__block-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -12px;
}

.reviews-page__block-list .reviews-block__video-item {
    max-width: 226px;
    width: 100%;
    -ms-flex-preferred-size: 226px;
    flex-basis: 226px;
    margin: 0 12px 24px;
}

.reviews-page__block-list .reviews-block__video-item img {
    margin-bottom: 12px;

}

.reviews-page__block-list .reviews-block__video-item .reviews-block__video-title {
    font-size: 12px;
    
    color: #768187;
    text-align: center;
    margin-bottom: 10px;
}

.reviews-page__block-list .reviews-block__video-item .reviews-block__video-date {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #a6b0b5;
    text-align: center;
    pointer-events: none;
}

@media only screen and (max-width: 1599px) {
    .reviews-page__block-list .reviews-block__video-item,
    .reviews-page__block-list .reviews-block__photo-item {
        max-width: 22%;
        -ms-flex-preferred-size: 22%;
        flex-basis: 22%;
    }
}

@media only screen and (max-width: 1300px) {
    .reviews-page__block-list .reviews-block__video-item,
    .reviews-page__block-list .reviews-block__photo-item {
        max-width: 30%;
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
    }
}

@media only screen and (max-width: 1249px) {
    .reviews-page__block-list {
        margin: 0 -9px;
    }

    .reviews-page__block-list .reviews-block__video-item,
    .reviews-page__block-list .reviews-block__photo-item {
        margin: 0 9px 18px;
    }
}

@media only screen and (max-width: 1023px) {
    .reviews-page__block-list {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .reviews-page__block-list .reviews-block__video-item,
    .reviews-page__block-list .reviews-block__photo-item {
        max-width: 30%;
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
    }
}

@media only screen and (max-width: 580px) {
    .reviews-page__block-list {
        margin: 0;
        padding: 0 10px;
    }

    .reviews-page__block-list .reviews-block__video-item,
    .reviews-page__block-list .reviews-block__photo-item {
        max-width: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin: 0 auto 10px;
    }

    .reviews-page__block-list .reviews-block__video-item img,
    .reviews-page__block-list .reviews-block__photo-item img {
        width: 1005px;

    }
}

.reviews-page__sidebar {
    margin-left: 25px;
    padding: 38px 38px 35px;
    max-width: 470px;
    width: 100%;
    -ms-flex-preferred-size: 470px;
    flex-basis: 470px;
    background: #fff;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
}

.reviews-page__sidebar .add-reviews {
    margin: 0 auto;
}

@media only screen and (max-width: 1249px) {
    .reviews-page__sidebar {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        max-width: 380px;
        -ms-flex-preferred-size: 380px;
        flex-basis: 380px;
    }
}

@media only screen and (max-width: 1023px) {
    .reviews-page__sidebar {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto;
        -webkit-box-shadow: none;
        box-shadow: none;
        max-width: 400px;
        padding: 38px 0 35px;
    }
}

@media only screen and (max-width: 767px) {
    .reviews-page__sidebar {
        padding: 30px 15px 35px;
    }
}

.reviews-page__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 22px;
}

@media only screen and (max-width: 767px) {
    .reviews-page__top {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.reviews-page__rate {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.reviews-page__rate .rate__star svg {
    width: 20px;
    height: 20px;
}

@media only screen and (max-width: 767px) {
    .reviews-page__rate .rate {
        display: none;
    }
}

.reviews-page__top-title {
    font-size: 18px;
    
    color: #333;
    text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
    .reviews-page__top-title {
        margin-bottom: 10px;
    }
}

.reviews-page__rate-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #333;
    margin-right: 20px;
}

.reviews-page__rate-text span {
    font-family: 'Open Sans', serif;
    font-size: 14px;
    
    color: #333;
    margin-left: 8px;
}

.latest-reviews {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 90px;
}

@media only screen and (max-width: 1599px) {
    .latest-reviews {
        padding: 0 30px;
    }
}

.latest-reviews .reviews-block__video-item {
    max-width: 226px;
    width: 100%;
    -ms-flex-preferred-size: 226px;
    flex-basis: 226px;
    margin: 0 12px 24px;
}

.latest-reviews .reviews-block__video-item img {
    margin-bottom: 12px;

}

.latest-reviews .reviews-block__video-item .reviews-block__video-title {
    font-size: 12px;
    
    color: #768187;
    text-align: center;
    margin-bottom: 10px;
    padding: 0 5px;
    max-height: 39px;
    overflow: hidden;
}

.latest-reviews .reviews-block__video-item .reviews-block__video-date {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #a6b0b5;
    text-align: center;
    pointer-events: none;
}

.latest-reviews__title {
    font-size: 18px;
    
    color: #333;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
}

.latest-reviews .main-link {
    color: #97a1a8;
}

.latest-reviews .main-link span {
    color: #97a1a8;
}

.latest-reviews .main-link span::after {
    border-color: #97a1a8;
}

@media only screen and (min-width: 1025px) {
    .latest-reviews .main-link:hover {
        color: #ec4a9b;
    }

    .latest-reviews .main-link:hover span {
        color: #ec4a9b;
    }

    .latest-reviews .main-link:hover span::after {
        border-color: #ec4a9b;
    }
}

.latest-reviews__list {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.latest-reviews__list.slick-slider {
    margin: 0 -22px 25px;
    padding: 0 12px;
}

@media only screen and (max-width: 767px) {
    .latest-reviews__list.slick-slider {
        margin: 0 -22px 55px;
    }
}

.latest-reviews__list.slick-slider .reviews-block__video-item.slick-slide,
.latest-reviews__list.slick-slider .reviews-block__photo-item.slick-slide {
    margin-bottom: 0;
}

.latest-reviews__list .slick-dots {
    bottom: -40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px 22px;
}

.latest-reviews__list .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.latest-reviews__list .slick-dots li:first-of-type {
    margin-left: 0;
}

.latest-reviews__list .slick-dots li:last-of-type {
    margin-right: 0;
}

.latest-reviews__list .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #ccc;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.latest-reviews__list .slick-dots li button::before {
    display: none;
}

.latest-reviews__list .slick-dots li.slick-active button {
    border-color: #ec4a9b;
    background: #ec4a9b;
}

@media only screen and (max-width: 1023px) {
    .latest-reviews__list .related-products__btn {
        background: #c0cad0;
    }

    .latest-reviews__list .related-products__btn svg {
        fill: #fff;
    }
}

@media only screen and (max-width: 767px) {
    .latest-reviews .reviews-block__video-item,
    .latest-reviews .reviews-block__photo-item {
        max-width: 190px;
        margin: 0 5px;
    }
}

.search-page .viewed-products__title {
    font-size: 18px;
    margin: 0 auto 23px;
}

@media only screen and (max-width: 767px) {
    .search-page .viewed-products__title {
        padding: 0 15px;
    }
}

.search-block {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 50px;
}

@media only screen and (max-width: 1599px) {
    .search-block {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .search-block .search-block__subtitle {
        padding: 0;
        text-align: left;
    }
}

.search-block__title {
    font-size: 18px;
    
    color: #333;
    text-transform: uppercase;
    margin: 0 auto 10px;
    text-align: center;
}

.search-block__subtitle {
    font-size: 14px;
    
    color: #333;
    text-align: center;
    margin: 0 auto 20px;
}

.search-block__form {
    position: relative;
    max-width: 515px;
    width: 100%;
    margin: 0 auto 35px;
}

.search-block__form input {
    height: 44px;
    padding: 0 45px 0 22px;
}

.search-block__button {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 15px;
    background: transparent;
    border: none;
}

.search-block__button svg {
    width: 14px;
    height: 14px;
    fill: #a1abb2;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .search-block__button:hover svg {
        fill: #ec4a9b;
    }
}

.search-block__nav {
    margin-bottom: 45px;
}

.search-block__nav-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: 1px solid #e1e6e9;
}

.search-block__nav-item {
    position: relative;
    padding: 12px 17px;
    display: block;
    font-size: 14px;
    margin: 0 16px;
}

@media only screen and (max-width: 767px) {
    .search-block__nav-item {
        padding: 10px 5px;
        margin: 0 10px;
    }
}

.search-block__nav-item::before {
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -1px;
    height: 2px;
    width: 0;
    background: #b0bfc8;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
}

.search-block__nav-item a {
    text-decoration: none;
    color: #333;
}

.search-block__nav-item.active::before {
    width: 100%;
}

.search-block__nav-item.active a {
    
    color: #333;
}

@media only screen and (min-width: 1025px) {
    .search-block__nav-item:hover::before {
        width: 100%;
    }
}

.search-block__products {
    margin-bottom: 90px;
}

@media only screen and (max-width: 767px) {
    .search-block__products {
        margin-bottom: 40px;
    }

    .search-block__products .search-block__subtitle {
        padding: 0 15px;
        text-align: center;
        font-weight: 400;
    }
}

.search-block__products-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -9px;
}

@media only screen and (max-width: 1449px) {
    .search-block__products-list {
        margin: 0 -10px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.search-block__products-list .product__item {
    max-width: 230px;
    -ms-flex-preferred-size: 230px;
    flex-basis: 230px;
}

@media only screen and (max-width: 1599px) {
    .search-block__products-list .product__item {
        max-width: 220px;
        -ms-flex-preferred-size: 220px;
        flex-basis: 220px;
    }
}

@media only screen and (max-width: 1449px) {
    .search-block__products-list .product__item {
        max-width: 230px;
        -ms-flex-preferred-size: 230px;
        flex-basis: 230px;
    }
}

@media only screen and (max-width: 489px) {
    .search-block__products-list .product__item {
        max-width: 145px;
        -ms-flex-preferred-size: 145px;
        flex-basis: 145px;
    }
}

.search-block__products-list .product__info-list {
    display: none;
}

.search-block__products-list .product__rate {
    margin-bottom: 20px;
}

.search-block__products-list .product__image {
    padding: 30px;
    height: 232px;
}

@media only screen and (max-width: 489px) {
    .search-block__products-list .product__image {
        height: 170px;
    }
}

.search-block__news {
    margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
    .search-block__news {
        margin-bottom: 40px;
    }

    .search-block__news .search-block__subtitle {
        padding: 0 15px;
        text-align: center;
        font-weight: 400;
    }
}

.search-block__news-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -10px;
}

@media only screen and (max-width: 1449px) {
    .search-block__news-list {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.search-block__news-list .news__item {
    max-width: 355px;
    -ms-flex-preferred-size: 355px;
    flex-basis: 355px;
    margin: 0 10px 35px;
}

@media only screen and (max-width: 1599px) {
    .search-block__news-list .news__item {
        max-width: 340px;
        -ms-flex-preferred-size: 340px;
        flex-basis: 340px;
    }
}

@media only screen and (max-width: 1449px) {
    .search-block__news-list .news__item {
        max-width: 286px;
        -ms-flex-preferred-size: 286px;
        flex-basis: 286px;
    }
}

@media only screen and (max-width: 1249px) {
    .search-block__news-list .news__item {
        max-width: 355px;
        -ms-flex-preferred-size: 355px;
        flex-basis: 355px;
    }
}

.search-block .catalog--large {
    padding: 0;
}

.search-block .catalog--large .pagination {
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    .search-block .catalog--large .pagination {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 767px) {
    .search-block--products {
        padding: 0;
    }

    .search-block--products .search-block__subtitle,
    .search-block--products .search-block__form {
        padding: 0 10px;
    }
}

.search-news {
    margin-bottom: 110px;
}

@media only screen and (max-width: 1599px) {
    .search-news {
        padding: 0;
    }
}

@media only screen and (max-width: 1023px) {
    .search-news .news__sidebar-open {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .search-news .news__sidebar-open {
        margin-left: 0;
    }
}

select {
    display: none;
}

.select {
    margin: 0;
    border: 1px solid #e8e8e8;
    padding: 10px 20px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    /*  white-space: nowrap;*/
    outline: none;
    overflow: hidden;
}

.select::after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 7px;
    width: 7px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}

.select:focus {
    border-color: #333;
}

.select.error {
    border-color: #ec4a9b;
}

.select--open {
    overflow: visible;
}

.select--open::after {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.select--medium {
    max-width: 500px;
}

.select__list {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background-color: #fff;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 2px;
    opacity: 0;
    max-height: 250px;
    overflow-y: auto;
    padding: 0;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-2em);
    transform: scale(0.75) translateY(-2em);
    -webkit-transition: opacity 0.1s ease-out, -webkit-transform 0.2s cubic-bezier(0.5, 0, 0, 1.25);
    transition: opacity 0.1s ease-out, -webkit-transform 0.2s cubic-bezier(0.5, 0, 0, 1.25);
    transition: transform 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.1s ease-out;
    transition: transform 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.1s ease-out, -webkit-transform 0.2s cubic-bezier(0.5, 0, 0, 1.25);
    z-index: 5;
}

.select__list .mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.select__list .mCSB_scrollTools {
    top: -4px;
    right: -6px;
}

.select--open .select__list {
    visibility: visible;
    opacity: 1;
    z-index: 5;
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    margin-top: 3px !important;
}

.select__item {
    padding: 5px 10px;
    cursor: pointer;
}

.select__item.hidden {
    display: none;
}

.select__item:hover {
    background: #d8dddf;
}

.select__item--active {
    
    background: #d8dddf;
}

.select__item--disabled {
    display: none;
    opacity: .3;
    cursor: not-allowed;
}

/*.search-holder {
    z-index: 6;
    position: absolute;
    left: 5px;
    right: 5px;
    top: 53px;
    height: 40px;
}*/

.search-holder input {
    border: 1px solid #c3ced5;
    width: 100%;
    height: 35px;
    padding: 0 20px;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
}

.search-select .select__list {
    padding-top: 45px;
}

.service-block {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 130px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1599px) {
    .service-block {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 767px) {
    .service-block {
        padding: 0 10px;
        margin: 0 auto 10px;
    }
}

.service-block__main {
    max-width: 1120px;
    width: 100%;
    -ms-flex-preferred-size: 1120px;
    flex-basis: 1120px;
}

.service-block__sidebar {
    background: #fff;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    max-width: 308px;
    width: 100%;
    -ms-flex-preferred-size: 308px;
    flex-basis: 308px;
    margin-left: 20px;
}

.service-block__sidebar .inner-wrapper-sticky {
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 1249px) {
    .service-block__sidebar {
        max-width: 280px;
        -ms-flex-preferred-size: 280px;
        flex-basis: 280px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
}

@media only screen and (max-width: 1023px) {
    .service-block__sidebar {
        display: none;
    }
}

.service-item {
    background: #fff;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 100px;
}

@media only screen and (max-width: 1023px) {
    .service-item {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .service-item {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.service-item__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.service-item__image {
    max-width: 422px;
    width: 100px;
    -ms-flex-preferred-size: 422px;
    flex-basis: 422px;
}

@media only screen and (max-width: 1449px) {
    .service-item__image {
        max-width: 360px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
}

@media only screen and (max-width: 1249px) {
    .service-item__image {
        display: none;
    }
}

.service-item__content {
    max-width: 700px;
    width: 100%;
    -ms-flex-preferred-size: 700px;
    flex-basis: 700px;
    padding: 35px 40px;
}

@media only screen and (max-width: 1449px) {
    .service-item__content {
        padding: 25px;
    }
}

@media only screen and (max-width: 1249px) {
    .service-item__content {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

@media only screen and (max-width: 767px) {
    .service-item__content {
        padding: 0;
    }
}

.service-item__title {
    font-size: 26px;
    
    color: #333;
    text-transform: uppercase;
    margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
    .service-item__title {
        font-size: 14px;
        margin-bottom: 12px;
    }
}

.service-item__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.5;
    max-height: 80px;
    overflow-y: auto;
}

@media only screen and (max-width: 767px) {
    .service-item__text {
        max-height: 380px;
    }
}

.service-item__subtitle {
    font-size: 14px;
    
    color: #56626a;
    text-transform: uppercase;
    margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
    .service-item__subtitle {
        font-size: 13px;
    }
}

.service-item__table {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .service-item__table {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.service-item__list {
    max-width: 460px;
    width: 100%;
    -ms-flex-preferred-size: 460px;
    flex-basis: 460px;
    list-style: none;
    margin: 0;
    padding: 0 35px 0 0;
    border-right: 1px solid #e1e6e9;
}

@media only screen and (max-width: 767px) {
    .service-item__list {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        padding: 0 0 30px;
        border-right: none;
        border-bottom: 1px solid #e1e6e9;
    }
}

.service-item__item {
    margin-bottom: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 1449px) {
    .service-item__item {
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 1023px) {
    .service-item__item {
        margin-bottom: 12px;
    }
}

@media only screen and (max-width: 767px) {
    .service-item__item {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        margin-bottom: 10px;
    }
}

.service-item__item:last-of-type {
    margin-bottom: 0;
}

.service-item__item .text {
    font-size: 14px;
    color: #798890;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
    .service-item__item .text {
        -ms-flex-negative: 1;
        flex-shrink: 1;
        padding-right: 5px;
    }
}

.service-item__item .value {
    font-size: 14px;
    
    color: #768187;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
    .service-item__item .value {
        padding-left: 5px;
    }
}

.service-item__item .dots {
    -ms-flex-item-align: end;
    align-self: flex-end;
    border-bottom: 1px dotted #959ea3;
    width: 100%;
    margin-bottom: 3px;
}

.service-item__download {
    max-width: 115px;
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    color: #53595d;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.service-item__download svg {
    fill: #778b96;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

@media only screen and (min-width: 1025px) {
    .service-item__download:hover {
        color: #ec4a9b;
    }

    .service-item__download:hover svg {
        fill: #ec4a9b;
    }
}

@media only screen and (max-width: 767px) {
    .service-item__download {
        margin: 15px auto 35px;
    }
}

.service-item__bottom {
    background: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
    .service-item__bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.service-item__contacts {
    max-width: 422px;
    width: 100%;
    -ms-flex-preferred-size: 422px;
    flex-basis: 422px;
    padding: 25px 50px 30px;
    border-right: 1px solid rgba(193, 207, 215, 0.2);
}

@media only screen and (max-width: 1449px) {
    .service-item__contacts {
        max-width: 360px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
}

@media only screen and (max-width: 1249px) {
    .service-item__contacts {
        -ms-flex-negative: 1;
        flex-shrink: 1;
    }
}

@media only screen and (max-width: 1023px) {
    .service-item__contacts {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        border-right: none;
        border-bottom: 1px solid rgba(193, 207, 215, 0.2);
        max-width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

@media only screen and (max-width: 767px) {
    .service-item__contacts {
        padding: 30px 10px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.service-item__contacts-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.service-item__contacts-item:last-of-type {
    margin-bottom: 0;
}

@media only screen and (max-width: 1023px) {
    .service-item__contacts-item {
        margin: 0 20px;
    }

    .service-item__contacts-item:first-of-type {
        margin-left: 0;
    }

    .service-item__contacts-item:last-of-type {
        margin-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .service-item__contacts-item {
        margin: 0 0 10px;
    }
}

.service-item__contacts-icon {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 12px;
}

.service-item__contacts-icon svg {
    width: 22px;
    height: 22px;
    fill: #c1cfd7;
}

.service-item__contacts-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #c1cfd7;
    margin-bottom: 6px;
}

.service-item__contacts-value {
    font-size: 13px;
    
    color: #fff;
}

.service-item__contacts-value a {
    text-decoration: none;
    color: #fff;
}

.service-item__info {
    max-width: 700px;
    width: 100%;
    -ms-flex-preferred-size: 700px;
    flex-basis: 700px;
    padding: 20px 38px;
}

@media only screen and (max-width: 1023px) {
    .service-item__info {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

@media only screen and (max-width: 767px) {
    .service-item__info {
        padding: 15px 10px 30px;
    }
}

.service-item__info-text {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #a8bcc7;
    line-height: 1.5;
    margin-bottom: 18px;
}

.service-item__info-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.service-item__info-box .submit-btn {
    max-width: 152px;
    height: 40px;
    background: #5b6a72;
    margin-right: 30px;
}

@media only screen and (min-width: 1025px) {
    .service-item__info-box .submit-btn:hover {
        background: #ec4a9b;
    }
}

@media only screen and (max-width: 767px) {
    .service-item__info-box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.service-item__info-link {
    max-width: 192px;
    width: 100%;
    font-size: 13px;
    color: #fff;
    text-decoration: underline;
    -webkit-text-decoration-style: dotted;
    text-decoration-style: dotted;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.service-item__info-link img {
    margin-right: 8px;
    -ms-flex-negative: 0;
    flex-shrink: 0;

}

@media only screen and (min-width: 1025px) {
    .service-item__info-link:hover {
        color: #ec4a9b;
    }
}

@media only screen and (max-width: 767px) {
    .service-item__info-link {
        margin-bottom: 22px;
    }
}

.service-content {
    position: relative;
    margin-bottom: 120px;
}

@media only screen and (max-width: 1023px) {
    .service-content {
        margin: 80px auto 40px;
    }
}

@media only screen and (max-width: 767px) {
    .service-content {
        margin: 30px auto 45px;
    }
}

.service-content::before {
    content: '';
    position: absolute;
    left: -30%;
    top: -25px;
    height: 170px;
    width: 600px;
    background: #e6eaec;
    z-index: -1;
}

@media only screen and (max-width: 1249px) {
    .service-content::before {
        left: -50%;
    }
}

@media only screen and (max-width: 767px) {
    .service-content::before {
        display: none;
    }
}

.service-content__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .service-content__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.service-content__image {
    max-width: 476px;
    width: 100%;
    -ms-flex-preferred-size: 476px;
    flex-basis: 476px;
    margin-right: 35px;
}

@media only screen and (max-width: 767px) {
    .service-content__image {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        margin: 0 auto 20px;
    }
}

.service-content__content {
    max-width: 602px;
    width: 100%;
    -ms-flex-preferred-size: 602px;
    flex-basis: 602px;
}

@media only screen and (max-width: 767px) {
    .service-content__content {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.service-content__title {
    max-width: 420px;
    font-size: 18px;
    color: #333;
    
    text-transform: uppercase;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .service-content__title {
        font-size: 14px;
    }
}

.service-content__text {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #333;
    line-height: 1.5;
    max-height: 180px;
    overflow-y: auto;
}

@media only screen and (max-width: 767px) {
    .service-content__text {
        max-height: 400px;
    }
}

.service-map__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 767px) {
    .service-map__inner {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.service-map__inner .contacts-block__main {
    max-width: 418px;
    width: 100%;
    -ms-flex-preferred-size: 418px;
    flex-basis: 418px;
}

@media only screen and (max-width: 1023px) {
    .service-map__inner .contacts-block__main {
        max-width: 340px;
        -ms-flex-preferred-size: 340px;
        flex-basis: 340px;
    }
}

@media only screen and (max-width: 767px) {
    .service-map__inner .contacts-block__main {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.service-map__inner .contacts-content {
    padding: 0;
    max-height: 414px;
    overflow-y: auto;
}

.service-map__inner .contacts-content .mCSB_scrollTools .mCSB_draggerContainer {
    top: 0px;
    left: 10px;
    bottom: 0;
    right: 0;
}

.service-map__inner .contacts-content .mCSB_scrollTools .mCSB_draggerRail {
    width: 10px;
    background: #d2dbe0;
}

.service-map__inner .contacts-content .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 10px;
    background: #96a8b3;
}

.service-map__inner .contacts-content .mCSB_inside > .mCSB_container {
    margin-right: 0;
}

.service-map__inner .contacts-content__box-item {
    padding: 30px 30px 20px;
    border-bottom: 1px solid #e1e6e9;
}

@media only screen and (max-width: 1023px) {
    .service-map__inner .contacts-content__box-item {
        padding: 15px 20px;
    }
}

.service-map__inner .contacts-content__box-item:last-child {
    border-bottom: none;
}

.service-map__inner .contacts-content__main-title {
    font-size: 13px;
    color: #56626a;
}

.service-map__inner .contacts-content__item {
    margin-bottom: 10px;
}

.service-map__inner .contacts-content__icon {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.service-map__inner .contacts-content__icon svg {
    width: 14px;
    height: 14px;
}

.service-map__title {
    font-size: 26px;
    
    color: #333;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto 25px;
}

#map-service {
    height: 465px;
    max-width: 702px;
    width: 100%;
    -ms-flex-preferred-size: 702px;
    flex-basis: 702px;
}

@media only screen and (max-width: 767px) {
    #map-service {
        max-width: 100%;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.service-nav {
    padding: 0 20px;
}

.service-nav__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.service-nav__item {
    padding: 16px 15px;
    font-size: 15px;
    border-bottom: 1px solid #e1e6e9;
}

.service-nav__item a {
    display: table;
    overflow: hidden;
    position: relative;
    color: #869198;
    text-decoration: none;
    z-index: 1;
}

.service-nav__item a::before {
    content: '';
    position: absolute;
    left: -5px;
    bottom: 1px;
    width: 0;
    height: 4px;
    background: #ccd8e0;
    z-index: -1;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.service-nav__item:last-of-type {
    border-bottom: none;
}

.service-nav__item.active {
    
}

.service-nav__item.active a {
    color: #333;
}

.service-nav__item.active a::before {
    width: 105%;
}

.tab {
    height: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    position: relative;
}

.tab.active {
    height: auto;
    overflow: visible;
    opacity: 1;
}

.wishlist-block {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto 100px;
}

@media only screen and (max-width: 1599px) {
    .wishlist-block {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 510px) {
    .wishlist-block {
        padding: 0 10px;
        margin: 0 auto 50px;
    }
}

.wishlist-block__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -9px 36px;
    border-bottom: 1px solid #e1e6e9;
}

@media only screen and (max-width: 1449px) {
    .wishlist-block__list {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 1599px) {
    .wishlist-block__list .product__item {
        max-width: 268px;
        -ms-flex-preferred-size: 268px;
        flex-basis: 268px;
    }
}

@media only screen and (max-width: 1249px) {
    .wishlist-block__list .product__item {
        max-width: 234px;
        -ms-flex-preferred-size: 234px;
        flex-basis: 234px;
    }
}

@media only screen and (max-width: 510px) {
    .wishlist-block__list .product__item {
        max-width: 46%;
        -ms-flex-preferred-size: 46%;
        flex-basis: 46%;
    }
}

.wishlist-block__text {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    margin-bottom: 30px;
}

@media only screen and (max-width: 510px) {
    .wishlist-block__text {
        padding: 0 15px;
    }
}

.wishlist-block__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
}

@media only screen and (max-width: 510px) {
    .wishlist-block__image {
        margin-bottom: 10px;
    }
}

.wishlist-block .submit-btn {
    max-width: 152px;
    margin: 0 auto;
    font-size: 14px;
}

.frame_blc {
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
}

.frame_blc iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

@media only screen and (max-width: 767px) {
    .product-page__table {
        display: none;
    }
}

.new-news {
    text-align: center;
    text-transform: uppercase;
    
    margin-bottom: 2%;
    font-size: 185%;
    color: #333;
}

@media only screen and (max-width: 767px) {
    .new-news {
        font-size: 20px;
        padding: 0 15px;
    }
}

/* спинер корзины */

.lds-ring {
    display: flex;
    position: absolute;
    width: max-content;
    height: max-content;
    z-index: 98;
    background-color: rgba(255, 255, 255, 0.4);
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: fixed;
    width: 64px;
    height: 64px;
    /*top: 35%;
    left: 50%;*/
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #c3ced5 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* спинер главной */

.lds-ring-slider {
    position: relative;
    display: block;
    width: max-content;
    top: 25%;
    /* z-index: 9999;*/
    background-color: rgba(255, 255, 255, 0.4);
}

.lds-ring-slider div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    left: 47.5%;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #c3ced5 transparent transparent transparent;
}

.lds-ring-slider div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring-slider div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring-slider div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring-slider {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 767px) {
    .lds-ring-slider div {
        width: 43px;
        height: 43px;
        left: 43.5%;
    }
}

/*new*/
@media only screen and (max-width: 480px) {
    .auth-pop-up {
        max-width: 415px;
    }
}

.auth-pop-up {
    width: 100%;
    max-width: 400px;
    transform: translateX(100%);
    transition: transform .4s;
    padding: 100px 40px 50px;
    height: 100%;
    background: #fff;
    pointer-events: all;
}

.auth-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .3);
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s;
    pointer-events: none;
}

.auth-overlay--active {
    opacity: 1;
}

.auth-overlay--active .auth-pop-up {
    transform: translateX(0);
}

.auth-pop-up__close-button {
    position: absolute;
    top: 40px;
    right: 40px;
    background: none;
    width: 15px;
    height: 15px;
    outline: none;
    cursor: pointer;
}

.auth-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.close-icon {
    width: 100%;
    height: 100%;
}

.error_callback_phone {
    display: block;
    font-size: 10px;
    line-height: 12px;
    color: #333;
    margin: 5px 0 0;
}


.account-login {
    text-align: center;
}

.account-login__button.auth-button {
    line-height: 2px;
    width: 220px;
    height: 46px;
}

.account-login__description {
    text-align: center;
    font-size: 18px;
    margin-bottom: 21px;
    color: #333;
}

#w4p-submit-btn, #liqpay-submit-btn {
    background: rgba(0, 0, 0, 0);
    text-transform: uppercase;
    color: white;
}

#w4p-submit-btn-outer, #liqpay-submit-btn-outer {
    margin: auto;
    background: #ec4a9b;
}

#w4p-submit-btn-outer:hover, #liqpay-submit-btn-outer:hover {
    background: #333;
}

font.errortext {
    color: red;
}

font.notetext {
    color: green;
}
/*# sourceMappingURL=index.css.map */
.map-columns {
    margin: auto;
}
.map-columns tr {
    display: flex;
    flex-wrap: wrap;
}
.map-columns td {
    display: flex;
}

#streamCRM-widget-button {
    display: none!important;
    visibility: hidden;
}

ul.map-level-0 a {
    
}

ul.map-level-1 a, ul.map-level-2 a, ul.map-level-3 a {
    font-weight: normal;
}

.map-columns td {
    vertical-align: top;
}

.loader-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3040;
    background-color: rgba(0, 0, 0, 0.32);
    opacity: 1;
}

.loader-container:after {
    content: "";
    position: absolute;
    top: -webkit-calc(50% - 25px);
    top: calc(50% - 25px);
    left: -webkit-calc(50% - 25px);
    left: calc(50% - 25px);
    border: 5px solid #ec4a9b;
    border-radius: 50%;
    border-color: #ec4a9b transparent #ec4a9b transparent;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: preload 2s linear infinite;
    visibility: visible;
}

.preloader {
    width: 50px;
    height: 50px;
    top: -webkit-calc(50% - 25px);
    top: calc(50% - 25px);
    left: -webkit-calc(50% - 25px);
    left: calc(50% - 25px);
    border: 5px solid #ec4a9b;
    border-radius: 50%;
    border-color: #ec4a9b transparent #ec4a9b transparent;
    -webkit-animation: spin 2s linear infinite;
    animation: preload 2s linear infinite;
    margin: 40px auto 40px auto;
}

/* Safari */
@-webkit-keyframes preload {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes preload {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.swiper-slide .product__item {
    margin-left: unset;
    margin-right: unset;
}

.bot-block {
    display: block;
    left: 0;
    top: 100%;
    overflow: hidden;
    height: 0;
    position: absolute;
}

.available__label {
    top: 0;
    display: flex;
    gap: 5px;
    position: absolute;
    right: 0;
    padding: 2px 5px 0px;
    font-size: 12px;
}

.available__label.not__available {
    color: #999;
}

.available__label.available {
    color: #33a700;
}

.available__label.not__available svg {
    fill: #999;
    height: 12px;
    width: 12px;
    padding-top: 3px;
}

.available__label.available svg {
    fill: #33a700;
    height: 12px;
    width: 12px;
    padding-top: 3px;
}

.table-last-items table {
    width: 100%;
    border-collapse: collapse; /* Объединение границ ячеек */
}

.table-last-items table td,
.table-last-items table th {
    border: 1px solid #e1e6e9; /* Устанавливаем границу ячеек */
    padding: 8px; /* Добавляем отступ вокруг содержимого ячеек */
}

.table-last-items table h6 {
    font-size: 16px;
    line-height: 1px;
    margin: 12px 0px;
    font-weight: normal;
}

.table-last-items .date-text-last-item-table{
    color: #999;
    margin-top: 10px;
    font-size: 14px;
}

.product__image .swiper-button-prev, .product__image .swiper-button-next{
    color: #959795 !important;
    z-index: 5 !important;
}

.product__image .swiper-pagination{
    z-index: 5 !important;
}

.product__image .swiper-pagination-bullet-active {
    background: #959795;
}

.product__image .swiper-button-prev:after, .product__image .swiper-button-next:after {
    font-size: 30px !important;
}

.product__image .swiper-slide {
    opacity: 0;
}

.product__image .swiper-slide-active {
    opacity: 1;
}

.product__image swiper-container {
    overflow-x: hidden;
}

.product__image swiper-slide {
    padding-left: 2px;
    min-width: 240px;
    overflow: hidden;
}

.set_products_js {
    /*width: 280px;*/
    display: block;
}

.swiper-container {
    width: 280px;
    padding: 20px;
}

.search-block .product__image{
    overflow: hidden;
}

.swiper-lazy-preloader-holder {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    animation: swiper-preloader-spin 1s infinite linear;
    box-sizing: border-box;
    border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
    border-color: #f16ca8;
    border-radius: 50%;
    border-top-color: transparent;
}

.swiper-lazy-preloader {
    border: unset;
}

.product__item .product__image swiper-container swiper-slide::part(preloader) {
    --swiper-theme-color: #f16ca8;
}

.alert-warning {
    background-color: #ec4a9b17 ;
    border-color: #f5c2c7;
    flex-direction: column;
}

.baner-product-list {
    padding-left: 17px;
}

.baner-product-list li {
    margin-bottom: 10px;
}

.checkout-basket_product-block {
    border-top: 1px solid #e1e6e9;
}

.basket_product-block-title {
    text-align: center;
    display: block;
    font-size: 18px;
    margin: 8px;
}

@media only screen and (max-width: 600px) {
    #product-availability .submit-btn {
        font-size: 8px;
    }

    .product__image swiper-slide {
        min-width: 160px;
    }
}

.sale_order_full_table .submit-btn {
    min-width: 240px;
    height: 50px;
}

.product-page__info-top .button-prev-custom, .product-page__info-top .button-next-custom  {
    width: 20px;
    text-align: center;
    cursor: pointer;
    float: left;
    color: black;
}

.product-page__info-top .disable, .product-page__info-top .disable {
    opacity: .35;
}

.product-page__info-top .button-prev-custom:hover, .product-page__info-top .button-next-custom:hover {
    opacity: .35;
}

.product-page__info-top .buttons_container {
    margin-left: auto;
    padding: 0 0px 0 22px;
}

.order-buttons-block {
    margin-right: 5px;
}

.fancybox__content {
    width: calc(90vw); /* ширина будет равна 90% ширины окна браузера */
    height: calc(80vh); /* высота будет равна 80% высоты окна браузера */
    max-width: 100vw; /* ограничение по ширине на 100% от ширины экрана */
    max-height: 100vh; /* ограничение по высоте на 100% от высоты экрана */
}

.fancybox__caption {
    max-width: 30% !important;
}

.input-num-sms {
    border: none !important;
}

.required-label {
    color: #ec4a9b;
}

.add-reviews_need_auth {
    background: #f5f7f7;
    margin-bottom: 3.2rem;
    padding: 1.6rem 2.4rem;
    font-size: 1.3em;
    line-height: 2.4rem;
    display: flex;
    align-items: center;
}

.add-reviews_need_auth .add-reviews_need_auth_btn{
    background: #ec4a9b;
    color: #fff;
    font-size: 14px;
    height: 44px;
    cursor: pointer;
    text-transform: uppercase;
    padding: 0 27px;
    margin-left: auto;
    align-items: center;
    text-align: center;
}

@media (max-width: 768px) {
    .add-reviews_need_auth {
        flex-direction: column; /* Изменение направления на column */
        align-items: flex-start; /* Выравнивание элементов по левому краю */
    }

    .add-reviews_need_auth .add-reviews_need_auth_btn {
        margin-left: 0; /* Убираем отступ слева */
        margin-top: 1rem; /* Добавляем отступ сверху */
        width: 100%; /* Занимаем всю доступную ширину */
        justify-content: center; /* Центрирование текста по горизонтали */
    }
}

.reviews-block__confirm {
    font-size: 12px;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.reviews-block__confirm img {
    width: 18px;
    margin-right: 4px;
}

.product-info-panel .product-page__controls{
    max-width: 768px;
    justify-content: space-between;
    padding: 10px 20px;
    height: 67px;
    -webkit-box-shadow: 0 -2px 13px 0 rgba(144, 143, 143, 0.5);
    box-shadow: 0 -2px 13px 0 rgba(144, 143, 143, 0.5);
    margin-bottom: 0px;
}

.product-info-panel {
    bottom: 0;
    z-index: 10102;
}

.product-info-panel .product-page__price-old {
    font-size: 13px;
}

.product-info-panel .product-page__price-box {
    margin-bottom: 0px;
}

.product-info-panel .product-page__price-new {
    font-size: 16px;
}

.product-info-panel .product-page__buy  {
    padding: 20px;
    margin-bottom: 0px;
}