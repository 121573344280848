.search-input-clear {
    right: 1px;
    margin: 6px 10px 0;
    position: absolute;
    cursor: pointer;
    z-index: 999;
}

.search-input-clear svg{
    fill: #333;
    height: 17px;
    width: 17px;
}