@keyframes fly {
    0% {
        transform: translateX(-100vw) rotate(0);
    }
    50% {
        transform: translateX(50vw) rotate(15deg);
    }
    100% {
        transform: translateX(100vw) rotate(0);
    }
}

.envelope-container {
    position: relative;
}

.animate-fly {
    animation: fly 15s infinite linear;
}

.subscribe_text_font {
    font-family: "Open Sans";
}

@media (max-width: 768px) {
    .subscribe_icon {
        display: none;
    }
}