* {
    box-sizing: border-box;
    /*padding: 0;
    margin: 0;*/
}

.auth-pop-up a {
    text-decoration: none;
}

button {
    background-color: none;
    border: none;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
    appearance: none;
    outline: none;
    background: #fff;
    overflow: visible;
}

.authorization input:not([type="checkbox"]):not([type="radio"]) {
    border: none;
}

[name='user_input_email_phone'], [name='user_input_email_phone']:focus, [name='user_input_email_phone']:active {
    border: none;
    outline: none !important;
}

.auth-pop-up {
    width: 100%;
    max-width: 400px;
    transform: translateX(100%);
    transition: transform .4s;
    padding: 100px 40px 50px;
    height: 100%;
    background: #fff;
    pointer-events: all;
    overflow: auto;
}

.auth-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .3);
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s;
    pointer-events: none;
}

.auth-overlay--active {
    opacity: 1;
    z-index: 120;
}

.auth-overlay--active .auth-pop-up {
    transform: translateX(0);
}

.close-modal {
    position: absolute;
    top: 40px;
    right: 40px;
    background: none;
    width: 15px;
    height: 15px;
    outline: none;
    cursor: pointer;
}

.auth-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 1325px;
    min-height: 420px;
}

.auth-scroll-dis {
    overflow: hidden;
    pointer-events: none;
}

.b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible {
    z-index: 10101;
}

.b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible.hidden {
    display: none;
}

.request_error_block {
    margin-top: 25px;
}

.error-text {
    display: none;
    color: #fc654b;
    font-size: 13px;
    margin-top: -15px;
    margin-bottom: 20px;
    font-family: 'Open Sans', serif;
}

.error-text.checkword {
    margin-top: -10px;
}

.error-text--active {
    display: inline-block;
}

.error-solution-wrap {
    display: flex;
    justify-content: space-between;
    max-height: 17px;
    margin-bottom: 15px;
}

.auth-title {
    display: inline-block;
    font-size: 24px;
    margin-bottom: 30px;
    font-family: 'Open Sans', serif;
    color: #333;
}

.sms-text {
    font-size: 14px;
    display: inline-block;
    margin-bottom: 20px;
    font-family: 'Open Sans', serif;
}

.auth-link {
    color: #333;
    font-size: 14px;
    line-height: 15px;
    border-bottom: 1px dotted #596368;
    display: inline-block;
    text-decoration: dotted;
    transition: color .3s;
    font-family: 'Open Sans', serif;
}

.auth-link:hover {
    color: black;
}

.auth-link--margin {
    margin-bottom: 15px;
}

.auth-button {
    background-color: #333;
    text-align: center;
    padding: 20px 5px;
    color: white;
    text-transform: uppercase;
    font-family: 'Open Sans', serif;
    font-size: 15px;
    line-height: 16px;
    transition: background-color .5s;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 20px;
}

.auth-button:hover {
    background-color: #ec4a9b;
}

.registration-link {
    color: #333;
    font-size: 16px;
    transition: .3s all;
    font-family: 'Open Sans', serif;
}

.registration-link:hover {
    color: #ec4a9b;
}

.verification-code {
    position: relative;
    margin-bottom: 20px;
}

.verification-code__input {
    font-family: 'Open Sans', serif;
    font-size: 14px;
    padding-left: 6px;
    width: 140px;
    height: 30px;
    letter-spacing: 17px;
}

.verification-code__border {
    position: absolute;
    bottom: 5px;
    width: 95px;
    height: 1px;
    background: linear-gradient(90deg, #000 0, #000 20px, #fff 20px, #fff 25px, #000 25px, #000 45px, #fff 45px, #fff 50px,  #000 50px, #000 70px, #fff 70px, #fff 75px, #000 75px, #000 95px);
}

/*e-mail, tel page*/

.authorization,
.input-with-label {
    position: relative;
    border: 1px solid  #c3c3c3;
    background: #fff;
    width: 100%;
    margin-bottom: 20px;
    padding: 25px 20px 5px;
}

.authorization.checkword {
    margin-bottom: 15px;
}

.auth-tel-info, .auth-checkword-info {
    font-size: 13px;
    font-family: 'Open Sans', serif;
    color: #333;
    margin-bottom: 14px;
    margin-top: -15px;
    display: inline-block;
}

.auth-checkword-info.spam {
    margin-top: 10px;
}

.authorization label,
.auth-input__label {
    font-family: 'Open Sans', serif;
    position: absolute;
    top: 50%;
    left: 12px;
    right: 0;
    transform: translateY(-50%) scale(1);
    color: #333;
    font-size: 16px;
    transition: transform, top .3s;
}

.authorization__input:focus + label,
.authorization__input.has-value + label {
    top: 10px;
    left: 10px;
    transform: translateY(-50%) translateX(-15%) scale(0.7);
    width: 90%;
}

/*password*/

.auth-link--password {
    display: block;
    margin-bottom: 20px;
    border: none;
    font-size: 15px;
}

/*.authorization__password-img {
    position: absolute;
    top: 10px;
    right: 11px;
    width: 25px;
    height: 25px;
}*/

.input-with-label__password-img {
    outline: none;
    background-image: url('devSrc/common/images/password-off.svg');
    background-size: contain;
    background-color: transparent;
    position: absolute;
    top: 15px;
    right: 11px;
    width: 25px;
    height: 19px;
    cursor: pointer;
}

.input-with-label__password-img--open {
    background-image: url('devSrc/common/images/password-on.svg');
    top: 17px;
    height: 15px;
}

/*password-recovery*/

.password-recovery-form {
    margin-bottom: 20px;
}

.password-recovery-info {
    font-family: 'Open Sans', serif;
    font-size: 16px;
    line-height: 18px;
    color: #606060;
    margin-bottom: 20px;
}

@media (max-width: 480px) {
    .auth-pop-up {
        max-width: 415px;
    }
}

.popup-policy-label-auth {
    color: #333;
    transition: .3s ease-in-out;
    border-bottom: 1px dashed black;
    text-decoration: none;
}

.popup-policy-label-auth:hover {
    color: #ec4a9b;
}

.checkbox-block {
    margin: 0 0 15px 28px;
}