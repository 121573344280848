@media (min-width: 767px) {
    .search .search-result-main-info .product-list  {
        height: 386px;
    }
}

.search .search-result-main-info .product-list .rcs-custom-scroll .rcs-inner-handle {
    width: 4px;
    background-color: #7ac2ec;
    opacity: .3;
}
.search .search-result-main-info .product-list .rcs-custom-scroll .rcs-custom-scrollbar {
    opacity: 1;
    right: -2px;
}
